const { h, define, Component, createRef } = omii;
import "../../es-lib/omii/latest/ui/omii-ui.js"
import { getToken } from "../token";
import css from "../css/home.scss";

export default class extends Component {
    static css = css;
    dataList = [];
    companyName = "";
    attentionTime = "";
    attentionEndTime = "";
    sourceType = "";
    businessName = "";
    data = {
        total: 0,
        pageSize: 10,
        pageNum: 1
    }
    pageRef = createRef();
    serviceArticle = {};
    serviceSetting = {
        width: "660px",
        height: "487px",
        show: false,
        title: "洽谈邀约"
    }
    myCss = `
    .pager .page  {
        font-size:1.4rem!important;
        padding:6px 9px;
        border:solid 1px #f5f5f9;
        margin:0 5px
    }
    .pager .page:hover{
        background:#002040 !important;
    }
    .pager .active{
        background:#002040 !important;
    }
    .pager .jumper,.pager .info{
        font-size:1.4rem!important;
    }
    .pager .jumper .jump-to{
        margin:0 1rem;
    }
    `
    getPagedList(e) {
        let pageNum = e.detail;
        let url = "/prod-api/manage/booking/personalMessage?pageNum=" + pageNum + "&pageSize=10";
        if (this.sourceType) {
            url += "&sourceType=" + this.sourceType;
        }
        if (this.businessName) {
            url += "&businessName=" + this.businessName;
        }
        fetch(url, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                if (res.code == 200) {
                    res.rows.map(item => {
                        if (item.sourceType == 2) {
                            item.url = productUrl + item.businessId
                            item.name = "产品";
                        } else if (item.sourceType == 3) {
                            item.url = solutionUrl + item.businessId;
                            item.name = "方案";
                        } else if (item.sourceType == 5) {
                            item.url = exhProductUrl + item.businessId;
                            item.name = "展品";
                        }
                    })
                    this.dataList = res.rows;
                    this.data.total = res.total;
                    this.data.pageNum=pageNum;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
    }
    getList(num) {
        // let pageNum = e.detail;
        let url = "/prod-api/manage/booking/personalMessage?pageNum=" + num + "&pageSize=10";
        if (this.sourceType) {
            url += "&sourceType=" + this.sourceType;
        }
        if (this.businessName) {
            url += "&businessName=" + this.businessName;
        }
        fetch(url, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                if (res.code == 200) {
                    res.rows.map(item => {
                        if (item.sourceType == 2) {
                            item.url = productUrl + item.businessId
                            item.name = "产品";
                        } else if (item.sourceType == 3) {
                            item.url = solutionUrl + item.businessId;
                            item.name = "方案";
                        } else if (item.sourceType == 5) {
                            item.url = exhProductUrl + item.businessId;
                            item.name = "展品";
                        }
                    })
                    this.dataList = res.rows;
                    this.data.total = res.total;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
    }
    removeFollow(id) {
        if (confirm("确定要取消收藏吗？")) {
            let url = "/prod-api/manage/personal/removeFavorite?favoriteId=" + id;
            fetch(url, {
                method: "delete",
                headers: {
                    "Content-type": "application/json",
                    "SiteKey": "Portal",
                    Authorization: `Bearer ${getToken()}`
                }
            }).then(response => response.json())
                .then(res => {
                    this.getList(1);
                    this.update();
                })
        }

    }
    showDetail(obj) {
        this.serviceArticle = obj;
        this.serviceSetting.show = true;
        this.update()
    }
    updateService() {
        this.serviceSetting.show = false;
        this.update()
    }
    install() {
        this.getList(1);
    }
    query(e) {
        this.getList(1)
    }
    installed() {
    }
    render() {
        return (
            <>
                <div className="oi-row-full font26">洽谈邀约</div>
                <div className="list-area">
                    <table class="table-list" cellPadding="0" border="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="index-col">序号</th>
                                <th class="subject-col">主题</th>
                                <th class="time-col">创建时间</th>
                                <th class="status-col">状态</th>
                                <th class="action-col">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.dataList.length > 0 ? this.dataList.map((item, i) => (
                                <tr>
                                    <td class="index-col">{i + 1}</td>
                                    <td class="subject-col">{item.title}</td>
                                    <td class="time-col">{item.createTime.replace("T", " ")}</td>
                                    <td class="status-col">
                                        {item.auditStatus == 1 ? '未回复' : '已回复'}
                                    </td>
                                    <td class="action-col">
                                        <span style="cursor:pointer" onClick={evt => {
                                            this.showDetail(item)
                                        }}>查看详情</span>
                                    </td>
                                </tr>
                            )) : (
                                <tr class="text-c">
                                    <td colSpan="6">
                                        暂无数据
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {
                        this.data.total > 0 ? <div className="pager-area">
                            <oi-pager css={this.myCss} total={this.data.total} page-size={this.data.pageSize} page-num={this.data.pageNum} onPaged={evt => this.getPagedList(evt)} />
                        </div> : <div />
                    }


                </div>
                <oi-dialog-box settings={this.serviceSetting}>
                    <div className="oi-row">
                        <table className="form-table">
                            <tr>
                                <td className="form-label">主题：</td>
                                <td className="form-content">{this.serviceArticle.title}</td>
                            </tr>
                            <tr>
                                <td className="form-label">联系电话：</td>
                                <td className="form-content">{this.serviceArticle.tel}</td>
                            </tr>
                            <tr>
                                <td className="form-label">预约时间：</td>
                                <td className="form-content">{this.serviceArticle.bookTime}</td>
                            </tr>
                            <tr>
                                <td className="form-label">留言内容：</td>
                                <td className="form-content"> <p className="info-content" unsafeHTML={this.serviceArticle.content}></p></td>
                            </tr>
                        </table>

                    </div>
                    <div className="reply-box">
                        {
                            this.serviceArticle.auditStatus == 3 ? <div>
                                <p>回复时间：{this.serviceArticle.replyTime}</p>
                                <p>回复内容：{this.serviceArticle.replyContent}</p>
                            </div> : <span></span>
                        }
                    </div>
                    <div slot="footer" className="text-c">
                        <button className="btn-submit m3t" onClick={evt => {
                            this.updateService()
                        }}>确 定</button>
                    </div>
                </oi-dialog-box>
            </>
        )
    }
}
