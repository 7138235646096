const { h, define, Component, createRef } = omii;
import "../../es-lib/omii/latest/ui/omii-ui.js"
import { getToken } from "../token";
import css from "../css/home.scss";

export default class extends Component {
    static css = css;
    dataList = [];
    companyName = "";
    attentionTime = "";
    attentionEndTime = "";
    sourceType = "";
    businessName = "";
    data = {
        total: 0,
        pageSize: 10,
        pageNum: 1
    }
    pageRef = createRef();
    myCss = `
    .pager .page  {
        font-size:1.4rem!important;
        padding:6px 9px;
        border:solid 1px #f5f5f9;
        margin:0 5px
    }
    .pager .page:hover{
        background:#002040 !important;
    }
    .pager .active{
        background:#002040 !important;
    }
    .pager .jumper,.pager .info{
        font-size:1.4rem!important;
    }
    .pager .jumper .jump-to{
        margin:0 1rem;
    }
    `
    getPagedList(e) {
        let pageNum = e.detail;
        let url = "/prod-api/manage/personal/getFollowList?pageNum=" + pageNum + "&pageSize=10";
        if (this.sourceType) {
            url += "&sourceType=" + this.sourceType;
        }
        if (this.businessName) {
            url += "&businessName=" + this.businessName;
        }
        fetch(url, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                if (res.code == 200) {
                    res.rows.map(item => {
                        if (item.sourceType == 1) {
                            item.url = enterpriseUrl + item.businessId
                            item.name = "企业";
                        } else if (item.sourceType == 2) {
                            item.url = exhibitorUrl + item.businessId;
                            item.name = "企业";
                        } else if (item.sourceType == 4) {
                            item.url = expertUrl + item.businessId;
                            item.name = "专家";
                        }
                    })
                    this.dataList = res.rows;
                    this.data.total = res.total;
                    this.data.pageNum=pageNum;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
    }
    getList(num) {
        // let pageNum = e.detail;
        let url = "/prod-api/manage/personal/getFollowList?pageNum=" + num + "&pageSize=10";
        if (this.sourceType) {
            url += "&sourceType=" + this.sourceType;
        }
        if (this.businessName) {
            url += "&businessName=" + this.businessName;
        }
        fetch(url, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                if (res.code == 200) {
                    res.rows.map(item => {
                        if (item.sourceType == 1) {
                            item.url = enterpriseUrl + item.businessId
                            item.name = "企业";
                        } else if (item.sourceType == 2) {
                            item.url = exhibitorUrl + item.businessId;
                            item.name = "展商";
                        } else if (item.sourceType == 4) {
                            item.url = expertUrl + item.businessId;
                            item.name = "专家";
                        }
                    })
                    this.dataList = res.rows;
                    this.data.total = res.total;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
    }
    removeFollow(id) {
        if (confirm("确定要取消关注吗？")) {
            let url = "/prod-api/manage/personal/removeFollow?followId=" + id;
            fetch(url, {
                method: "delete",
                headers: {
                    "Content-type": "application/json",
                    "SiteKey": "Portal",
                    Authorization: `Bearer ${getToken()}`
                }
            }).then(response => response.json())
                .then(res => {
                    this.getList(1);
                    this.update();
                })
        }

    }

    install() {
        this.getList(1);
    }
    query(e) {
        this.getList(1)
    }
    installed() {
    }
    render() {
        return (
            <>
                <div className="oi-row-full font26">我的关注</div>
                <div className="list-area">
                    <div className="row search-area">
                        <div className="search-col">
                            类型: <select o-model="sourceType" class="normalSelect">
                                <option value="" selected>全部</option>
                                <option value="1">企业</option>
                                <option value="2">展商</option>
                                <option value="4">专家</option>
                            </select>
                        </div>
                        <div className="search-col">
                            名称: <input o-model="businessName" className="normalInput" placeholder="名称" />
                        </div><button className="search-btn" onClick={e => {
                            this.query()
                        }}>搜索</button>
                    </div>
                    <table class="table-list" cellPadding="0" border="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="index-col">序号</th>
                                <th class="type-col">类型</th>
                                <th>名称</th>
                                <th class="time-col">关注时间</th>
                                <th class="action-col">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.dataList.length > 0 ? this.dataList.map((item, i) => (
                                <tr>
                                    <td class="index-col">{i + 1}</td>
                                    <td class="type-col">【{item.name}】</td>
                                    <td> <a href={item.url} target="_blank">{item.businessName}</a></td>
                                    <td class="time-col">
                                        {item.createTime.replace("T", " ")}
                                    </td>
                                    <td class="action-col">
                                        <span class="action" onClick={evt => {
                                            this.removeFollow(item.followId)
                                        }}>
                                            取消关注
                                        </span>
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="5" className="text-c">
                                        暂无数据
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {
                        this.data.total > 0 ? <div className="pager-area">
                            <oi-pager css={this.myCss} total={this.data.total} page-size={this.data.pageSize} page-num={this.data.pageNum} onPaged={evt => this.getPagedList(evt)} />
                        </div> : <div />
                    }

                </div>
            </>
        )
    }
}
