export default `
.disnone {
  display: none;
}

.page-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  text-align: center;
  background: url(assets/images/regist/loading-big.gif) no-repeat rgba(0, 0, 0, 0.3019607843) center center;
  background-size: 6rem;
}

.page-loading label {
  color: #fff;
  margin: 50% auto;
}

.regist-box {
  width: 100%;
  margin: 0rem auto;
  text-align: left;
  min-height: 12rem;
}

.regist-box-main {
  margin-top: 5rem;
}

div.fullRow {
  clear: both;
  width: 100%;
  display: inline-block;
}

.col24 {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
  box-sizing: border-box;
  align-content: center;
}

.form-label {
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

i.red {
  color: red;
  font-style: normal;
}

.red {
  font-style: normal;
  margin-right: 0.6rem;
}

.form-label label input {
  vertical-align: middle;
  margin-bottom: 5px;
}

.form-input {
  -webkit-appearance: none;
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: 14px;
  line-height: 6rem;
  outline: 0;
  padding: 0 1.5rem;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 6rem;
}

.errorTip {
  color: #F56C6C;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 1.5rem;
}

.col12 label,
.col24 label {
  float: left;
  font-size: 14px;
  width: auto;
  margin: 0.8rem 3rem 1rem 0;
}
.col12 label input,
.col24 label input {
  vertical-align: middle;
  margin-bottom: 4px;
}

.hide {
  display: none;
}

.login-code-img {
  position: absolute;
  right: 0px;
}

.pbr {
  position: absolute;
  right: 4px;
  cursor: pointer;
  color: #0c64bf;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  outline: none;
}

.step-btn {
  text-align: center;
  margin: 3rem auto 4rem;
}

.step-btn .btn-last,
.step-btn .btn-next,
.step-btn .btn-reset {
  width: 14rem;
  height: 4.5rem;
  border-radius: 2.5rem;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 4.5rem;
}

.step-btn .btn-last {
  background-color: #0582ee;
  font-size: 1.6rem;
  color: #fff;
  border: 0.1rem #0582ee solid;
}

.step-btn .btn-next {
  background-color: #0582ee;
  font-size: 1.6rem;
  color: #fff;
  border: 0.1rem #0582ee solid;
  margin-right: 3rem;
}

.step-btn .btn-reset {
  background-color: #fff;
  font-size: 1.6rem;
  color: #0582ee;
  border: 0.1rem #0582ee solid;
}

.img-success {
  width: 100%;
  height: 33rem;
  margin: 16rem auto 0;
  background: url(assets/images/regist/img_success.png) no-repeat #fff top center;
  background-size: 38.2rem;
  padding-top: 33rem;
  box-sizing: border-box;
}

.img-fail {
  width: 100%;
  height: 33rem;
  margin: 16rem auto 0;
  background: url(assets/images/regist/img_fail.png) no-repeat #fff top center;
  background-size: 38.2rem;
  padding-top: 33rem;
  box-sizing: border-box;
}

.regist-tips {
  text-align: center;
  font-size: 2.4rem;
  color: #353535;
  font-weight: bold;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiRTpcXFByb2plY3RzXFxhbmZhbmdcXGxpbnV4XFx1c2VyLWNlbnRlclxcc3JjXFxjc3MiLCJzb3VyY2VzIjpbImJpbmRQaG9uZS5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0U7OztBQUVGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTs7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUVGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUVGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUVGO0VBQ0U7RUFDQTs7O0FBRUY7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7OztBQUVGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBSUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBS0E7QUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0FBQUE7RUFDRTtFQUNBOzs7QUFLTjtFQUNFOzs7QUFFRjtFQUNFO0VBQ0E7OztBQUVGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUVBOzs7QUFHRjtBQUFBO0FBQUE7RUFHRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBOzs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBIn0= */`;
