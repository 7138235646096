export default `
.layer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
}

span.errorTip {
  z-index: 200;
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  color: red;
}

.oi-dialog-box {
  z-index: 10000;
  position: fixed;
  width: 600px;
  height: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;
  padding: 0 30px;
}
.oi-dialog-box .oi-row.title {
  color: #002040;
  font-size: 1.8rem;
  margin-top: 2rem;
  line-height: 100%;
  margin-left: -1rem;
  margin-right: -1rem;
}
.oi-dialog-box .content {
  margin-top: 4rem;
}

.close {
  position: absolute;
  right: 3rem;
  top: 2rem;
  background: url(assets/images/close.png) no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  text-indent: -9999em;
  transition: all 0.3s ease-in;
}
.close:hover {
  transform: rotate(180deg);
}

.hide {
  display: none;
}

input[type=text],
textarea,
input[type=date],
input[type=month] {
  border: #ddd solid 1px;
  outline: none;
}

textarea {
  width: 100%;
  resize: none;
}

fieldset {
  border-color: #f9f9f9;
  margin: 1rem 0;
}
fieldset legend {
  font-size: 1.8rem;
  margin: 1rem;
}

.oi-row {
  clear: both;
  margin-left: -10px;
  margin-right: -10px;
  width: 100%;
  display: flex;
  align-items: center;
}
.oi-row .oi-label {
  color: #000;
  clear: both;
  font-size: 1.8rem;
  display: block;
  line-height: 250%;
}
.oi-row.oi-row-full {
  margin-left: 0;
  margin-right: 0;
  border-bottom: #ddd solid 1px;
  border-right: #ddd solid 1px;
  border-left: #ddd solid 1px;
  position: relative;
}
.oi-row.oi-row-full:first-child {
  border-top: #ddd solid 1px;
}
.oi-row .oi-col-content {
  position: relative;
  line-height: 6rem;
  font-size: 1.8rem;
}
.oi-row .oi-col-content .smsCode {
  position: absolute;
  right: 2rem;
  cursor: pointer;
  top: 2rem;
  color: #82a7d0;
  line-height: 100%;
  font-size: 1.6rem;
}
.oi-row .oi-col-content input[type=text],
.oi-row .oi-col-content input[type=password] {
  height: 4.6rem;
  line-height: 4.6rem;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px #215eb9;
  outline: none;
  border-radius: 4px;
  text-indent: 6px;
  font-size: 1.6rem;
  box-shadow: 0 0 15px #f4f8fe inset;
}
.oi-row .oi-col-24 {
  width: 100%;
  position: relative;
}
.oi-row .m3b {
  margin-bottom: 1rem;
}
.oi-row .oi-col-12 {
  width: 50%;
  float: left;
  padding-left: 0px;
  padding-right: 0px;
  box-sizing: border-box;
  position: relative;
}
.oi-row .label-tit {
  width: 16rem;
  background: #f3f3f3;
  display: inline-block;
  box-sizing: border-box;
  padding: 1rem;
  margin-right: 0.5rem;
  white-space: nowrap;
}
.oi-row .label-tit::before {
  content: "*";
  padding-right: 0.4rem;
  color: red;
}

.btn-submit {
  background: #00468c;
  padding: 1rem 4rem;
  color: #fff;
  font-size: 2rem;
  border-radius: 3rem;
  letter-spacing: 2px;
  cursor: pointer;
  border: medium none;
}
.btn-submit.m3t {
  margin-top: 2rem;
}

.radio-cols {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.radio-cols input[type=radio] {
  vertical-align: middle;
  margin-bottom: 0.5rem;
}

.flex-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.label-tit {
  font-size: 1.6rem;
}

.oi-row .height100 {
  height: 100px;
  display: inline-block;
  line-height: 100px;
  padding-top: 0;
  padding-bottom: 0;
}

.checkbox-cols {
  width: 80%;
  font-size: 1.6rem;
  word-break: break-all;
}
.checkbox-cols input {
  vertical-align: middle;
  margin-bottom: 0.5rem;
}

.right-width {
  width: 80%;
  resize: none;
}

.footer-btns {
  margin-top: 2rem;
  text-align: center;
}

button.btn {
  border: medium none;
  margin: 0 1rem;
  background: none transparent;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 10px;
}
button.btn.btn-primary {
  background: #00468c;
  color: #fff;
}
button.btn.btn-default {
  background: #ddd;
}
button.btn.btn-add, button.btn.btn-delete {
  color: #215eb9;
}

.custom-table {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.custom-table thead th {
  background: #ddd;
  font-size: 1.6rem;
  line-height: 100%;
  padding: 1rem 0;
}
.custom-table tbody td {
  border-bottom: #ddd solid 1px;
  padding: 1rem 0;
}

.book-item {
  display: flex;
  justify-content: flex-start;
}

i.red {
  color: red;
  font-style: normal;
  margin-right: 0.4rem;
}

.project-item {
  float: left;
  margin-right: 1rem;
  height: 30px;
  position: relative;
}
.project-item label {
  white-space: nowrap;
  margin-right: 10px;
  font-size: 1.6rem;
}
.project-item input[type=text] {
  width: 12rem;
}
.project-item input[type=radio] {
  vertical-align: middle;
  margin-bottom: 0.4rem;
}

.title-item input {
  width: 90%;
}

.book-item-flex {
  display: flex;
  align-items: center;
}

.book-item-row {
  margin-bottom: 3rem;
}

.btn-upload {
  font-size: 1.2rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiRTpcXFByb2plY3RzXFxhbmZhbmdkYXNodWp1XFxuZXdQaW5nVGFpQ29kZVxcQXBwc1xcdXNlci1jZW50ZXJcXHNyY1xcY29tcG9uZW50c1xcYXBwbHkiLCJzb3VyY2VzIjpbImZvcm0uc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBSTtFQUNJO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFHSjtFQUNJO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUlKO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNJO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHSjtFQUNJOzs7QUFJUjtFQUNJO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDSTs7O0FBSVI7RUFDSTs7O0FBR0o7QUFBQTtBQUFBO0FBQUE7RUFJSTtFQUNBOzs7QUFHSjtFQUNJO0VBQ0E7OztBQUdKO0VBQ0k7RUFDQTs7QUFFQTtFQUNJO0VBQ0E7OztBQUlSO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHSjtFQUNJO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNJOztBQUlSO0VBQ0k7RUFDQTtFQUNBOztBQUVBO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0o7QUFBQTtFQUVJO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUVBOztBQUlSO0VBQ0k7RUFDQTs7QUFHSjtFQUNJOztBQUdKO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUdKO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDSTtFQUNBO0VBQ0E7OztBQUtaO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtFQUNJOzs7QUFJUjtFQUNJO0VBQ0E7RUFDQTs7QUFFQTtFQUNJO0VBQ0E7OztBQUlSO0VBQ0k7RUFDQTtFQUNBOzs7QUFHSjtFQUNJOzs7QUFJQTtFQUNJO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUlSO0VBQ0k7RUFDQTtFQUNBOztBQUVBO0VBQ0k7RUFDQTs7O0FBSVI7RUFDSTtFQUNBOzs7QUFHSjtFQUNJO0VBQ0E7OztBQUdKO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0k7RUFDQTs7QUFHSjtFQUNJOztBQUdKO0VBRUk7OztBQUlSO0VBQ0k7RUFrQkE7O0FBZkk7RUFDSTtFQUNBO0VBQ0E7RUFDQTs7QUFLSjtFQUNJO0VBQ0E7OztBQU9aO0VBQ0k7RUFDQTs7O0FBR0o7RUFDSTtFQUNBO0VBQ0E7OztBQUdKO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7O0FBRUE7RUFDSTtFQUNBO0VBQ0E7O0FBR0o7RUFDSTs7QUFHSjtFQUNJO0VBQ0E7OztBQUtKO0VBQ0k7OztBQUlSO0VBQ0k7RUFDQTs7O0FBR0o7RUFDSTs7O0FBR0o7RUFDSSJ9 */`;
