export default `
@charset "UTF-8";
.otherLogin {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.otherLogin .title span {
  display: block; /*设置为块级元素会独占一行形成上下居中的效果*/
  position: relative; /*定位横线（当横线的父元素）*/
  color: #b1afaf;
}
.otherLogin .title span::before, .otherLogin .title span::after {
  content: "";
  position: absolute; /*定位背景横线的位置*/
  top: 50%;
  background: #e1e1e1; /*背景横线颜色*/
  width: 32%; /*单侧横线的长度*/
  height: 1px;
}
.otherLogin .title span::before {
  left: 2%;
}
.otherLogin .title span::after {
  right: 2%;
}
.otherLogin .image {
  width: 60%;
  margin: 1rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.otherLogin .image .loginIcon {
  position: relative;
  width: 4.2rem;
  height: 4rem;
  cursor: pointer;
}
.otherLogin .image .loginIcon img {
  width: 100%;
  height: 100%;
}
.otherLogin .image .loginIcon text {
  display: none;
}
.otherLogin .image .loginIcon .active {
  color: rgb(107, 112, 131);
  display: block;
  position: absolute;
  top: -3.8rem;
  right: -50%;
  width: 7.1rem;
  background: rgb(255, 255, 255);
  border: 0.1rem solid rgb(107, 112, 131);
  border-radius: 1.5rem;
  padding: 0.5rem;
}

.disnone {
  display: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiRTpcXFByb2plY3RzXFxhbmZhbmdkYXNodWp1XFxuZXdQaW5nVGFpQ29kZVxcQXBwc1xcdXNlci1jZW50ZXJcXHNyY1xcY3NzIiwic291cmNlcyI6WyJvdGhlckxvZ2luLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtBQUFBO0VBQ0U7RUFDQTtFQUNBOztBQUVFO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVGO0VBQ0U7O0FBRUY7RUFDRTs7QUFJTjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBQ0E7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7QUFDQTtFQUNFO0VBQ0E7O0FBRUY7RUFDRTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFLUjtFQUNFIn0= */`;
