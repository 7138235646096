export default `
* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #E8E8E8;
}

.container {
  position: relative;
}

#canva {
  background: indianred;
}

#block {
  position: absolute;
  left: 0px;
}

.refreshIcon {
  position: absolute;
  left: 280px;
  top: 5px;
  width: 21px;
  height: 20px;
  cursor: pointer;
  background: url(assets/images/verify/refresh.png);
  display: block;
}

.verSliderBlock {
  height: 40px;
  width: 40px;
  background: url(assets/images/verify/right_arrow.png);
  background-size: 100%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  position: absolute;
  text-align: center;
  line-height: 40px;
  color: #45494c;
  font-size: 25px;
  font-weight: 400;
}

.bar {
  position: relative;
  text-align: center;
  width: 310px;
  height: 40px;
  line-height: 40px;
  margin-top: 15px;
  background: #f7f9fa;
  color: #45494c;
  border: 1px solid #e4e7eb;
  display: block;
}

#bar-mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  border: 0 solid #1991fa;
  background: #d1e9fe;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiRTpcXFByb2plY3RzXFxhbmZhbmdcXGxpbnV4XFx1c2VyLWNlbnRlclxcc3JjXFxjb21wb25lbnRzXFxpbWFnZVZlcmlmeSIsInNvdXJjZXMiOlsiaW5kZXguc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJO0VBQ0E7OztBQUVKO0VBQ0k7OztBQUVKO0VBQ0k7OztBQUVKO0VBQ0k7OztBQUdKO0VBQ0k7RUFDQTs7O0FBRUo7RUFDSTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFFSjtFQUNHO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0g7RUFDSTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBRUo7RUFDSTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0EifQ== */`;
