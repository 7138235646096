const { h, define, Component, classNames, uniqueTag } = omii;
import "../../es-lib/omii/latest/ui/omii-ui.js"
import css from "./labels.scss"

export default class extends Component {
    labelData = [];
    checkedResult = [];

    checkedArr = [];
    static css = css;
    style() {
        return `
       h1 {
            color: red;
        }
    `;
    }
    async getLabels() {
        var res = await fetch(remoteUrl + this.props.settings.url, {});
        var result = await res.json();
        this.labelData = result.data;
        this.update();
    }
    customAction(e) {
        let id = e.target.id;
        let customName = e.target.value;
        let customCheck = e.target.parentNode.querySelector(".customCheck");
        console.log(customName)
        let obj = this.checkedArr.find(a => a.labelId == id);
        if (customName) {
            customCheck.checked = true;
            console.log(111)
            if (obj) {
                obj.labelName = customName;
                console.log(222)
                console.log(obj)
            } else {
                console.log(333)
                let newObj = {
                    labelId: customCheck.value,
                    labelName: customName,
                    editFlag: customCheck.getAttribute("edit"),
                    subBusiness: this.props.settings.area
                }
                this.checkedArr.push(newObj)
            }
        } else {
            customCheck.checked = false;
            if (obj) {
                let objIndex = this.checkedArr.findIndex(a => a.labelId == id);
                this.checkedArr.splice(objIndex, 1);
            }
        }
        // console.log(this.checkedArr)
        // this.updateSettings({
        //     relation: this.checkedArr
        // })
        console.log(this.checkedArr)
        this.fire("got",this.checkedArr)

    }

    checkAction(e) {
        let id = e.target.value;
        let objIndex = this.checkedArr.findIndex(a => a.labelId == id);
        if (e.target.checked) {
            let checkedObj = {
                labelId: e.target.value,
                labelName: e.target.getAttribute("label"),
                editFlag: e.target.getAttribute("edit"),
                subBusiness: this.props.settings.area
            }
            this.checkedArr.push(checkedObj);
        } else {
            this.checkedArr.splice(objIndex, 1)
        }
        this.fire("got",this.checkedArr)
    }

    install() {
        this.getLabels();
    }
    render() {
        return (
            <>
                {this.labelData.map(item => {
                    return (
                        <label class='oi-label'>
                            <input onClick={evt => {
                            this.checkAction(evt)
                        }} type={this.props.settings.type?this.props.settings.type:'checkbox'} class="customCheck" edit={item.editFlag} value={item.dictValue?item.dictValue:item.labelId} label={item.dictLabel?item.dictLabel:item.labelName} name={this.props.settings.area} o-model="checkedResult" />{item.dictLabel?item.dictLabel:item.labelName}<input onBlur={evt => {
                            this.customAction(evt)
                        }} value="" id={item.labelId?item.labelId:item.dictValue} class="customName" style={item.editFlag == '0' ? 'display:none' : 'display:none'} type='text'></input></label>
                    )
                })
                }
            </>
        )
    }

}
