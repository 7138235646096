export default `
.layer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
}

.oi-dialog-box {
  z-index: 10000;
  position: fixed;
  width: 600px;
  height: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;
  padding: 0 30px;
}
.oi-dialog-box .oi-row.title {
  color: #002040;
  font-size: 1.8rem;
  margin-top: 2rem;
  line-height: 100%;
  margin-left: -1rem;
  margin-right: -1rem;
}
.oi-dialog-box .content {
  margin-top: 4rem;
}

.close {
  position: absolute;
  right: 3rem;
  top: 2rem;
  background: url(assets/images/close.png) no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  text-indent: -9999em;
  transition: all 0.3s ease-in;
}
.close:hover {
  transform: rotate(180deg);
}

.hide {
  display: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiRTpcXFByb2plY3RzXFxhbmZhbmdcXGxpbnV4XFx1c2VyLWNlbnRlclxcc3JjXFxjb21wb25lbnRzXFxkaWFsb2ciLCJzb3VyY2VzIjpbImRpYWxvZy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFJO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUVKO0VBQ0k7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFDQTtFQUNJO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFFSjtFQUNJOzs7QUFHUjtFQUNJO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBQ0E7RUFDSTs7O0FBR1I7RUFDSSJ9 */`;
