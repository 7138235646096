const { h, uniqueTag, Component, define, apis, createRef, render, route } = omii;
import security from "./security";
import css from "./css/login.scss";
import { codeController } from "./apis";
import "./register";
import Cookies from 'js-cookie'
import { setToken, getToken, isTokenExpired, removeToken } from "./token"
import { constant } from './lib/constant'
import homePage from "./home";
import otherLogin from './otherLogin'
import imageVerify from './components/imageVerify/index'

define('af-other-login',otherLogin)
define("image-verify", imageVerify)

export default class extends Component {
  userCatalog = '01';//01-企业，02-个人
  type = 1;
  codeImg = '';
  tip = "获取验证码";
  disBtn = false
  static css = css;
  verify = {
    phone: "",
    phoneCode: "",
    code:"",//图片验证码
    userCatalog:""
  }
  // phoneReg = /^[1][3,5,4,7,8,9][0-9]{9}$/;
  phoneReg = /^\d{11}$/;//调整手机号验证为 只校验11位数字 --update by sxm 20240715

  data = {
    username: "",
    password: "",
    code: "",
  };
  isImageShow= false;//是否显示图片滑块验证码弹窗

  codeRef = createRef();
  phoneRef = createRef();
  imgVerifyRef = createRef();

  initialPwdSetting = {
    width: "100rem",
    height: "60rem",
    show: false,
    title: "登录提示"
  }
  initialPwdTips = '';
  // initialPwdTips1 = '';
  // initialPwdTips2 = '';
  initialPwdTipsList = [];
  corpName = '';//企业名称
  qrCodeSetting = {
    width: "40rem",
    height: "45rem",
    show: false,
    title: "扫码进群"
  }

  refreshCode() {
    codeController.get().then((r) => {
      if (this.type == 1) {
        this.codeRef.current.src = `data:image/jpg;base64,${r.img}`;
      } else if (this.type == 2) {
        this.phoneRef.current.src = `data:image/jpg;base64,${r.img}`;
      }
      this.data.uuid = r.uuid;
    });
  }
  //切换用户类型
  switchUser(v) {
    this.userCatalog = v;
    this.type = 1;
    // this.refreshCode();
    this.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
    this.update();
  }
  switchTab(v) {
    this.type = v;
    // this.refreshCode();
    this.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
    this.isImageShow = false;
    if (v == 3) {
      this.showCode()
    }
    this.update();
  }
  enterAction(e) {
    if (e.keyCode == 13) {
      this.openImageVarify();
    }
  }
  //手机验证码登录
  async codeLogin() {
    if (!this.phoneReg.test(this.verify.phone)) {
      alert('输入正确手机号')
      return ;
    }
    if(this.verify.phoneCode==''){
      alert('请输入短信验证码')
      return;
    }
    this.verify.userCatalog = this.userCatalog;
    let obj = JSON.parse(JSON.stringify(this.verify));
    fetch('/prod-api/auth/phoneLogin', {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        "Content-type": "application/json"
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.code == 200) {
          if (data.data.code == 403) {
            location.href = "#/Register";
          } else {
            // sessionStorage.setItem("af-auth-token", data.data.access_token)
            setToken(data.data.access_token,720);
            location.reload();
          }
        }else{
          alert(data.msg)
        }

      })
    this.update();

  }
  //验证码
  getVerify() {
    let num = 60
    //设置循环定时器
    if (this.phoneReg.test(this.verify.phone)) {
      if(this.verify.code==''){
        alert('请先通过图片滑块验证')
        return;
      }
      this.disBtn = true
      let that = this

        fetch(`/prod-api/system/user/checkIsTelReg?phonenumber=${this.verify.phone}&userType=${this.userCatalog}`, {
            method: 'get',
        })
            .then(response1 => response1.json())
            .then(data1 => {
                if (data1.code == 200) {
                    if (data1.data == 0) {
                        alert("手机号码“" + that.verify.phone + "”不存在！")
                        // that.refreshCode();
                        that.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
                        that.disBtn = false
                        that.update();
                    }else if (data1.data == 1) {
                      // fetch(`/prod-api/manage/open/smscode/Send?tel=${this.verify.phone}&code=${this.verify.code}`, {
                      // fetch(`/prod-api/manage/open/smscode/SendSMSIncludeCaptcha?tel=${this.verify.phone}&code=${this.verify.code}&uuid=${this.data.uuid}`, {//20240510之前，图片验证码版本
                      fetch(`/prod-api/manage/open/smscode/SendSMSIncludeCaptchaHd?tel=${this.verify.phone}&code=${this.verify.code}&uuid=${this.data.uuid}`, {//20240510之后，图片滑块验证码版本
                        method: 'get',
                      })
                          .then(response => response.json())
                          .then(data => {
                            if (data.code == 200) {
                              let timer = setInterval(function () {
                                num--
                                if (num <= 0) {
                                  //清除循环执行定时器
                                  clearInterval(timer);
                                  //还原
                                  num = 60;
                                  that.disBtn = false
                                  that.tip = "获取验证码";
                                } else {
                                  that.tip = num + '秒后可重新获取';
                                }
                                that.update();
                              }, 1000)
                            } else {
                              // that.refreshCode();
                              that.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
                              that.disBtn = false
                              that.update();
                              alert(data.msg)
                            }
                          })
                    } else {
                      alert("手机号码“" + that.verify.phone + "”重复，请使用用户名/密码进行登录！")
                      // that.refreshCode();
                      that.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
                      that.disBtn = false
                      that.update();
                    }
                } else {
                    // that.refreshCode();
                    that.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
                    that.disBtn = false
                    that.update();
                    alert(data1.msg)
                }
            })

    } else {
      alert('输入正确手机号')
    }
    this.update();

  }
  // 判断登录状态
  wxLogin(obj) {
    let timeInt = setInterval(() => {
      let url = obj
      fetch("/dabg-api/dabg/sjbg/api/wechat/checkLogin/" + url, {
        method: 'get',
      })
        .then(response => response.json())
        .then(res => {
          if (res.isLogin) {
            clearInterval(timeInt)
            // sessionStorage.setItem("af-auth-token", res.accessToken)
            setToken(res.accessToken,720);
            location.reload();
          }
        })
    }, 3000);

  }

  showCode() {
    // let url = 'http://120.46.198.156/dabg/sjbg/con/qrPage'
    fetch("/dabg-api/dabg/sjbg/con/qrPage", {
      method: 'get',
    })
      .then(response => response.json())
      .then(res => {
        if (res.code == 0) {
          // let result = JSON.parse(res.data);

          this.codeImg = res.data.qrCodeUrl;
          this.update();
        } else {
          alert(res.msg)
        }
        this.wxLogin(res.data.ticket)
        // this.update();
      })
    // fetch("/prod-api/manage/wx/createCode", {
    //   method: 'get',
    // })
    // .then(response => response.json())
    // .then(data => {
    //   if (data.code == 200) {
    //     let result = JSON.parse(data.msg);
    //     this.codeImg = "http://mp.weixin.qq.com/cig-bin/showqrcode?ticket=" + result.ticket;
    //     this.update();
    //   } else {
    //     alert(data.msg)
    //   }
    // })
  }
  //第三方登录
  handleOtherLogin(){
    var paramArr = location.href.substr(location.href.indexOf("?") + 1).split("&");
    let obj = {};
    for (let i = 0; i < paramArr.length; i++) {
      let name = paramArr[i].substr(0, paramArr[i].indexOf("="));
      let value = paramArr[i].substr(paramArr[i].indexOf("=") + 1);
      if (name) {
        obj[name] = value;
      }
    }
    if(obj.code&&obj.state=='weixin'){//微信登录
      // console.log(obj.code)
      let url='/prod-api/manage/wx/weiXinLogin?appid='+constant.weixinId+'&secret='+constant.weixinSecret+'&code='+obj.code
      //&grant_type=authorization_code'
      fetch(url,{
          method:'get'
      }).then(response => response.json())
      .then(res=>{
          console.log(res)
          this.connentInfo(0,res.data.openid,res.data.access_token)
      })
    }else if(obj.code&&obj.state==1213456){//qq登录
      let url = "/qq/oauth2.0/token?grant_type="+obj.code+"&client_id=" + constant.QQappid + "&client_secret="+ constant.QQkey + "&redirect_uri=" + location.href + "&code=" + obj.code
      fetch(url,{
        method:'get'
      }).then(res=>{
        localStorage.setItem('qq_access_token',obj.access_token)
        localStorage.setItem('qq_access_time',new Date())
        let url1="/qq/oauth2.0/me?access_token=" + obj.access_token
        fetch(url1,{
          method:'get'
        }).then(r=>{
          // console.log(r)
          let url2 = "/qq/user/get_user_info?access_token=" + obj.access_token+ "&oauth_consumer_key=" + contant.QQappid + "&openid=" + r.openid
          fetch(url2,{
            method:'get'
          }).then(e=>{
            console.log(e)//获取到用户信息
          })
        })
      })
    }else if(obj.code){//微博登录
      let url='/prod-api/manage/wx/weiBoLogin'
      fetch(url,{
        headers: {
          "Content-type": "application/json"
        },
        method:'post',
        body:JSON.stringify({
          client_id:String(constant.weiboKey),
          client_secret:constant.weiboSecret,
          //grant_type:'authorization_code',
          redirect_uri:constant.url,
          code:obj.code
        })
      }).then(response => response.json())
      .then(res=>{
        console.log(res)
        this.connentInfo(2,res.data.uid,res.data.access_token)
      })
    }
  }
  async handleLogin() {
    if (!this.data.username) {
      alert("请输入用户名");
      return false;
    }
    if (!this.data.password) {
      alert("请输入密码");
      return false;
    }
    if (!this.data.code) {
      alert("请输入验证码");
      return false;
    }
    this.data.userCatalog = this.userCatalog;
    if (await security.login(this.data)) {
      this.fire("logined",this.userCatalog);
    } else {
      // alert("请检查用户名密码是否正确。");
      // this.refreshCode();
      this.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
    }

  }
  async install() {
    // this.refreshCode();
    var token = new URL(location.href).searchParams.get("token");
    if(location.href.indexOf("?token=")>=0){
      token=location.href.substring(location.href.indexOf("?token=")+7);
    }
    // console.log(token)
    if (token) {
      setToken(token, 720);
      let userInfo = await security.getUserInfo();
      if(userInfo.code==401){
        removeToken();
        // Cookies.remove("af-auth-token")
      }else
      if (userInfo.code == 200) {
        location.reload();
      }
    }
    this.handleOtherLogin()
  }
  goPage(url) {
    location.href = '#' + url;
    location.reload();
  }
  //密码提示功能 --add by sxm 20230324 --update by sxm 20231219
  updateInitialPwd(show){
    // this.initialPwdTips = '';
    // this.initialPwdTips1 = '';
    // this.initialPwdTips2 = '';
    this.initialPwdTipsList = [];
    this.corpName = '';
    this.$$(".initial-pwd-box input").map(item => item.value = '')
    this.initialPwdSetting.show = show;
    this.update();
  }
  //获取初始密码
  getInitialPwd(){
    // this.initialPwdTips1 = '';
    // this.initialPwdTips2 = '';
    // this.initialPwdTips = '';
    this.initialPwdTipsList = [];
    this.update();
    if(this.corpName==''){
      alert("请输入企业名称！");
      this.update();
      return;
    }
    fetch(`/prod-api/manage/enterpriseNew/getEntContactmanByEntName?entName=${this.corpName}`, {
      method: 'get',
    })
    .then(response => response.json())
    .then(data => {
      if (data.code == 200) {
        this.initialPwdTipsList = data.data;
        if(this.initialPwdTipsList==null || this.initialPwdTipsList.length==0){
          this.initialPwdTips = '0';
        }else {
          this.initialPwdTips = '';
        }
        this.update();
      }
    })
    // fetch(`/prod-api/manage/enterpriseNew/getEntPasswordByEntName?entName=${this.corpName}`, {
    //   method: 'get',
    // })
    // .then(response => response.json())
    // .then(data => {
    //   if (data.code == 200) {
    //     var content = data.data;
    //     this.initialPwdTips1 = content.substring(0,content.indexOf("，"));
    //     this.initialPwdTips2 = content.substring(content.indexOf("，")+1);
    //     // this.initialPwdTips = data.data;
    //     this.update();
    //   } else {
    //     this.initialPwdTips1 = data.msg;
    //     // this.initialPwdTips = data.msg;
    //     this.update();
    //   }
    // })
  }
  //扫码功能 --add by sxm 20230425
  scanQrCode(show){
    this.qrCodeSetting.show = show;
    this.update();
  }
  connentInfo(type,uid){
    let url=''
    let idType=''
    let obj={}
    switch(type){
        case 0 : url='weixinLogin'; obj={weixinId:uid};idType='weixinId';break;
        case 1 : url='qqLogin'; obj={qqId:uid};idType='qqId';break;
        case 2 : url='weiboLogin'; obj={weiboId:uid};idType='weiboId';break;
    }
    fetch('/prod-api/auth/'+url,{
        headers: {
            Authorization: `Bearer ${getToken()}`,
            "Content-type": "application/json",
        },
        method:'post',
        body:JSON.stringify(Object.assign({
          userCatalog:'01',
        },obj))
    }).then(response => response.json())
    .then(res=>{
      console.log(res)
      if (res.code == 200) {
        if (res.data.code == 403||res.data.code == 500) {
          alert(res.data.msg)
        }else {
          // sessionStorage.setItem("af-auth-token", data.data.access_token)
          setToken(res.data.access_token,720);
          location.reload();
        }
      }else{
        alert(res.msg)
      }
    })
  }
  //打开图片滑块验证码弹窗
  openImageVarify(){
    if(this.type == 1) {//账号登录
      if (!this.data.username) {
        alert("请输入用户名");
        return false;
      }
      if (!this.data.password) {
        alert("请输入密码");
        return false;
      }
    }else if(this.type == 2) {//短信登录
      if (!this.phoneReg.test(this.verify.phone)) {
        alert('输入正确手机号')
        return false;
      }
    }
    this.isImageShow = true;
    this.update();
  }
  constructor(props) {
    super(props);
    this.checkImageVarify = this.checkImageVarify.bind(this); // 绑定 this
  }

  //图片滑块验证码校验通过
  checkImageVarify(imgData){
    setTimeout(() => {
      this.isImageShow = false;
      this.update();
    }, 300)
    if(this.type == 1){//账号登录
      this.data.uuid = imgData.imgUuid;
      this.data.code = imgData.imgCode;
      this.data.loginCaptcha = 'imageHd';//标识图片滑动验证码
      this.update();
      this.handleLogin();//登录
    }else if(this.type == 2){//短信登录
      this.data.uuid = imgData.imgUuid;
      this.verify.code = imgData.imgCode;
      this.data.loginCaptcha = 'imageHd';//标识图片滑动验证码
      this.update();
      this.getVerify();//发送验证码
    }

  }
  render() {
    return (
      <div class="bodybg">
        <div class="login-container">

            <oi-dialog-box settings={{show: this.isImageShow, width:310, height: 265}}>
                <image-verify ref={this.imgVerifyRef} mess='hello ' checkImageVarify={this.checkImageVarify} />
            </oi-dialog-box>
          <div>
            <a href="http://120.46.204.66:8090/Home"><span class="login-logo"></span></a>
          </div>
          <div class="login-box">
            <span class="login-left">
            </span>
            <div class="login-right" style="margin-top:5rem">
              <div  className={'login-switch'}>
                <table className={this.userCatalog == '01'?'account-switch':'account-switch account-switch02'} cellSpacing="0" cellPadding="0" align="center">
                  <tr>
                    <td className={this.userCatalog == '01' ? 'active' : ''} onClick={evt => {
                      this.switchUser('01')
                    }}>企业</td>
                    <td className={this.userCatalog == '02' ? 'active' : ''} onClick={evt => {
                      this.switchUser('02')
                    }}>个人</td>
                  </tr>
                </table>
                <table class="account-tabs" cellspacing="0" cellpadding="0" align="center">
                  <tr>
                    <td align="center">
                      <span className={this.type == 1 ? 'on' : ''} onClick={evt => {
                        this.switchTab(1)
                      }}>账号登录</span>
                    </td>
                    <td align="center">
                     <span className={this.type == 2 ? 'on' : ''} onClick={evt => {
                        this.switchTab(2)
                      }}>短信登录</span>
                    </td>
                    <td align="center" className={this.userCatalog == '01'?'disnone':''}>
                      <span className={this.type == 3 ? 'on' : ''} onClick={evt => {
                        this.switchTab(3)
                      }}>扫码登录</span>
                    </td>
                  </tr>
                </table>
                <table className={this.type == 1 ? 'account-tab active' : 'tab-pane account-tab'} cellspacing="0" cellpadding="0" style="margin:0 auto;" align="center">
                  <tr>
                    <td class="input-area">
                      <span class="icon icon1"></span>
                      <input id="username" o-model="data.username" type="text" class="login-input required"
                        auto-complete="off" data-msg="请输入账号" placeholder="账号" />
                    </td>
                  </tr>
                  <tr>
                    <td class="input-area">
                      <span class="icon icon2"></span>
                      <input id="password" o-model="data.password" type="password" class="login-input required"
                        auto-complete="off" data-msg="请输入密码" placeholder="密码" />

                    </td>
                  </tr>
                  {/*<tr>*/}
                  {/*  <td class="input-area" style="position:relative">*/}
                  {/*    <span class="icon icon3"></span>*/}
                  {/*    <input id="code" o-model="data.code" onKeyDown={evt => {*/}
                  {/*      this.enterAction(evt);*/}
                  {/*    }} auto-complete="off"*/}
                  {/*      class="login-input required" placeholder="验证码" data-msg="请输入验证码" />*/}
                  {/*    <img height="36px" ref={this.codeRef} name="codeUrl" src="" onclick={evt => {*/}
                  {/*      this.refreshCode();*/}
                  {/*    }} class="login-code-img codeUrl" />*/}
                  {/*    <input type="hidden" class="uuid" name="uuid" />*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                  <tr>
                    <td align="center" style="padding-top: 4.8rem;">
                      <p className={this.userCatalog == '01' ? 'grey18' : 'disnone'} style="color:red;margin-top:0;">
                        <label>系统升级，原账号密码不变，可直接登录</label></p>
                      <p className={this.userCatalog == '01' ? 'grey18 mb20' : 'disnone'} style="color:red;margin-top: 0.5rem;">
                        <label>如忘记账号密码，可通过联系人手机短信验证登录</label></p>
                      {/*<p className={this.userCatalog == '01' ? 'grey18 mb20' : 'disnone'}>*/}
                      {/*  <label>会员初始密码为联系人手机号/邮箱</label></p>*/}
                      <button onClick={evt => {
                        this.openImageVarify();
                      }} class="login-btn">登 录</button>
                    </td>
                  </tr>

                </table>
                <table className={this.type == 2 ? 'phone-tab active' : 'tab-pane phone-tab'} cellspacing="0" cellpadding="0" style="margin:0 auto" align="center">
                  <tr>
                    <td class="input-area">
                      <span class="icon icon4"></span>
                      <input id="phone" name="phone" o-model="verify.phone" type="text" class="login-input required" auto-complete="off"
                        data-msg="请输入手机号" placeholder="手机号" />
                    </td>
                  </tr>
                  {/*<tr>*/}
                  {/*  <td class="input-area" style="position:relative">*/}
                  {/*    <span class="icon2 icon"></span>*/}
                  {/*    <input id="code" name="code" auto-complete="off" o-model="verify.code"*/}
                  {/*      class="login-input required" placeholder="图片验证码" data-msg="请输入图片验证码" />*/}
                  {/*    <img height="36px" ref={this.phoneRef} name="codeUrl" src="" onClick={evt => {*/}
                  {/*      this.refreshCode();*/}
                  {/*    }} class="login-code-img codeUrl" />*/}
                  {/*    <input type="hidden" class="uuid" name="uuid" />*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                  <tr>
                    <td class="input-area">
                      <span class="icon2 icon"></span>
                      <input id="vericode" o-model="verify.phoneCode" name="vericode" type="text" class="login-input required"
                        auto-complete="off" data-msg="请输入短信验证码" placeholder="短信验证码" />
                      <button disabled={this.disBtn} class="smscode" onclick={evt => {
                        this.openImageVarify()
                      }}  >{this.tip}</button>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" style="padding-top: 4.8rem;">
                      <p className={this.userCatalog == '01' ? 'grey18 mb20' : 'disnone'}>
                        {/*<label>会员初始密码为联系人手机号/邮箱</label>*/}
                        <a onClick={evt => {
                          this.updateInitialPwd(true);
                        }} style="margin-left:1rem;">企业联系人及手机号提示</a></p>
                      <button onclick={evt => {
                        this.codeLogin()
                      }} class="login-btn">登 录</button>
                    </td>
                  </tr>
                </table>
                <table className={this.userCatalog == '02' && this.type == 3 ? 'wx-tab active' : 'tab-pane wx-tab'} cellspacing="0" cellpadding="0" style="margin:0 auto" align="center">
                  <tr>
                    <td align="center">
                      <img class="codeImg" src={this.codeImg} />
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      <a className="blue16" onClick={evt => { this.showCode() }}><i className={'icon-refresh'}></i>刷新</a>
                      <p className="grey18" style="margin-top: 1rem;">微信扫码 关注公众号立即登录</p>
                    </td>
                  </tr>
                </table>
                <div className={this.type == 1 ? 'table-tips':'table-tips center'}>
                  <span>没有账号，<a href="#/Register">立即注册</a></span>
                  <span className={this.type == 1?'':'disnone'}><a href="#/Forget">忘记密码？</a></span>
                </div>
                {/* <div className={'disnone'}> */}
                <div className={this.userCatalog=='02'?'':'disnone'}>
                  <af-other-login></af-other-login>
                </div>
              </div>
              {/*<div className={'footer-tips'}>*/}
              {/*  如有问题可<a onclick={evt => {this.scanQrCode(true);}}>扫码</a>进群咨询*/}
              {/*</div>*/}
              <div className={'footer-tips'}>
                技术支持：010 - 88820951
              </div>
            </div>
          </div>

          <oi-dialog-box settings={this.initialPwdSetting}>
            <div className="oi-row">
              <ul className="initial-pwd-box">
                <li style="text-align:left">
                  <label>企业名称：</label>
                  <input id="corpName" name="corpName" o-model="corpName" type="text" className="required"
                         auto-complete="off"
                         data-msg="请输入企业名称" placeholder="请输入企业全称，查看提示"/>
                </li>
                <li>
                  <button onClick={evt => {
                    this.getInitialPwd()
                  }} className="login-btn">查询
                  </button>
                </li>
                <li>
                  <table class="tips-table">
                      <tr>
                        <th style="width:36rem;">用户名</th>
                        <th style="width:36rem;">用户姓名</th>
                        <th style="width:18rem;">手机号</th>
                      </tr>
                      {
                          this.initialPwdTipsList.map(item => {
                            return (
                              <tr>
                              <td>{item.user_name}</td>
                              <td>{item.nick_name}</td>
                              <td>{item.phonenumber}</td>
                              </tr>
                            )
                          })
                      }
                      <tr>
                        <td colSpan={3} className={this.initialPwdTips=='0'?'':'disnone'}>暂无数据</td>
                      </tr>
                  </table>
                </li>
                {/*<li>{this.initialPwdTips1}<br/>{this.initialPwdTips2}</li>*/}
              </ul>
            </div>
          </oi-dialog-box>
          <oi-dialog-box settings={this.qrCodeSetting}>
            <div className="oi-row" style="text-align: center;">
              <img src="../assets/images/regist/qrcode_group.jpg" style="width: 30rem; height: 30rem;" />
            </div>
          </oi-dialog-box>
        </div>
      </div>
    );
  }
}
