const { h, uniqueTag, Component, define, apis, createRef, render } = omii;
import security from "./security";
import css from "./css/login.scss";
import { codeController, labelsController } from "./apis";

import { setToken, getToken, isTokenExpired } from "./token"


let images = [];
let telValidator = "^\\d{1,4}-\\d{1,4}-\\d{5,9}(-\\d{1,4})?$";
let phoneValidator = "^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\\d{8}$";
let emailValidator = "^([a-zA-Z]|[0-9])(\\w|\\-)+@([a-zA-Z0-9_-])+\\.([a-zA-Z]{2,4})$";
// let emailValidator = "^([a-zA-Z]|[0-9])(\\w|\\-)+@[a-zA-Z0-9]+\\.([a-zA-Z]{2,4})$";
let passValidator = "(?!^[0-9]+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^A-z0-9]+$)^[^\\s\\u4e00-\\u9fa5]{6,}";
define("af-main-account", class extends Component {
        static css = css;
        registObj = {};
        checkObjEnt = {};
        checkObj = {};
        stepin = 1;//当前步骤
        userType = '0';//用户类型 01-企业，02-个人
        tip = "获取验证码";
        disBtn = false;
        loading = false;
        nullTipsList = [];//为空的项
        codeRef = createRef();

        fzrList = [];//负责人列表

        //初始化
        install() {
            this.refreshCode();
        }
        //获取企业负责人列表
        getQyfzrList(v) {
            fetch("/prod-api/manage/entUser/getQyfzr?entName="+this.checkObjEnt.entName, {}).then(response => response.json()).then(r => {
                if (r.code == 200) {
                    this.fzrList = r.data;
                    this.stepin = v;
                    this.update();
                }
            });
        }
        //切换企业负责人
        changeQyfzr(e, id) {
            this.checkObj.additionalId = id;
            this.update();
        }
        //检查企业负责人是否正确
        checkQyfzr(e, v){
            let id = this.checkObj.additionalId;
            if(id==undefined || id==null || id==''){
                alert("请选择信息核验用户！")
                return;
            }
            let contactMobile = this.checkObj['contactMobile'+id];
            if(contactMobile==undefined || contactMobile==null || contactMobile==''){
                alert("请输入完整手机号码！")
                return;
            }
            let item = this.$$("#contactMobile_" + id)[0];
            let reg = item.dataset.reg;
            if (reg) {
                reg = new RegExp(reg);
                if (!reg.test(contactMobile)) {
                    alert(item.dataset.tip)
                    return;
                }else {
                    this.checkObj.contactMobile = contactMobile;
                    fetch("/prod-api/manage/entUser/verificationPhone?additionalId="+id+"&contactMobile="+contactMobile, {}).then(response => response.json()).then(r => {
                        if (r.code == 200) {
                            if(r.data){
                                this.stepin = v;
                                this.update();
                            }else{
                                alert("手机号码验证未通过");
                                return;
                            }
                        }else {
                            alert("手机号码验证未通过");
                            return;
                        }
                    });
                }
            }
        }

        //上一步
        lastStep(e, v) {
            this.stepin = v;
            this.update();
        }
        //下一步
        nextStep(e, v) {
            if (v == 2) {//进入步骤2
                var flag = this.validator("form-step1");
                if (!flag) {
                    return false;
                }else{

                    fetch("/prod-api/manage/entUser/verificationEntName?entName="+this.checkObjEnt.entName, {
                    }).then(response => response.json()).then(r => {
                        if (r.code == 200) {
                            this.getQyfzrList(v);
                            this.update();
                        } else {
                            alert(r.msg);//返回第一个错误提示
                        }
                    }).then(r1 => {

                    });
                }
            }

        }

        //刷新验证码
        refreshCode() {
            codeController.get().then((r) => {
                this.codeRef.current.src = `data:image/jpg;base64,${r.img}`;
                this.registObj.uuid = r.uuid;
            });
        }
        //表单校验 true-校验通过
        validator(formName) {
            let flag = true;
            this.nullTipsList = [];
            let registObjName = "registObj";
            if(formName=='form-step1'){
                registObjName = "checkObjEnt";
            }else if(formName=='form-step2'){
                registObjName = "checkObj";
            }
            for (var i = 0; i < this.$$("." + formName + " .required").length; i++) {
                let item = this.$$("." + formName + " .required")[i];
                let name = item.getAttribute("name");
                let nameArr = name.split(".");
                let currentValue = this[registObjName][nameArr[0]];
                if (nameArr.length > 1) {
                    currentValue = this[registObjName][nameArr[0]][nameArr[1]];
                }

                if (!currentValue) {
                    flag = false;
                    let msgNode = item.parentNode.querySelector("span.errorTip");
                    if (!msgNode) {
                        let span = document.createElement("span");
                        span.className = "errorTip";
                        span.innerText = item.dataset.msg;
                        item.parentNode.appendChild(span);
                    }
                    this.nullTipsList.push(item.dataset.msg);
                } else {
                    let msgNode = item.parentNode.querySelector("span.errorTip");
                    let reg = item.dataset.reg;
                    if (reg) {
                        reg = new RegExp(reg);
                        if (!reg.test(currentValue)) {
                            flag = false;
                            if (msgNode) {
                                msgNode.innerText = item.dataset.tip;
                            } else {
                                let span = document.createElement("span");
                                span.className = "errorTip";
                                span.innerText = item.dataset.tip;
                                item.parentNode.appendChild(span);
                            }
                            this.nullTipsList.push(item.dataset.tip);
                        } else {
                            if (msgNode) {
                                item.parentNode.removeChild(msgNode)
                            }
                        }
                    } else {
                        if (msgNode) {
                            item.parentNode.removeChild(msgNode)
                        }
                    }
                }
            }
            return flag;
        }
        //获取手机验证码
        getVerify() {
            let num = 60
            //设置循环定时器
            var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            if (myreg.test(this.registObj.phonenumber)) {
                if(this.registObj.code==undefined || this.registObj.code==null || this.registObj.code==''){
                    alert('请输入图片验证码')
                    return;
                }
                this.disBtn = true
                let that = this
                fetch(`/prod-api/system/user/checkIsTelReg?phonenumber=${this.registObj.phonenumber}&userType=01`, {
                    method: 'get',
                })
                    .then(response1 => response1.json())
                    .then(data1 => {
                        if (data1.code == 200) {
                            if (data1.data != 0) {
                                alert("手机号码“" + this.registObj.phonenumber + "”已注册企业用户！")
                                that.refreshCode();
                                that.disBtn = false
                                that.update();
                            } else {
                                fetch(`/prod-api/manage/open/smscode/SendSMSIncludeCaptcha?tel=${this.registObj.phonenumber}&code=${this.registObj.code}&uuid=${this.registObj.uuid}`, {
                                    method: 'get',
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        if (data.code == 200) {
                                            let timer = setInterval(function () {
                                                num--
                                                if (num <= 0) {
                                                    //清除循环执行定时器
                                                    clearInterval(timer);
                                                    //还原
                                                    num = 60;
                                                    that.disBtn = false
                                                    that.tip = "获取验证码";
                                                } else {
                                                    that.tip = num + '秒后可重新获取';
                                                }
                                                that.update();
                                            }, 1000)
                                        } else {
                                            that.refreshCode();
                                            that.disBtn = false
                                            that.update();
                                            alert(data.msg)
                                        }
                                    })
                            }
                        } else {
                            that.refreshCode();
                            that.disBtn = false
                            that.update();
                            alert(data1.msg)
                        }
                    })


            } else {
                alert('请输入正确手机号')
            }

        }
        //重置表单
        resetForm(e) {
            if (this.stepin == 1) {//第一步
                this.checkObjEnt.entName = "";
                this.$$("#entName")[0].value="";
            } else if (this.stepin == 2) {//第二步
                this.checkObj.additionalId = "";
                this.checkObj.contactMobile = "";
                for (var i = 0; i < this.$$(".contactMobile-input").length; i++) {
                    this.$$(".contactMobile-input")[i].value="";

                }
                this.$$(".contactMobile-input")[0].value="";
            } else if (this.stepin == 3) {//第三步
                this.$$(".form-czmm input.form-input").map(item => item.value = '')
            }
            this.update();
        }

        changeLoading(flag) {
            this.loading = flag;
            this.update();
        }
        //提交
        submit() {
            var flag = this.validator("form-yhxx");
            if (flag) {
                if (this.registObj.password != this.registObj.confirmPassword) {
                    alert("密码和确认密码不一致");
                    return false;
                }
                this.changeLoading(true);
                var obj = JSON.parse(JSON.stringify(this.registObj));
                let param = {
                    ...obj,
                    entName: this.checkObjEnt.entName,
                    additionalId: this.checkObj.additionalId,
                    contactMobile: this.checkObj.contactMobile
                }
                fetch(`/prod-api/manage/entUser/saveUser`, {
                    method: 'post',
                    body: JSON.stringify(param),
                    headers: {
                        "Content-type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        this.changeLoading(false);
                        if (data.code == 200) {
                            if (data.data>0) {
                                this.stepin = 4;
                                this.update();
                            }else {
                                alert("设置主账号失败！")
                            }
                        } else {
                            alert(data.msg)
                        }
                    })
            }else{
                this.changeLoading(false);
            }
        }

        render() {
            return (
                <div className="mainbox">
                    <div className={this.loading ? 'page-loading' : 'disnone'}><label>{this.loadingText}</label></div>
                    <div className="header">
                        <div className="regist-box regist-head">
                            <div className="regist-top regist-top-left">
                                <a href="http://120.46.204.66:8090/Home"><span className="regist-logo"></span></a>
                            </div>
                            <p className="noaccount">主账号设置
                            </p>
                        </div>
                    </div>
                    <div className="regist-box regist-box-main">
                        <div className="regist-step-box">
                            <div className="bgline"></div>
                            <div className="bgline-active" style={'width:' + (this.stepin * 22.7) + 'rem'}></div>
                            <ul>
                                <li className={this.stepin >= 1 ? 'active-step' : ''}><span>输入企业名称</span></li>
                                <li className={this.stepin >= 2 ? 'active-step' : ''}><span>信息核验</span></li>
                                <li className={this.stepin >= 3 ? 'active-step' : ''}><span>设置主账号</span></li>
                                <li className={this.stepin >= 4 ? 'active-step' : ''}><span>设置成功</span></li>
                            </ul>
                        </div>

                        <div className={this.stepin == 1 ? 'form-step1' : 'disnone'}>
                            <form>
                                <div className="fullRow">
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>企业名称：</span>
                                        <input type="text" className="form-input required" o-model="checkObjEnt.entName" name="entName" id="entName"
                                               data-msg="请输入企业名称"/>
                                    </div>
                                </div>
                            </form>
                            <div className="step-btn" style="margin-bottom:9rem;">
                                <button type="button" className="btn-next" onClick={evt => {
                                    this.nextStep(evt, 2)
                                }}>下一步
                                </button>
                                <button type="button" className="btn-reset" onClick={evt => {
                                    this.resetForm(evt)
                                }}>重置
                                </button>
                            </div>
                        </div>
                        <div className={this.stepin == 2 ? 'form-step2' : 'disnone'}>
                            <form>
                                <div className={this.fzrList!=null&&this.fzrList.length>0?"fullRow":"disnone"}>
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>信息核验：以下为贵司联系人及电话提示，请选择其一将对应的手机号补充完整进行验证</span>
                                    </div>
                                    <div className="col24">
                                        <ul className="account-fzr">
                                            {
                                                this.fzrList.map(item => {
                                                    return (
                                                        <li>
                                                            <a className={this.checkObj.additionalId==item.additionalId? 'a-checkbox-in' : 'a-checkbox'}
                                                               onClick={evt => {
                                                                   this.changeQyfzr(evt, item.additionalId)
                                                               }}>{item.contact} {item.contactMobile}</a>
                                                            <input type="text" id={"contactMobile_"+item.additionalId} className="form-input contactMobile-input" o-model={"checkObj.contactMobile"+item.additionalId}
                                                                   data-msg="请输入完整手机号码" data-reg={phoneValidator} data-tip="手机号格式不正确" />
                                                        </li>
                                                    )
                                            })}
                                        </ul>

                                    </div>
                                </div>
                                <div className={this.fzrList!=null&&this.fzrList.length>0?"disnone":"regist-success img-fail"}>
                                    <p>该企业暂无信息核验数据</p>
                                </div>
                            </form>
                            <div className={"step-btn"} style="margin-bottom:9rem;">
                                <button type="button" className="btn-last mr30" onClick={evt => {
                                    this.lastStep(evt, 1)
                                }}>上一步
                                </button>
                                <button type="button" className={this.fzrList!=null&&this.fzrList.length>0?"btn-next":"disnone"} onClick={evt => {
                                    this.checkQyfzr(evt, 3)
                                }}>下一步
                                </button>
                                <button type="button" className={this.fzrList!=null&&this.fzrList.length>0?"btn-reset":"disnone"} onClick={evt => {
                                    this.resetForm(evt)
                                }}>重置
                                </button>
                            </div>
                        </div>

                        <div className={this.stepin == 3 ? 'form-yhxx' : 'disnone'}>
                            <form>
                                <div className="fullRow">
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>负责人姓名：</span>
                                        <input type="text" className="form-input required" o-model="registObj.nickName" name="nickName" id="nickName"
                                               data-msg="请输入负责人姓名" />
                                    </div>
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>负责人职务：</span>
                                        <input type="text" className="form-input required" o-model="registObj.postName" name="postName" id="postName"
                                               data-msg="请输入负责人职务" />
                                    </div>
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>登录账号：</span>
                                        <input type="text" className="form-input required" o-model="registObj.userName" name="userName" id="userName"
                                               data-msg="请输入登录账号" />
                                    </div>
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>登录密码：</span>
                                        <input type="password" className="form-input required" o-model="registObj.password"
                                               name="password"
                                               data-msg="请输入密码" data-reg={passValidator}
                                               data-tip="密码至少包含数字、大小写字母、特殊字符 两种以上（不包含中文及空格），长度不小于8位"/>
                                    </div>
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>确认密码：</span>
                                        <input type="password" className="form-input required" o-model="registObj.confirmPassword"
                                               name="confirmPassword" data-msg="请输入确认密码" data-reg={passValidator}
                                               data-tip="密码至少包含数字、大小写字母、特殊字符 两种以上（不包含中文及空格），长度不小于8位"/>
                                    </div>
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>负责人手机号：</span>
                                        <input type="text" className="form-input required" o-model="registObj.phonenumber"
                                               name="phonenumber" id="phonenumber"
                                               data-msg="请输入负责人手机号" data-reg={phoneValidator} data-tip="手机号格式不正确"/>
                                    </div>
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>图片验证码：</span>
                                        <div style="position:relative">
                                            <input type="text" className="form-input required" o-model="registObj.code" name="code"
                                                   data-msg="请输入图片验证码"/>
                                            <img height="36" ref={this.codeRef} name="codeUrl" src="" onClick={evt => {
                                                this.refreshCode();
                                            }} className="login-code-img codeUrl"/>
                                        </div>
                                    </div>
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>短信验证码：</span>
                                        <div style="position:relative">
                                            <input type="text" className="form-input required" o-model="registObj.checkCode"
                                                   name="checkCode"
                                                   data-msg="请输入短信验证码"/>
                                            <button type="button" disabled={this.disBtn} onClick={evt => {
                                                this.getVerify()
                                            }} className='pbr'>{this.tip}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="step-btn">
                                    <button type="button" className="btn-last mr30" onClick={evt => {
                                        this.lastStep(evt, 2)
                                    }}>上一步
                                    </button>
                                    <button type="button" className="btn-next" onClick={evt => {
                                        this.submit(evt, 4)
                                    }}>下一步
                                    </button>
                                    <button type="reset" className="btn-reset" onClick={evt => {
                                        this.resetForm(evt)
                                    }}>重置
                                    </button>

                                </div>
                            </form>
                        </div>
                        <div className={this.stepin == 4 ? '' : 'disnone'}>
                            <div className="regist-success img-success">
                                <p>恭喜您，主账号设置成功！</p>
                            </div>
                            <div className="step-btn">
                                <a href="/" className="btn-next">登录</a>
                            </div>
                        </div>
                    </div>
                    <div className={this.stepin == 1 ? 'regist-footer positionFixed' : 'regist-footer'}>
                        <div className="regist-box" style="text-align: center;height:auto;min-height:8rem;">
                            版权所有：中国安全防范产品行业协会 京ICP备07503664号-4 京公网安备 11010802034822号
                        </div>
                    </div>
                </div>
            );
        }
    }
)
