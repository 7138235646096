const { h, uniqueTag, Component, define, apis, createRef } = omii;
import css from "../css/side.scss";
import { codeController } from "../apis";
export default class extends Component {
  static css = css;
  render() {
    return (
    <ul className="side-menu">
        <li><a>我的信息</a></li>
        <li><a>我的收藏</a></li>
        <li><a>我的关注</a></li>
        <li><a>我的订阅</a></li>
        <li><a>消息中心</a></li>
    </ul>
    );
  }
}
