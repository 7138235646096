const { h, define, Component, createRef } = omii;
import "../../es-lib/omii/latest/ui/omii-ui.js"
import { getToken } from "../token";
import css from "../css/home.scss";
// import "jquery"

export default class extends Component {
    static css = css;
    dataList = [];
    tabName = "";
    startTime = "";
    endTime = "";
    data = {
        total: 0,
        pageSize: 10,
        pageNum: 1
    }
    pageRef = createRef();
    startRef = createRef();
    myCss = `
    .pager .page  {
        font-size:1.4rem!important;
        padding:6px 9px;
        border:solid 1px #f5f5f9;
        margin:0 5px
    }
    .pager .page:hover{
        background:#002040 !important;
    }
    .pager .active{
        background:#002040 !important;
    }
    .pager .jumper,.pager .info{
        font-size:1.4rem!important;
    }
    .pager .jumper .jump-to{
        margin:0 1rem;
    }
    `
    getPagedList(e) {
        let pageNum = e.detail;
        this.data.pageNum = pageNum;
        this.getList(pageNum);
    }
    getList(num) {
        let url = "/prod-api/manage/userPointsDetail/userPointsUse/list?pageNum=" + num + "&pageSize="+this.data.pageSize;
        if(this.startTime) {
            url += "&startTime="+this.startTime;
        }
        if(this.endTime) {
            url += "&endTime="+this.endTime;
        }
        if(this.tabName) {
            url += "&tabName="+this.tabName;
        }
        fetch(url, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                if (res.code) {
                    this.dataList =res.rows;
                    this.data.total = res.total;
                    this.data.pageNum = num;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
        // this.data.pageNum = pageNum;
    }

    formatUsetype(useType,def) {
        if(def==undefined || def==null) {
            def = "";
        }
        if(useType=='1') {
            return '浏览';
        }else if(useType=='2') {
            return '下载';
        }else {
            return def;
        }
    }

    parseTime(time, pattern) {
        if (arguments.length === 0 || !time) {
            return null
        }
        const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
        let date
        if (typeof time === 'object') {
            date = time
        } else {
            if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
                time = parseInt(time)
            } else if (typeof time === 'string') {
                time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
            }
            if ((typeof time === 'number') && (time.toString().length === 10)) {
                time = time * 1000
            }
            date = new Date(time)
        }
        const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
        }
        const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
            let value = formatObj[key]
            // Note: getDay() returns 0 on Sunday
            if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
            if (result.length > 0 && value < 10) {
                value = '0' + value
            }
            return value || 0
        })
        return time_str
    }


    install() {
        this.getList(1);
    }
    query(e) {
        this.getList(1)
    }

    installed() {

    }
    render() {
        return (
            <>
                <div className="oi-row-full font26">我的点数</div>
                <div className="list-area">
                    <div className="row search-area">
                        <div className="search-col">
                            开始时间: <input type="date" datetimeformat="yyyy-MM-dd" o-model="startTime" className="startDate normalInput" placeholder="开始时间" />
                        </div>
                        <div className="search-col">
                            结束时间： <input type="date" o-model="endTime" className="endDate normalInput" placeholder="结束时间" />
                        </div>
                        <div className="search-col">
                            栏目名称: <input o-model="tabName" className="normalInput" placeholder="栏目名称" />
                        </div><button className="search-btn" onClick={e => {
                        this.query()
                    }}>搜索</button>
                    </div>
                    <table class="table-list" cellPadding="0" border="0" cellspacing="0">
                        <thead>
                        <tr>
                            <th class="index-col">序号</th>
                            <th style="width:10rem;">栏目名称</th>
                            <th>文章标题</th>
                            <th style="width:10rem;">使用类型</th>
                            <th style="width:10rem;">使用点数</th>
                            <th class="time-col">创建时间</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.dataList.length > 0 ? this.dataList.map((item, i) => (
                            <tr>
                                <td class="type-col">{i + 1}</td>
                                <td> {item.tabName}</td>
                                <td> {item.articleTitle}</td>
                                <td style="text-align:center;"> {this.formatUsetype(item.useType)}</td>
                                <td style="text-align:center;"> {item.pointsValue}</td>
                                <td class="time-col">
                                    {this.parseTime(item.createTime)}
                                </td>

                            </tr>
                        )) : (
                            <tr>
                                <td colSpan="6" className="text-c">
                                    暂无数据
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    {
                        this.data.total > 0 ? <div className="pager-area">
                            <oi-pager css={this.myCss} total={this.data.total} page-size={this.data.pageSize} page-num={this.data.pageNum} onPaged={evt => this.getPagedList(evt)} />
                        </div> : <div />
                    }

                </div>
            </>
        )
    }
}
