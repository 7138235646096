const { h, define, Component, classNames, uniqueTag, createRef } = omii;
import tinymce from 'tinymce/tinymce';
export default class extends Component {
    installed() {
        tinymce.init({
            selector: this.base,
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code',
            setup: (editor) => {
                editor.on('change', () => {
                    this.handleEditorChange(editor.getContent());
                });
            }
        });
    }

    handleEditorChange(content) {
        console.log('Content:', content);
    }

    render() {
        return (
            <div>
                <textarea ref={e => this.base = e} id="my-editor" style="resize:none"></textarea>
            </div>
        );
    }
}