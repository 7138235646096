import { getToken } from "../token";
const { apis } = omii;
const baseUrl =baseApi;
export const codeController = apis.createController(
    "code",
    baseUrl
);
export const authController = apis.createController(
    "auth",
    baseUrl
)
export const systemController = apis.createController('system', baseUrl, () => {
    return {
        Authorization: `Bearer ${getToken()}`
    }
})
export const systemUpoadController = apis.createController('system', baseUrl, () => {
    return {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type":"multipart/form-data"
    }
})
export const uploadController = apis.createController('file', baseUrl, () => {
    return {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type":"multipart/form-data"
    }
})
export const entUserController = apis.createController('manage', baseUrl, () => {
    return {
        Authorization: `Bearer ${getToken()}`
    }
})

export const labelsController = apis.createController('manage-nx', baseUrl, () => {
    return {
        Authorization: `Bearer ${getToken()}`
    }
})