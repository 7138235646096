const { h, uniqueTag, Component, define, apis, createRef, render } = omii;
import css from "./css/bindPhone.scss";
import { codeController, labelsController } from "./apis";

let images = [];
let phoneValidator = "^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\\d{8}$";
let passValidator = "(?!^[0-9]+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^A-z0-9]+$)^[^\\s\\u4e00-\\u9fa5]{6,}";
define("af-bind-phone", class extends Component {
  static css = css;
  registObj = {};
  stepin = 1;//当前步骤
  tip = "获取验证码";
  disBtn = false;
  loading = false;
  nullTipsList = [];//为空的项
  codeRef = createRef();
  wxid = '';

  //初始化
  install() {
    this.refreshCode();
    //获取参数
    if(location.href.indexOf("?")>-1){
      var that = this;
      var params = location.href.split("?")[1].split("&");
      params.forEach((item,index)=>{
        if(item.indexOf("wxid")>-1){
          that.wxid = item.replace("wxid=","");
        }
      })
      console.info("that.wxid:",that.wxid)
    }
    if(this.wxid==''){
      this.stepin = 3;
    }
  }

  //刷新验证码
  refreshCode() {
    codeController.get().then((r) => {
      this.codeRef.current.src = `data:image/jpg;base64,${r.img}`;
      this.registObj.uuid = r.uuid;
    });
  }
  //表单校验 true-校验通过
  validator(formName) {
    let flag = true;
    this.nullTipsList = [];
    let registObjName = "registObj";

    for (var i = 0; i < this.$$("." + formName + " .required").length; i++) {
      let item = this.$$("." + formName + " .required")[i];
      let name = item.getAttribute("name");
      let nameArr = name.split(".");
      let currentValue = this[registObjName][nameArr[0]];
      if (nameArr.length > 1) {
        currentValue = this[registObjName][nameArr[0]][nameArr[1]];
      }

      if (!currentValue) {
        flag = false;
        let msgNode = item.parentNode.querySelector("span.errorTip");
        if (!msgNode) {
          let span = document.createElement("span");
          span.className = "errorTip";
          span.innerText = item.dataset.msg;
          item.parentNode.appendChild(span);
        }
        this.nullTipsList.push(item.dataset.msg);
      } else {
        let msgNode = item.parentNode.querySelector("span.errorTip");
        let reg = item.dataset.reg;
        if (reg) {
          reg = new RegExp(reg);
          if (!reg.test(currentValue)) {
            flag = false;
            if (msgNode) {
              msgNode.innerText = item.dataset.tip;
            } else {
              let span = document.createElement("span");
              span.className = "errorTip";
              span.innerText = item.dataset.tip;
              item.parentNode.appendChild(span);
            }
            this.nullTipsList.push(item.dataset.tip);
          } else {
            if (msgNode) {
              item.parentNode.removeChild(msgNode)
            }
          }
        } else {
          if (msgNode) {
            item.parentNode.removeChild(msgNode)
          }
        }
      }
    }
    // console.info("验证结果：",flag)
    return flag;
  }

  //获取手机验证码
  getVerify() {
    let num = 60
    var myreg = /^[1][3,5,4,7,8][0-9]{9}$/;
    if (myreg.test(this.registObj.phonenumber)) {
      if(this.registObj.code==undefined || this.registObj.code==''){
        alert('请输入图片验证码')
        return;
      }
      this.disBtn = true
      let that = this
      fetch(`/prod-api/manage/open/smscode/SendSMSBindPhone?tel=${this.registObj.phonenumber}&code=${this.registObj.code}&uuid=${this.registObj.uuid}&wxgzhOpenid=${this.wxid}`, {
        method: 'get',
      })
          .then(response => response.json())
          .then(data => {
            if (data.code == 200) {
              //设置循环定时器
              let timer = setInterval(function () {
                num--
                if (num <= 0) {
                  //清除循环执行定时器
                  clearInterval(timer);
                  //还原
                  num = 60;
                  that.disBtn = false
                  that.tip = "获取验证码";
                } else {
                  that.tip = num + '秒后可重新获取';
                }
                that.update();
              }, 1000)
            } else {
              that.refreshCode();
              that.disBtn = false
              that.update();
              alert(data.msg)
            }
      })
    } else {
      alert('请输入正确手机号')
      return false;
    }

  }
  //绑定
  submitBind(e, v){
    var flag = this.validator("form-yhxx");
    if (!flag) {
      if (this.nullTipsList != null && this.nullTipsList.length > 0) {
        alert(this.nullTipsList[0]);//返回第一个错误提示
      }
      return false;
    }else{
      this.changeLoading(true);
      //校验短信验证码
      let param = {
        phone: this.registObj.phonenumber,
        phoneCode: this.registObj.phoneCode
      }
      fetch(`/prod-api/system/user/checkPhoneCodeBindPhone?phone=${this.registObj.phonenumber}&phoneCode=${this.registObj.phoneCode}&wxgzhOpenid=${this.wxid}`, {
        method: 'post'
      })
          .then(response => response.json())
          .then(data => {
            this.changeLoading(false);
            if (data.code == 200) {
              this.stepin = v;
              this.update();
            } else {
              alert(data.msg)
            }
          })
    }
  }
  //loading遮罩
  changeLoading(flag) {
    this.loading = flag;
    this.update();
  }

  render() {
    return (
        <div className="mainbox">
          <div className={this.loading ? 'page-loading' : 'disnone'}><label>{this.loadingText}</label></div>
          <div className="regist-box regist-box-main">
            <div className={this.stepin == 1 ? 'form-yhxx' : 'disnone'}>
              <form>
                <div className="fullRow">
                  <div className="col24">
                    <span className="form-label"><i className="red">*</i>手机号：</span>
                    <input type="text" className="form-input required" o-model="registObj.phonenumber"
                           name="phonenumber" id="phonenumber"
                           data-msg="请输入手机号" data-reg={phoneValidator} data-tip="手机号格式不正确"/>
                  </div>
                  <div className="col24">
                    <span className="form-label"><i className="red">*</i>图片验证码：</span>
                    <div style="position:relative">
                      <input type="text" className="form-input required" o-model="registObj.code" name="code"
                             data-msg="请输入图片验证码"/>
                      <img height="36" ref={this.codeRef} name="codeUrl" src="" onClick={evt => {
                        this.refreshCode();
                      }} className="login-code-img codeUrl"/>
                    </div>
                  </div>
                  <div className="col24">
                    <span className="form-label"><i className="red">*</i>短信验证码：</span>
                    <div style="position:relative">
                      <input type="text" className="form-input required" o-model="registObj.phoneCode"
                             name="phoneCode"
                             data-msg="请输入短信验证码"/>
                      <button type="button" disabled={this.disBtn} onClick={evt => {
                        this.getVerify()
                      }} className='pbr'>{this.tip}</button>
                    </div>
                  </div>
                </div>
                <div className="step-btn">
                  <button type="button" className="btn-next" onClick={evt => {
                    this.submitBind(evt, 2)
                  }}>绑定
                  </button>

                </div>
              </form>
            </div>

            <div className={this.stepin == 2 ? '' : 'disnone'}>
              <div className="img-success"></div>
              <p className="regist-tips">恭喜您，绑定成功！<br/>请刷新网页二维码，重新进行扫码登录</p>
            </div>
            <div className={this.stepin == 3 ? '' : 'disnone'}>
              <div className="img-fail"></div>
              <p className="regist-tips">参数错误！请重新扫描二维码</p>
            </div>
          </div>
        </div>
    );
  }
}
)
