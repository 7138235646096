const { h, define, Component, classNames, uniqueTag, createRef } = omii;
import "../../../es-lib/omii/latest/ui/omii-ui.js"
import css from "./form.scss";
import tinymce from "../tinymce/tinymce.jsx";
import { setToken, getToken, isTokenExpired } from "../../token"

define("oi-tinymce1", tinymce)

let images = [];
export default class extends Component {
    static css = css;
    formData = {
        imgage: {}
    };
    educations = ["博士及以上", "硕士", "研究生", "本科", "专科", "其他"];
    productTypes = [];//应用领域
    productJson = [];
    researchProjects = {};
    advantages = [];
    jobResumeRef = createRef();
    imgage = {}
    licensePic = ""
    proofPhoto = ""
    eduPhoto = ""
    rankPhoto = ""
    goodPhoto = ""
    joinYear = ""
    photo = ""
    nations = [
        "汉族",
        "蒙古族",
        "回族",
        "藏族",
        "维吾尔族",
        "苗族",
        "彝族",
        "壮族",
        "布依族",
        "朝鲜族",
        "满族",
        "侗族",
        "瑶族",
        "白族",
        "土家族",
        "哈尼族",
        "哈萨克族",
        "傣族",
        "黎族",
        "僳僳族",
        "佤族",
        "畲族",
        "高山族",
        "拉祜族",
        "水族",
        "东乡族",
        "纳西族",
        "景颇族",
        "柯尔克孜族",
        "土族",
        "斡尔族",
        "仫佬族",
        "羌族",
        "布朗族",
        "撒拉族",
        "毛难族",
        "仡佬族",
        "锡伯族",
        "阿昌族",
        "普米族",
        "塔吉克族",
        "怒族",
        "乌孜别克族",
        "俄罗斯族",
        "鄂温克族",
        "崩龙族",
        "保安族",
        "裕固族",
        "京族",
        "塔塔尔族",
        "独龙族",
        "鄂伦春族",
        "赫哲族",
        "门巴族",
        "珞巴族",
        "基诺族",
    ];
    style() {
        return `
       h1 {
            color: red;
        }
    `;
    }
    getExpertArea() {
        fetch("/prod-api/manage/label/listByKey/expertArea").then(res => {
            res.json().then((response) => {
                for (var i = 0; i < response.data.length; i++) {
                    const productJson = {};
                    productJson.labelId = response.data[i].labelId;
                    productJson.labelName = response.data[i].labelName;
                    productJson.editFlag = response.data[i].editFlag;
                    this.productTypes.push(productJson);
                }
                this.update();
            })
        });
    }
    initEditor() {
        tinymce.init({
            selector: '#my-editor',
            plugins: 'link image code',
            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
        });
    }
    componentDidMount() {
        alert(1)
        this.initEditor();
    }
    install() {
        // console.log(this.settings)
        this.getExpertArea();
    }
    installed() {


    }
    removeAdvantage(i) {
        if (confirm("确定要移除此项吗")) {
            this.advantages.splice(i, 1);
            this.update();
        }
    }
    addLevel(item) {
        item.books.push({
            familiarLevel: "",
            infos: [{}],
        });
        this.update();
    }
    addAdvantage() {
        let obj = {
            speciality: "",
            books: [
                {
                    familiarLevel: "",
                    infos: [{ achievement: "", periodicalsName: "", text: "" }],
                },
            ],
        }
        this.advantages.push(obj);
        this.updateSelf();
    }
    // submitForm() {
    //     console.log(this.formData)
    //     console.log(this.advantages)
    // }
    removeBook(list, index) {
        list.splice(index, 1);
        this.update();
    }
    addBook(item) {
        item.push({
            achievement: "",
            periodicalsName: "",
        });
        this.update();
    }
    addProject() {
        let obj = JSON.parse(JSON.stringify(this.researchProjects));
        this.productJson.push(obj);
        this.update();
    }
    handleDeleteProject(i) {
        if (confirm("确认要删除这条记录吗")) {
            this.productJson.splice(i, 1);
            this.update();
        }
    }
    fileRef = createRef();
    uploadPhoto() {
        this.fileRef.current.click();
        let that = this;
        var fileInput = this.fileRef.current;
        fileInput.addEventListener("change", function () {
            let fileObj = new FormData();
            fileObj.append("file", fileInput.files[0]);
            fetch('/prod-api/file/upload', {
                method: 'POST',
                body: fileObj,
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.code == 200) {
                        alert("上传成功", function () {
                            that.formData.photo = data.data.url
                        })
                    } else {
                        alert(data.msg)
                    }
                })
                .catch(error => {
                    console.error(error)
                })
        })

    }
    fileRefYy = createRef();
    uploadPhotoYy() {
        this.fileRefYy.current.click();
        var fileInput = this.fileRefYy.current;
        let that = this;
        fileInput.addEventListener("change", function () {
            let fileObj = new FormData();
            fileObj.append("file", fileInput.files[0]);
            fetch('/prod-api/file/upload', {
                method: 'POST',
                body: fileObj,
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.code == 200) {
                        alert("上传成功", function () {
                            that.formData.imgage.licensePic = data.data.url
                        })

                    } else {
                        alert(data.msg)
                    }
                })
                .catch(error => {
                    console.error(error)
                })
        })

    }
    fileRefDw = createRef();
    uploadPhotoDw() {
        this.fileRefDw.current.click();
        let that = this
        var fileInput = this.fileRefDw.current;
        fileInput.addEventListener("change", function () {
            let fileObj = new FormData();
            fileObj.append("file", fileInput.files[0]);
            fetch('/prod-api/file/upload', {
                method: 'POST',
                body: fileObj,
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.code == 200) {
                        alert("上传成功", function () {
                            that.formData.imgage.proofPhoto = data.data.url
                        })
                    } else {
                        alert(data.msg)
                    }
                })
                .catch(error => {
                    console.error(error)
                })
        })

    }
    fileRefXl = createRef();
    uploadPhotoXl() {
        this.fileRefXl.current.click();
        let that = this
        var fileInput = this.fileRefXl.current;
        fileInput.addEventListener("change", function () {
            let fileObj = new FormData();
            fileObj.append("file", fileInput.files[0]);
            fetch('/prod-api/file/upload', {
                method: 'POST',
                body: fileObj,
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.code == 200) {
                        alert("上传成功", function () {
                            that.formData.imgage.eduPhoto = data.data.url
                        })
                    } else {
                        alert(data.msg)
                    }
                })
                .catch(error => {
                    console.error(error)
                })
        })

    }
    fileRefZc = createRef();
    uploadPhotoZc() {
        this.fileRefZc.current.click();
        let that = this
        var fileInput = this.fileRefZc.current;
        fileInput.addEventListener("change", function () {
            let fileObj = new FormData();
            fileObj.append("file", fileInput.files[0]);
            fetch('/prod-api/file/upload', {
                method: 'POST',
                body: fileObj,
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.code == 200) {
                        alert("上传成功", function () {
                            that.formData.imgage.rankPhoto = data.data.url
                        })
                    } else {
                        alert(data.msg)
                    }
                })
                .catch(error => {
                    console.error(error)
                })
        })

    }
    fileRefFz = createRef();
    uploadPhotoFz() {
        this.fileRefFz.current.click();
        let that = this
        var fileInput = this.fileRefFz.current;
        fileInput.addEventListener("change", function () {
            let fileObj = new FormData();
            fileObj.append("file", fileInput.files[0]);
            fetch('/prod-api/file/upload', {
                method: 'POST',
                body: fileObj,
                headers: {
                    Authorization: `Bearer ${getToken()}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.code == 200) {
                        alert("上传成功", function () {
                            that.formData.imgage.goodPhoto = data.data.url

                        })
                    } else {
                        alert(data.msg)
                    }
                })
                .catch(error => {
                    console.error(error)
                })
        })

    }
    //复选框选择事件
    checkboxClick(e, obj, name, item, business) {
        if (e.target.checked) {//选中
            if (this[obj][name] == undefined) {
                this[obj][name] = [];
            }
            var newItem = {
                labelId: item.labelId,
                labelName: item.labelName,
                editFlag: item.editFlag,
                subBusiness: business
            }
            this[obj][name].push(newItem);
        } else {//取消选中，移除
            for (var i = 0; i < this[obj][name].length; i++) {
                if (item.labelId == this[obj][name][i].labelId) {
                    this[obj][name].splice(i, 1);
                }
            }
            if (this[obj][name].length == 0) {//为空的时候，把对象删掉
                delete this[obj][name]
            }
        }
        this.update();
    }
    submitForm() {
        // console.log(this.productJson, 'this.productJson');
        var flag = this.validator('zjForm')
        let that = this
        // var flag = true
        if (flag) {
            if (that.productJson.length == 0) {
                alert('最少添加一个研究项目')
            } else if (that.advantages.length == 0) {
                alert('最少添加一个熟悉专业及其代表作')
            } else {
                // 熟悉专业及其代表作
                // that.formData.labelListAttr = JSON.stringify(that.productTypes);
                that.formData.worksJson = JSON.stringify(that.advantages);
                // 研究项目
                that.formData.projectJson = JSON.stringify(that.productJson);
                // 判断添加或修改
                // let images = {
                //     licensePic: that.licensePic,
                //     proofPhoto: that.proofPhoto,
                //     eduPhoto: that.eduPhoto,
                //     rankPhoto: that.rankPhoto,
                //     goodPhoto: that.goodPhoto,
                // };
                let obj = JSON.parse(JSON.stringify(that.formData));
                obj.worksJson = JSON.stringify(that.advantages);
                // obj.images = JSON.stringify(images);
                console.log(obj, 1111);
                fetch("/prod-api/manage/specialist", {
                    method: "post",
                    body: JSON.stringify(obj),
                    headers: {
                        "Content-type": "application/json"
                    }
                }).then(res => res.json()).then(r => {
                    if (r.code == 200) {
                        alert("申请成功");
                    } else {
                        alert(r.msg);
                    }
                })

            }
        }

    }
    //表单校验 true-校验通过
    validator(formName) {
        console.log(this.formData.photo, 'sss');
        let flag = true;
        this.nullTipsList = [];
        let registObjName = "formData";
        console.log(this.$$("." + formName + " .required").length, '123');
        for (var i = 0; i < this.$$("." + formName + " .required").length; i++) {
            let item = this.$$("." + formName + " .required")[i];
            let name = item.getAttribute("name");
            let nameArr = name.split(".");
            let currentValue = this[registObjName][nameArr[0]];
            if (nameArr.length > 1) {
                currentValue = this[registObjName][nameArr[0]][nameArr[1]];
            }

            if (!currentValue) {
                flag = false;
                let msgNode = item.parentNode.querySelector("span.errorTip");
                if (!msgNode) {
                    let span = document.createElement("span");
                    span.className = "errorTip";
                    span.innerText = item.dataset.msg;
                    item.parentNode.appendChild(span);
                }
                this.nullTipsList.push(item.dataset.msg);
            } else {
                let msgNode = item.parentNode.querySelector("span.errorTip");
                let reg = item.dataset.reg;
                if (reg) {
                    reg = new RegExp(reg);
                    if (!reg.test(currentValue)) {
                        flag = false;
                        if (msgNode) {
                            msgNode.innerText = item.dataset.tip;
                        } else {
                            let span = document.createElement("span");
                            span.className = "errorTip";
                            span.innerText = item.dataset.tip;
                            item.parentNode.appendChild(span);
                        }
                        this.nullTipsList.push(item.dataset.tip);
                    } else {
                        if (msgNode) {
                            item.parentNode.removeChild(msgNode)
                        }
                    }
                } else {
                    if (msgNode) {
                        item.parentNode.removeChild(msgNode)
                    }
                }
            }
        }
        // console.info("验证结果：",flag)
        return flag;
    }

    render() {
        return (
            <>
                <div className="zjForm">
                    <fieldset>
                        <legend>基本信息</legend>
                        <div className="oi-row oi-row-full" style="border-top:solid 1px #ddd">
                            <div className="oi-col-12">
                                <label className="label-tit">姓名：</label>
                                <input
                                    className="form-input  required"
                                    o-model="formData.userName"
                                    placeholder="请输入姓名"
                                    data-msg="请输入姓名"
                                    clearable
                                    data-tip='1'
                                    name="userName"
                                ></input>
                            </div>
                            <div className="oi-col-12 flex-col">
                                <label className="label-tit ">性别：</label>
                                <div data-msg="请选择性别" className="required" name="gender">
                                    <label className=" horizontal">
                                        <input type="radio" value="1" name="sex" o-model="formData.gender"></input>男
                                    </label>
                                    <label className="horizontal">
                                        <input type="radio" value="0" name="sex" o-model="formData.gender"></input>女
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-12">
                                <label className="label-tit">出生日期：</label>
                                <input type="date" name="birthDate" data-msg="请选择出生日期" o-model="formData.birthDate" className="form-input required" />
                            </div>
                            <div className="oi-col-12">
                                <label className="label-tit">籍贯：</label>
                                <input
                                    name="nativePlace"
                                    className="form-input required"
                                    o-model="formData.nativePlace"
                                    placeholder="请输入籍贯"
                                    data-msg="请输入籍贯"
                                    clearable
                                ></input>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-12">
                                <label className="label-tit">民族：</label>
                                <select o-model="formData.nation">
                                    {
                                        this.nations.map(item => <option>{item}</option>)
                                    }
                                </select>
                            </div>
                            <div className="oi-col-12">
                                <label className="label-tit">职务：</label>
                                <input
                                    name="post"
                                    className="form-input required"
                                    o-model="formData.post"
                                    placeholder="请输入职务"
                                    data-msg="请输入职务"
                                    clearablew
                                ></input>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-12 flex-col">
                                <label className="label-tit height100">照片：</label>
                                <div className="required" name='photo' data-msg='请上传照片'>
                                    <input ref={this.fileRef} type="file" accept="image/*" style="display:none" />
                                </div>
                                <button class="btn-upload btn btn-primary" onClick={evt => {
                                    this.uploadPhoto()
                                }}>上传照片</button>
                            </div>
                            <div className="oi-col-12 flex-col">
                                <label className="label-tit height100">手机号：</label>
                                <input
                                    className="form-input required"
                                    o-model="formData.phonenumber"
                                    placeholder="请输入手机号"
                                    data-msg="请输入手机号"
                                    clearable
                                    name="phonenumber"

                                ></input>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-12">
                                <label className="label-tit">邮箱：</label>
                                <input
                                    className="form-input required"
                                    o-model="formData.email"
                                    placeholder="请输入邮箱"
                                    data-msg="请输入邮箱"
                                    clearable
                                    name="email"
                                ></input>
                            </div>
                            <div className="oi-col-12">
                                <label className="label-tit">学历：</label>
                                <select o-model="formData.education" >
                                    {
                                        this.educations.map(item => <option>{item}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-12">
                                <label className="label-tit">学位：</label>
                                <input
                                    className="form-input required"
                                    o-model="formData.degree"
                                    placeholder="请输入学位"
                                    data-msg="请输入学位"
                                    clearable
                                    name="degree"

                                ></input>
                            </div>
                            <div className="oi-col-12">
                                <label className="label-tit">职称：</label>
                                <input
                                    className="form-input required"
                                    o-model="formData.jobTitle"
                                    placeholder="请输入职称"
                                    data-msg="请输入职称"
                                    clearable
                                    name="jobTitle"
                                ></input>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-12">
                                <label className="label-tit">工作单位：</label>
                                <input
                                    className="form-input required"
                                    o-model="formData.orgName"
                                    placeholder="请输入工作单位"
                                    data-msg="请输入工作单位"
                                    clearable
                                    name="orgName"
                                ></input>
                            </div>
                            <div className="oi-col-12">
                                <label className="label-tit">政治面貌：</label>
                                <input
                                    className="form-input required"
                                    o-model="formData.politicalLandscape"
                                    placeholder="请输入政治面貌"
                                    data-msg="请输入政治面貌"
                                    clearable
                                    name="politicalLandscape"
                                ></input>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-12">
                                <label className="label-tit">单位地址：</label>
                                <input
                                    className="form-input required"
                                    o-model="formData.orgAddress"
                                    placeholder="请输入单位地址"
                                    data-msg="请输入单位地址"
                                    clearable
                                    name="orgAddress"
                                ></input>
                            </div>
                            <div className="oi-col-12">
                                <label className="label-tit">办公电话：</label>
                                <input
                                    className="form-input required"
                                    o-model="formData.officePhone"
                                    placeholder="请输入办公电话"
                                    data-msg="请输入办公电话"
                                    clearable
                                    name="officePhone"
                                ></input>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-12">
                                <label className="label-tit">邮编：</label>
                                <input
                                    className="form-input required"
                                    o-model="formData.postcode"
                                    placeholder="请输入邮编"
                                    data-msg="请输入邮编"
                                    clearable
                                    name="postcode"
                                ></input>
                            </div>
                            <div className="oi-col-12">
                                <label className="label-tit">所学专业：</label>
                                <input
                                    className="form-input required"
                                    o-model="formData.professional"
                                    placeholder="请输入所学专业"
                                    data-msg="请输入所学专业"
                                    clearable
                                    name="professional"
                                ></input>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-12">
                                <label className="label-tit">从事该专业时间：</label>
                                <input
                                    className="form-input required"
                                    data-msg="请选择日期"
                                    o-model="formData.professionalTime"
                                    type="month"
                                    placeholder="选择日期"
                                    name="professionalTime"
                                >
                                </input>
                            </div>
                            <div className="oi-col-12">
                                <label className="label-tit">毕业院校：</label>
                                <input
                                    className="form-input required"
                                    o-model="formData.graduationSchool"
                                    placeholder="请输入毕业院校"
                                    data-msg="请输入毕业院校"
                                    clearable
                                    name="graduationSchool"
                                ></input>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-12">
                                <label className="label-tit">参加工作时间：</label>
                                <input
                                    className="form-input required"
                                    o-model="formData.participationWorkTime"
                                    type="month"
                                    placeholder="选择日期"
                                    data-msg="请选择日期"
                                    name="participationWorkTime"
                                >
                                </input>

                            </div>
                            <div className="oi-col-12">
                                <label className="label-tit">担任社会职务：</label>
                                <input
                                    className="form-input required"
                                    type="textarea"
                                    o-model="formData.societyPost"
                                    placeholder="请输入您担任的社会职务"
                                    data-msg="请输入您担任的社会职务"
                                    clearable
                                    name="societyPost"
                                ></input>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-12">
                                <label className="label-tit">获得专业资格证书：</label>
                                <input
                                    className="form-input required"
                                    o-model="formData.qualificationCertificate"
                                    type="month"
                                    data-msg="请选择获得专业资格证书日期"
                                    placeholder="选择日期"
                                    name="qualificationCertificate"
                                >
                                </input>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-24 flex-col">
                                <label className="label-tit">应用领域：</label>
                                <div name='labelListAttr' className="required" data-msg="请选择您的应用领域">
                                    {
                                        this.productTypes.map(item => {
                                            return <label>
                                                <input name="productType" type="checkbox" onClick={(evt) => { this.checkboxClick(evt, 'formData', 'labelListAttr', item, '') }}
                                                    value={item.labelId} />{item.labelName}
                                            </label>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="oi-row oi-row-full">
                            <div className="oi-col-24 flex-col" >
                                <label className="label-tit">个人简介：</label>
                                <div className="required"
                                    name="brief"
                                    data-msg="请输入您的个人简介">
                                    <textarea
                                        placeholder="用以向公众展示个人主要工作履历"
                                        o-model="formData.brief"
                                        maxlength="200" class="right-width"
                                    />
                                </div>

                            </div>
                        </div>
                    </fieldset >
                    <fieldset>
                        <legend>熟悉专业及其代表作</legend>
                        <form
                            o-model="worksJson"
                        >
                            <div>
                                <div>
                                    <table class="advantage-table custom-table" cellpadding="0" cellspacing="0">
                                        <thead>
                                            <tr>
                                                <th width="100">专业特长</th>
                                                <th width="100">熟悉程度</th>
                                                <th width="160">代表性论文、著作或成果</th>
                                                <th width="130">论文摘要</th>
                                                <th width="200">期刊名、出版时间或鉴定验收编号</th>
                                                <th width="120">
                                                    <button className="btn btn-add"
                                                        type="primary" onClick={evt => {
                                                            this.addAdvantage()
                                                        }}
                                                        size="mini"
                                                    >增加专业</button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.advantages.map((item, i) => {
                                                    return <tr>
                                                        <td width="100">
                                                            <input style="width:90%"
                                                                placeholder="专业特长" value={item.speciality} onChange={evt => {
                                                                    item.speciality = evt.target.value;
                                                                    this.updateSelf()
                                                                }}
                                                            />
                                                            <button
                                                                type="text"
                                                                icon="icon-plus" style="padding:0" className="btn btn-add"
                                                                size="mini" onClick={evt => {
                                                                    this.addLevel(item)
                                                                }}>增加熟悉程度</button>
                                                        </td>
                                                        <td colspan="4">
                                                            {
                                                                item.books.map((book, k) => {
                                                                    return <div class="book-item book-item-flex">
                                                                        <div style="width:100px;float:left">
                                                                            <input style="width:90%"
                                                                                placeholder="熟悉程度"
                                                                                type="textarea" value={book.familiarLevel}
                                                                                onChange={evt => {
                                                                                    book.familiarLevel = evt.target.value;
                                                                                    this.updateSelf()
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                book.infos.map((child, k) => {
                                                                                    return <div
                                                                                        style="float: left; position:relative; clear: both" class="book-item book-item-row">
                                                                                        <span class="title-item" style="width:160px">
                                                                                            <input
                                                                                                clearable=""
                                                                                                placeholder="请输入代表性论文、著作或成果"
                                                                                                value={child.achievement}
                                                                                                onChange={evt => {
                                                                                                    child.achievement = evt.target.value;
                                                                                                    this.updateSelf()
                                                                                                }}
                                                                                            />
                                                                                        </span>
                                                                                        <span class="title-item" style="width:130px">
                                                                                            <input
                                                                                                clearable=""
                                                                                                type="textarea"
                                                                                                placeholder="请输入论文摘要" value={child.text}
                                                                                                onChange={evt => {
                                                                                                    child.text = evt.target.value;
                                                                                                    this.updateSelf()
                                                                                                }}
                                                                                            />
                                                                                        </span>
                                                                                        <span class="title-item" style="width:200px">
                                                                                            <input
                                                                                                clearable="" value={child.periodicalsName}
                                                                                                placeholder="请输入期刊名、出版时间或鉴定验收编号"
                                                                                                onChange={evt => {
                                                                                                    child.periodicalsName = evt.target.value;
                                                                                                    this.updateSelf()
                                                                                                }}
                                                                                            />
                                                                                        </span>
                                                                                        <span class="info-item" style="position:absolute;right:0;bottom:-3rem">
                                                                                            <button
                                                                                                type="text"
                                                                                                icon="icon-plus" className="btn btn-add"
                                                                                                size="mini" onClick={evt => {
                                                                                                    this.addBook(book.infos)
                                                                                                }}>增加论文、著作或成果</button>
                                                                                            <button
                                                                                                type="text"
                                                                                                size="mini" className="btn btn-delete"
                                                                                                icon="icon-delete" v-if="book.infos.length>1"
                                                                                                onClick={evt => {
                                                                                                    this.removeBook(book.infos, i)
                                                                                                }}>删除</button>
                                                                                        </span>
                                                                                    </div>
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </div >
                                                                })
                                                            }

                                                        </td >

                                                        <td width="120" align="center">
                                                            <button
                                                                type="text"
                                                                icon="icon-delete" className="btn btn-delete"
                                                                size="mini" onClick={evt => {
                                                                    this.removeAdvantage(i)
                                                                }}>删除专业</button>
                                                        </td >
                                                    </tr >
                                                })
                                            }

                                        </tbody>
                                    </table >
                                </div >
                            </div >
                        </form >
                    </fieldset >
                    <fieldset>
                        <legend>近五年从事的主要研究项目</legend>
                        <div>
                            <div className="project-item">
                                <label><i className="red">*</i>项目名称：</label>
                                <input name="projectName" o-model="researchProjects.projectName"
                                    data-msg="请输入项目名称" clearable>
                                </input>
                            </div>
                            <div className="project-item">
                                <label><i className="red">*</i>项目来源：</label>
                                <input data-msg="请输入项目来源" o-model="researchProjects.projectSource" name="projectSource"> </input>
                            </div>
                            <div className="project-item">
                                <label><i className="red">*</i>立项年月：</label>
                                <input data-msg="请输入立项年月" type="month" name="establishmentDate" o-model="researchProjects.establishmentDate"> </input>
                            </div>
                            <div className="project-item">
                                <label><i className="red">*</i>A负责人/B参加者：</label>
                                <div data-msg="请选择参与身份" >
                                    <label className=" horizontal">
                                        <input type="radio" value="A" name="leaderParticipants" o-model="researchProjects.leaderParticipants"></input>A负责人
                                    </label> <label className="horizontal">
                                        <input type="radio" value="B" name="leaderParticipants" o-model="researchProjects.leaderParticipants"></input>B参加者
                                    </label>
                                </div>
                            </div>
                            <div className="project-item">
                                <label><i className="red">*</i>A在研/B完成：</label>
                                <div data-msg="请选择项目进度" >
                                    <label className=" horizontal">
                                        <input type="radio" value="A" name="workingComplete" o-model="researchProjects.workingComplete"></input>A在研
                                    </label> <label className="horizontal">
                                        <input type="radio" value="B" name="workingComplete" o-model="researchProjects.workingComplete"></input>B完成
                                    </label>
                                </div>
                            </div>
                            <button className="btn btn-primary project-item" onClick={evt => {
                                this.addProject()
                            }} type="success">添加</button>
                        </div>
                        <table width="100%" className="custom-table" border="0" cellpadding="0" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>项目名称</th>
                                    <th>项目来源</th>
                                    <th>立项年月</th>
                                    <th>A负责人/B参加者</th>
                                    <th>A在研/B完成</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.productJson.map((item, i) => {
                                        return <tr>
                                            <td>{item.projectName}</td>
                                            <td>{item.projectSource}</td>
                                            <td>{item.establishmentDate}</td>
                                            <td>{item.leaderParticipants}</td>
                                            <td>{item.workingComplete}</td>
                                            <td>

                                                <button
                                                    type="text"
                                                    icon="el-icon-delete"
                                                    slot="reference" onClick={evt => {
                                                        this.handleDeleteProject(i)
                                                    }}
                                                >删除</button>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </fieldset >
                    <fieldset>
                        <legend>工作简历</legend>
                        <oi-tinymce o-model="formData.jobResume" quickbars_insert_toolbar='' quickbars_selection_toolbar='' placeholder="请输入工作简历" toolbar='code|undo redo | bold italic| link | alignleft aligncenter alignright ' value={this.formData.jobResumeRef}></oi-tinymce>
                    </fieldset>
                    <fieldset>
                        <legend>相关证明</legend>
                        <div className="oi-row oi-row-full">
                            <label className="label-tit">营业执照：</label>
                            <div name="imgage.licensePic" data-msg="请上传营业执照" className="required">
                            </div>
                            <input ref={this.fileRefYy} type="file" accept="image/*" style="display:none" />
                            <button class="btn-upload btn btn-primary" onClick={evt => {
                                this.uploadPhotoYy()
                            }}>上传</button>
                        </div>
                        <div className="oi-row oi-row-full">
                            <label className="label-tit">单位证明：</label>
                            <div name="imgage.proofPhoto" data-msg="请上传单位证明" className="required">
                                <input ref={this.fileRefDw} type="file" accept="image/*" style="display:none" />
                            </div>
                            <button class="btn-upload btn btn-primary" onClick={evt => {
                                this.uploadPhotoDw()
                            }}>上传</button>
                        </div>
                        <div className="oi-row oi-row-full">
                            <label className="label-tit">学历证书：</label>
                            <div name="imgage.eduPhoto" data-msg="请上传学历证书" className="required">
                                <input ref={this.fileRefXl} type="file" accept="image/*" style="display:none" />
                            </div>
                            <button class="btn-upload btn btn-primary" onClick={evt => {
                                this.uploadPhotoXl()
                            }}>上传</button>
                        </div>
                        <div className="oi-row oi-row-full">
                            <label className="label-tit">职称证书：</label>
                            <div name="imgage.rankPhoto" data-msg="请上传职称证书" className="required">
                                <input ref={this.fileRefZc} type="file" accept="image/*" style="display:none" />
                            </div>
                            <button class="btn-upload btn btn-primary" onClick={evt => {
                                this.uploadPhotoZc()
                            }}>上传</button>
                        </div>
                        <div className="oi-row oi-row-full">
                            <label className="label-tit">无犯罪记录证明：</label>
                            <div name="imgage.goodPhoto" data-msg="请上传无犯罪记录证明" className="required">
                                <input ref={this.fileRefFz} type="file" accept="image/*" style="display:none" />
                            </div>
                            <button class="btn-upload btn btn-primary" onClick={evt => {
                                this.uploadPhotoFz()
                            }}>上传</button>
                        </div>
                    </fieldset>
                    <div className="footer-btns">
                        <button className="btn btn-default">暂 存</button>
                        <button className="btn btn-primary" onClick={evt => {
                            this.submitForm();
                        }}>提 交</button>
                    </div>

                </div>
            </>
        )
    }

}
