const { h, define, Component, classNames, uniqueTag } = omii;
import "../../../es-lib/omii/latest/ui/omii-ui.js"

import css from "./dialog.scss";

export default class extends Component {
    static css = css;

    style() {
        return `
       h1 {
            color: red;
        }
    `;
    }


    changeStatus(e) {
        this.props.settings.show=false;
        this.updateSelf();
        // this.updateSettings({
        //     show: false
        // })
        // this.fire("close")
    }
    install() {
        // console.log(this.settings)
    }
    render() {
        let styleObj = {
            width: this.props.settings.width,
            height: this.props.settings.height,
        }
        return (
            <>
                <div class={classNames("layer", {
                    hide: !this.props.settings.show,
                })}></div>
                <div style={styleObj} class={classNames("oi-dialog-box", {
                    hide: !this.props.settings.show,
                })}>
                    <h2 className="oi-row title">{this.props.settings.title}</h2>
                    <span className="close" onClick={
                        (evt) => this.changeStatus()
                    }>X</span>
                    <p class="content">
                        <slot></slot>
                    </p>
                    <div class="dialog-footer">
                        <slot name="footer"></slot>
                    </div>
                </div>

            </>
        )
    }

}
