const { h, uniqueTag, Component, define, apis, createRef, render } = omii;
import security from "./security";
import css from "./css/login.scss";
import { codeController, labelsController } from "./apis";

import { setToken, getToken, isTokenExpired } from "./token"

import labels from "./components/labels"
import imageVerify from './components/imageVerify/index'

import homePage from "./home";
define("oi-labels", labels)
define("image-verify", imageVerify)

let images = [];
let telValidator = "^\\d{1,4}-\\d{1,4}-\\d{5,9}(-\\d{1,4})?$";
// let phoneValidator = "^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\\d{8}$";
let phoneValidator = "^\\d{11}$";//调整手机号验证为 只校验11位数字 --update by sxm 20240715
// let emailValidator = "^([a-zA-Z]|[0-9])(\\w|\\-)+@([a-zA-Z0-9_-])+\\.([a-zA-Z]{2,4})$";
let emailValidator = "^([a-zA-Z]|[0-9])(\w|\-|\.)+@(([a-zA-Z0-9]|\-|\.)*([a-zA-Z0-9]|\-))+\.[a-zA-Z]{2,}$";
// let emailValidator = "^([a-zA-Z]|[0-9])(\\w|\\-)+@[a-zA-Z0-9]+\\.([a-zA-Z]{2,4})$";
let passValidator = "(?!^[0-9]+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^A-z0-9]+$)^[^\\s\\u4e00-\\u9fa5]{8,20}$";
let english = "[A-Za-z]+";
let numberValidator = "^-?(?:\\d+|\\d{1,3}(?:,\\d{3})+)?(?:\\.\\d+)?$";
let creditCodeValidator = "^[a-zA-Z0-9]{18}$";
let entProfileValidator = "^[\\s\\S]{1,800}$";
let entNameValidator = "^[^\\s]{6,}$";

let fax = '/^(?:\d{3,4}-)?\d{7,8}(?:-\d{1,6})?$/'
// let fax = ' /^(\d{3,4}-)?\d{7,8}$/'
define("af-register", class extends Component {
        type = 1;
        userId = "";
        gxqcp = [];
        gzly = [];
        userType = 0;//用户类型 1-个人，2-企业（实际存储时，01-企业，02-个人）
        tip = "获取验证码";
        disBtn = false;
        countries = [];
        provinces = [];
        cities = [];
        agree = [];
        zcsmList = [];
        serviceArticle = {};
        ruleArticle = {};
        static css = css;
        registObj = {//企业资料
            foundDate:''
        };
        registObjGr = {//个人资料
        };

        registObjCommon = {
            username: "",
            password: "",
            code: "",
        };

        serviceSetting = {
            width: "880px",
            height: "600px",
            show: false,
            title: "服务条款"
        }
        ruleSetting = {
            width: "880px",
            height: "600px",
            show: false,
            title: "服务细则"
        }
        codeRef = createRef();
        phoneRef = createRef();
        imgVerifyRef = createRef();

        //新版 add by sxm 20230418
        stepin = 1;//当前步骤
        interestProductList = [];//关心的产品
        focusAreaList = [];//关心的领域
        enterpriseTypeList = [];//企业类型
        subQylx = {
            type1: {//生产企业
                checked: false,
                list: []
            },
            type2: {//系统集成
                checked: false,
                list: []
            },
            type3: {//运营服务
                checked: false,
                list: []
            },
            type4: {//其他
                checked: false,
                list: []
            }
        };
        loading = false;
        loadingText = '';
        nullTipsList = [];//为空的项
        zgzFile = {};
        provinceTaiwan = {};//台湾
        provinceHongKong = {};//香港
        provinceMacao = {};//澳门
        isImageShow= false;//是否显示图片滑块验证码弹窗
        imgData= {
            imgCode: '',
            imgUuid: ''
        };//图片滑块验证码部分参数

        //刷新验证码
        refreshCode() {
            codeController.get().then((r) => {
                this.codeRef.current.src = `data:image/jpg;base64,${r.img}`;
                this.registObjCommon.uuid = r.uuid;
            });
        }
        //表单校验 true-校验通过
        validator(formName, exceptItems) {
            let flag = true;
            this.nullTipsList = [];
            let registObjName = "registObj";
            if (formName == 'form-grzl') {
                registObjName = "registObjGr";
            }
            if (formName == 'form-zcxx') {
                registObjName = "registObjCommon";
            }
            for (var i = 0; i < this.$$("." + formName + " .required").length; i++) {
                let item = this.$$("." + formName + " .required")[i];
                let name = item.getAttribute("name");
                if(exceptItems!=undefined && exceptItems!=null && exceptItems.length>0){//无需校验的字段name
                    var isExcept = false;
                    for(var e=0; e<exceptItems.length; e++){
                        if(name == exceptItems[e]){
                            isExcept = true;
                        }
                    }
                    if(isExcept){//无需校验
                        continue;
                    }
                }
                let nameArr = name.split(".");
                let currentValue = this[registObjName][nameArr[0]];
                if (nameArr.length > 1) {
                    currentValue = this[registObjName][nameArr[0]][nameArr[1]];
                }
                let needValidator = false;
                //企业细分类别单独判断下，是否需要校验
                if (name == 'mainProductArr' || name == 'applicationAreaArr' || name == 'serviceAreaArr' || name == 'otherEnterpriseTypeArr') {
                    if (this[registObjName].enterpriseTypes != undefined && this[registObjName].enterpriseTypes.length > 0) {
                        for (var m = 0; m < this[registObjName].enterpriseTypes.length; m++) {
                            if ((name == 'mainProductArr' && this[registObjName].enterpriseTypes[m].labelId == '1')
                                || (name == 'applicationAreaArr' && this[registObjName].enterpriseTypes[m].labelId == '2')
                                || (name == 'serviceAreaArr' && this[registObjName].enterpriseTypes[m].labelId == '3')
                                || (name == 'otherEnterpriseTypeArr' && this[registObjName].enterpriseTypes[m].labelId == '4')
                            ) {
                                needValidator = true;
                            }
                        }
                    }
                } else {
                    needValidator = true;
                }
                if (needValidator) {
                    if (!currentValue) {
                        flag = false;
                        let msgNode = item.parentNode.querySelector("span.errorTip");
                        if (!msgNode) {
                            let span = document.createElement("span");
                            span.className = "errorTip";
                            span.innerText = item.dataset.msg;
                            item.parentNode.appendChild(span);
                        }
                        this.nullTipsList.push(item.dataset.msg);
                    } else {
                        let msgNode = item.parentNode.querySelector("span.errorTip");
                        let reg = item.dataset.reg;
                        if (reg) {
                            reg = new RegExp(reg);
                            if (!reg.test(currentValue)) {
                                flag = false;
                                if (msgNode) {
                                    flag = false;
                                    msgNode.innerText = item.dataset.tip;
                                } else {
                                    let span = document.createElement("span");
                                    span.className = "errorTip";
                                    span.innerText = item.dataset.tip;
                                    item.parentNode.appendChild(span);
                                }
                                this.nullTipsList.push(item.dataset.tip);
                            } else {
                                if (msgNode) {
                                    item.parentNode.removeChild(msgNode)
                                }
                            }
                        } else {
                            if (msgNode) {
                                item.parentNode.removeChild(msgNode)
                            }
                        }
                    }
                }
            }
            // console.info("验证结果：",flag)
            return flag;
        }

        handlerUser() {
            // let userId = this.$route
            // this.userId = Numbler(this.userId)
            // roleId = Number(roleId)
            fetch("/prod-api/system/role/checkUserInRole?userId=" + Number(this.userId) + "&roleId=" + Number(roleId), {
            }).then(response => response.json()).then(r => {
                if (r.data == true) {
                    this.registObjCommon.areaFlag = this.userId
                    this.userType = 2
                }
            }).then(r1 => {
            });
        }
        //初始化
        install() {
            // console.log(location.hash);
            // console.log(roleId);
            if (location.hash) {
                this.userId = location.hash.split("=")[1]
                if (this.userId) {
                    this.handlerUser()
                }
            }
            this.getCountries();
            this.getProvinces();

            this.getQyDict();
            this.getGrDict();
            // this.refreshCode();
        }
        //切换用户类型
        changeUser(e, v) {
            this.userType = v;
            this.update();
        }
        //切换国家
        changeCountry(e) {
            if (this.userType == 2) {
                this.registObj.countryCode = e.target.value;
            } else {
                this.registObjGr.countryCode = e.target.value;
            }
            this.update();
        }
        //获取国家列表
        getCountries() {
            fetch("/prod-api/manage/country/list", {}).then(response => response.json()).then(r => {
                if (r.code == 200) {
                    this.countries = r.data;
                    this.update();
                }
            });
        }
        //获取省份列表
        getProvinces() {
            fetch("/prod-api/manage/city/list?parentCode=156", {
            }).then(response => response.json()).then(r => {
                if (r.code == 200) {
                    r.data.forEach(item=>{
                        if(item.cityName.indexOf("台湾")>-1){
                            this.provinceTaiwan = item;
                        }
                        if(item.cityName.indexOf("香港")>-1){
                            this.provinceHongKong = item;
                        }
                        if(item.cityName.indexOf("澳门")>-1){
                            this.provinceMacao = item;
                        }
                    });
                    r.data.pop();
                    r.data.pop();
                    r.data.pop();


                    this.provinces = r.data;
                    // console.log(r);
                } else {
                    this.provinces = [];
                }
            }).then(r1 => {
                if (this.provinces.length > 0) {
                    this.registObj.province = this.provinces[0].cityCode;
                    this.registObjGr.province = this.provinces[0].cityCode;
                    // console.log(this.registObj.province)
                    this.getCitys(event, this.provinces[0].cityCode);
                }
            });
        }
        //获取城市列表
        getCitys(e, code) {
            let cityCode;
            if (code) {
                cityCode = code;
            } else {
                cityCode = e.target.value;
            }
            if (this.userType == 0 || this.userType == 2) {//初始or企业
                this.registObj.province = cityCode;
            }
            if (this.userType == 0 || this.userType == 1) {//初始or个人
                this.registObjGr.province = cityCode;
            }
            fetch("/prod-api/manage/city/list?parentCode=" + cityCode, {
            }).then(response => response.json()).then(r => {
                if (r.code == 200) {
                    this.cities = r.data;
                } else {
                    this.cities = [];
                }
                if (this.cities.length > 0) {
                    if (this.userType == 0 || this.userType == 2) {//初始or企业
                        this.registObj.city = r.data[0].cityCode;
                    }
                    if (this.userType == 0 || this.userType == 1) {//初始or个人
                        this.registObjGr.city = r.data[0].cityCode;
                    }
                }
                this.update();
            });
        }
        //上传营业执照
        uploadFile() {
            this.$("#btn-file").click();
        }
        //上传营业执照
        addFile() {
            let fileInfo = this.$("#btn-file").files[0];
            let fileEXT = fileInfo.name.substring(fileInfo.name.lastIndexOf(".") + 1);
            let extNames = "JPG|JPEG|PNG";
            if (extNames.indexOf(fileEXT.toUpperCase()) < 0) {
                alert("请上传jpg或png格式的图片");
                return false;
            }
            if (fileInfo.size > 500 * 1024) {
                alert("请上传500k以内的图片");
                return false;
            }
            let image = new Image();
            image.src = URL.createObjectURL(fileInfo);
            let that = this;
            image.onload = function () {
                // if (image.width > 700) {
                //   alert("图片宽度不要大于700px");
                //   return false;
                // }
                that.zgzFile = that.$("#btn-file").files[0];
                // let fileObj = new FormData();
                // fileObj.append("imageFile", that.$("#btn-file").files[0]);
                // fileObj.append("busiType", 'ent');
                //回显图片
                let img = that.$("#licenseBook");
                if (!img) {
                    img = document.createElement("img");
                }
                img.id = "licenseBook";
                img.width = 200;
                if (that.$("#btn-file").files[0]){
                    const reader = new FileReader();
                    reader.readAsDataURL(that.$("#btn-file").files[0]);
                    reader.onload = function(event) {
                        // const img = document.getElementById('licenseBook');
                        img.src = event.target.result; // 将图片的Base64编码设置为img的src
                    };
                }
                img.style.marginTop = '50px'
                img.style.marginBottom = '50px'
                // img.src = imageFileUrl + data.result;
                that.$("#uploadArea").after(img);
                // fetch("/image/api/v1.0/scale/uploadImage", {
                //     method: 'POST',
                //     body: fileObj,
                //     headers: {
                //         Authorization: `Bearer ${getToken()}`
                //     }
                // })
                //     .then(response => response.json())
                //     .then(data => {
                //         if (data.success) {
                //             that.registObj.licenseCopy = data.result;
                //             let img = that.$("#licenseBook");
                //             if (!img) {
                //                 img = document.createElement("img");
                //             }
                //             img.id = "licenseBook";
                //             img.width = 200;
                //             img.src = imageFileUrl + data.result;
                //             that.$("#uploadArea").after(img);
                //         } else {
                //             alert(data.resultMessage)
                //         }
                //     })
                //     .catch(error => {
                //         console.error(error)
                //     })
                // fetch("/prod-api/file/upload", {
                //   method: 'POST',
                //   body: fileObj,
                //   headers: {
                //     Authorization: `Bearer ${getToken()}`
                //   }
                // })
                //   .then(response => response.json())
                //   .then(data => {
                //     if (data.code == 200) {
                //       that.registObj.licenseCopy = data.data.url;
                //       let img = that.$("#licenseBook");
                //       if (!img) {
                //         img = document.createElement("img");
                //       }
                //       img.id = "licenseBook";
                //       img.width = 200;
                //       img.src = fileUrl + data.data.url;
                //       that.$("#uploadArea").after(img);
                //     } else {
                //       alert(data.msg)
                //     }
                //   })
                //   .catch(error => {
                //     console.error(error)
                //   })
            }

        }
        //上传logo
        uploadLogoFile() {
            this.$("#btn-logo-file").click();
        }
        //上传logo
        addLogoFile() {
            let fileInfo = this.$("#btn-logo-file").files[0];
            let fileEXT = fileInfo.name.substring(fileInfo.name.lastIndexOf(".") + 1);
            let extNames = "JPG|JPEG|PNG";
            if (extNames.indexOf(fileEXT.toUpperCase()) < 0) {
                alert("请上传jpg或png格式的图片");
                return false;
            }
            if (fileInfo.size > 300 * 1024) {
                alert("请上传300k以内的图片");
                return false;
            }
            let image = new Image();
            image.src = URL.createObjectURL(fileInfo);
            let that = this;
            image.onload = function () {
                let fileObj = new FormData();
                fileObj.append("imageFile", that.$("#btn-logo-file").files[0]);
                fileObj.append("busiType", 'ent');
                fetch("/image/api/v1.0/scale/logo", {
                    method: 'POST',
                    body: fileObj,
                    headers: {
                        Authorization: `Bearer ${getToken()}`
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success) {
                            that.registObj.logo = data.result;
                            let img = that.$("#logoFile");
                            if (!img) {
                                img = document.createElement("img");
                            }
                            img.id = "logoFile";
                            img.width = 200;
                            img.src = imageFileUrl + data.result;
                            that.$("#uploadLogoArea").after(img);
                        } else {
                            alert("文件上传失败！")
                        }
                    })
                    .catch(error => {
                        console.error(error)
                    })
                // fetch("/prod-api/file/upload", {
                //   method: 'POST',
                //   body: fileObj,
                //   headers: {
                //     Authorization: `Bearer ${getToken()}`
                //   }
                // })
                //   .then(response => response.json())
                //   .then(data => {
                //     if (data.code == 200) {
                //       that.registObj.logo = data.data.url;
                //       let img = that.$("#logoFile");
                //       if (!img) {
                //         img = document.createElement("img");
                //       }
                //       img.id = "logoFile";
                //       img.width = 200;
                //       img.src = fileUrl + data.data.url;
                //       that.$("#uploadLogoArea").after(img);
                //     } else {
                //       alert(data.msg)
                //     }
                //   })
                //   .catch(error => {
                //     console.error(error)
                //   })
            }

        }
        //获取手机验证码
        getVerify() {
            var flag = this.validator("form-zcxx",["checkCode"]);
            if(!flag){
                return;
            }
            let num = 60
            //设置循环定时器
            // var myreg = /^[1][3,5,4,7,8,9][0-9]{9}$/
            var myreg = /^\d{11}$/ //调整手机号验证为只校验11位数字 --update by sxm 20240715
            if (myreg.test(this.registObjCommon.phoneNumber)) {
                // if (this.registObjCommon.code == '') {
                //     alert('请输入图片验证码')
                //     return;
                // }
                this.disBtn = true
                let that = this
                let userType = '02';//01-企业，02-个人
                if (this.userType == '2') {
                    userType = '01';
                }else{
                    userType = '02';
                }
                let sysUser = {
                    username:this.registObjCommon.username,
                    password:this.registObjCommon.password,
                    confirmPassword:this.registObjCommon.confirmPassword,
                    email:this.registObjCommon.email,
                    phoneNumber:this.registObjCommon.phoneNumber,
                    userType: userType
                }
                // fetch(`/prod-api/system/user/checkIsTelReg?phonenumber=${this.registObjCommon.phoneNumber}&userType=` + userType, {
                // fetch(`/prod-api/system/user/checkIsTelReg?phonenumber=${this.registObjCommon.phoneNumber}&userType=` + userType, {
                // fetch(`/prod-api/system/user/checkUserReg?username=${this.registObjCommon.username}&password=${this.registObjCommon.password}&confirmPassword=${this.registObjCommon.confirmPassword}&email=${this.registObjCommon.email}&phoneNumber=${this.registObjCommon.phoneNumber}&userType=` + userType, {
                fetch(`/prod-api/system/user/checkUserReg`, {
                    method: 'post',
                    body: JSON.stringify(sysUser),
                    headers: {
                        "Content-type": "application/json"
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.code == 200) {
                            if (data.data != 0) {
                                alert("手机号码“" + this.registObjCommon.phoneNumber + "”已注册！")
                                that.disBtn = false
                                this.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
                            } else {
                                // fetch(`/prod-api/manage/open/smscode/SendSMSIncludeCaptcha?tel=${this.registObjCommon.phoneNumber}&code=${this.registObjCommon.code}&uuid=${this.registObjCommon.uuid}`, {//20240510之前，图片验证码版本
                                fetch(`/prod-api/manage/open/smscode/SendSMSIncludeCaptchaHd?tel=${this.registObjCommon.phoneNumber}&code=${this.imgData.imgCode}&uuid=${this.imgData.imgUuid}`, {//20240510之后，图片滑块验证码版本
                                    method: 'get',
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        if (data.code == 200) {
                                            let timer = setInterval(function () {
                                                num--
                                                if (num <= 0) {
                                                    //清除循环执行定时器
                                                    clearInterval(timer);
                                                    //还原
                                                    num = 60;
                                                    that.disBtn = false
                                                    that.tip = "获取验证码";
                                                } else {
                                                    that.tip = num + '秒后可重新获取';
                                                }
                                                that.update();
                                            }, 1000)
                                            this.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
                                        } else {
                                            // that.refreshCode();
                                            that.disBtn = false
                                            that.update();
                                            alert(data.msg)
                                            this.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
                                        }
                                    })
                            }
                        } else {
                            alert(data.msg)
                            that.disBtn = false
                            this.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
                        }
                        this.update();
                    })

            } else {
                alert('输入正确手机号')
                this.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
            }

        }

        showServiceDialog() {
            this.serviceSetting.show = true;
            let articleObj = this.zcsmList.find(a => a.title == "服务条款");
            if (articleObj) {
                articleObj.content = decodeURI(articleObj.content);
            }
            this.serviceArticle = articleObj;
            this.update();
        }
        showRuleDialog() {
            this.ruleSetting.show = true;
            let articleObj = this.zcsmList.find(a => a.title == "服务细则");
            if (articleObj) {
                articleObj.content = decodeURI(articleObj.content);
            }
            this.ruleArticle = articleObj;
            this.update();
        }
        // 获取服务条款、服务细则
        getArticles() {
            fetch("/prod-api/manage/article/listByTab?tabKey=ZCSM", {
                method: "get",
                headers: {
                    "SiteKey": "Portal"
                }
            }).then(response => response.json())
                .then(res => {
                    // console.log(res)
                    this.zcsmList = res.rows;
                    this.update();
                })
        }

        updateService() {
            this.serviceSetting.show = false;
            this.update();
        }
        updateRule() {
            this.ruleSetting.show = false;
            this.update();
        }
        installed() {
            this.getArticles();
        }

        /**新版注册脚本 add by sxm 20230418 **/
        //企业用户字典值
        getQyDict() {
            this.getEnterpriseType();
            this.getSubEnterpriseType();
        }
        //个人用户字典值
        getGrDict() {
            this.getInterestProduct();
            this.getInterestArea();
        }
        //获取企业类型
        getEnterpriseType() {
            fetch("/prod-api/system/dict/data/type/enterprise_type", {}).then(response => response.json()).then(r => {
                if (r.code == 200) {
                    this.enterpriseTypeList = r.data;
                    this.update();
                }
            });
        }
        //获取企业细分类别
        getSubEnterpriseType() {
            fetch("/prod-api/system/dict/data/type/sc_main_product_type", {}).then(response => response.json()).then(r => {
                if (r.code == 200) {
                    this.subQylx.type1.list = r.data;
                    this.update();
                }
            });
            fetch("/prod-api/system/dict/data/type/jc_busi_area_type", {}).then(response => response.json()).then(r => {
                if (r.code == 200) {
                    this.subQylx.type2.list = r.data;
                    this.update();
                }
            });
            fetch("/prod-api/system/dict/data/type/yy_serve_area_type", {}).then(response => response.json()).then(r => {
                if (r.code == 200) {
                    this.subQylx.type3.list = r.data;
                    this.update();
                }
            });
            fetch("/prod-api/system/dict/data/type/other_enterprise_type", {}).then(response => response.json()).then(r => {
                if (r.code == 200) {
                    this.subQylx.type4.list = r.data;
                    this.update();
                }
            });
        }
        //获取所关心的产品
        getInterestProduct() {
            fetch("/prod-api/system/dict/data/type/sc_main_product_type", {}).then(response => response.json()).then(r => {
                if (r.code == 200) {
                    this.interestProductList = r.data;
                    this.update();
                }
            });
        }
        //获取所关心的领域
        getInterestArea() {
            fetch("/prod-api/system/dict/data/type/jc_busi_area_type", {}).then(response => response.json()).then(r => {
                if (r.code == 200) {
                    this.focusAreaList = r.data;
                    this.update();
                }
            });
        }
        //复选框选择（原数据格式为dict）
        checkboxClickDict(e, obj, name, item, business) {
            if (e.target.checked) {//选中
                if (this[obj][name] == undefined) {
                    this[obj][name] = [];
                }
                var newItem = {
                    labelId: item.dictValue,
                    labelName: item.dictLabel,
                    editFlag: null,
                    subBusiness: business
                }
                this[obj][name].push(newItem);
                if (name == 'enterpriseTypes') {//企业类型单独处理下细分类别的显引
                    this.subQylx['type' + item.dictValue].checked = true;
                }
            } else {//取消选中，移除
                for (var i = 0; i < this[obj][name].length; i++) {
                    if (item.dictValue == this[obj][name][i].labelId) {
                        this[obj][name].splice(i, 1);
                        if (name == 'enterpriseTypes') {//企业类型单独处理下细分类别的显引
                            this.subQylx['type' + item.dictValue].checked = false;
                        }
                    }
                }
                if (this[obj][name].length == 0) {//为空的时候，把对象删掉
                    delete this[obj][name]
                }
            }
            this.update();
        }
        //复选框选择事件
        checkboxClick(e, obj, name, item, business) {
            if (e.target.checked) {//选中
                if (this[obj][name] == undefined) {
                    this[obj][name] = [];
                }
                var newItem = {
                    labelId: item.labelId,
                    labelName: item.labelName,
                    editFlag: item.editFlag,
                    subBusiness: business
                }
                this[obj][name].push(newItem);
            } else {//取消选中，移除
                for (var i = 0; i < this[obj][name].length; i++) {
                    if (item.labelId == this[obj][name][i].labelId) {
                        this[obj][name].splice(i, 1);
                    }
                }
                if (this[obj][name].length == 0) {//为空的时候，把对象删掉
                    delete this[obj][name]
                }
            }
            this.update();
        }
        //修改textarea值
        changeTextarea(e, obj, name){
            if (this[obj][name] == undefined) {
                this[obj][name] = "";
            }
            this[obj][name] = e.target.value;
        }

        //重置
        resetForm(e) {
            if (this.stepin == 1) {//第一步
                this.userType = 0;
            } else if (this.stepin == 2) {//第二步
                if (this.userType == 1) {
                    // this.$$(".form-grzl input.form-input").map(item => item.value = '')
                    this.registObjGr = {};
                } else if (this.userType == 2) {
                    // this.$$(".form-qyzl input.form-input").map(item => item.value = '')
                    this.registObj = {};
                }
            } else if (this.stepin == 3) {//第三步
                this.$$(".form-zcxx input.form-input").map(item => item.value = '')
                this.registObjCommon = {};
            }
            this.isImageShow = false;
            this.update();
        }
        //上一步
        lastStep(e, v) {
            this.stepin = v;
            this.update();
        }
        //下一步
        nextStep(e, v) {
            if (v == 2) {//进入步骤2，验证用户类型
                if (this.userType == 0) {
                    alert("请选择用户类型！");
                    return false;
                }
            } else if (v == 3) {//进入步骤3，验证表单
                var flag = false;
                if (this.userType == 1) {
                    flag = this.validator("form-grzl");
                } else if (this.userType == 2) {
                    flag = this.validator("form-qyzl");
                }
                if (!flag) {
                    if (this.nullTipsList != null && this.nullTipsList.length > 0) {
                        alert(this.nullTipsList[0]);//返回第一个错误提示
                    }
                    return false;
                }
            }
            this.stepin = v;
            this.update();
        }
        changeLoading(flag) {
            this.loading = flag;
            this.update();
        }
        //检查公司名称是否已注册
        checkCompanyReg() {
            let that = this;
            if (this.registObj.entName != undefined && this.registObj.entName != null && this.registObj.entName != '') {
                if(!new RegExp(entNameValidator).test(this.registObj.entName)){
                    alert("公司名称需至少6个字（不含空格）！")
                    return;
                }
                fetch(`/prod-api/manage/enterpriseNew/checkIsCompanyReg?entName=${this.registObj.entName.replace(/\s+/g, '')}`, {
                    method: 'get',
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.code == 200) {
                            if (data.data != 0) {
                                alert("公司名称“" + this.registObj.entName + "”已注册！")
                                this.$$("#entName")[0].value = '';
                                this.registObj.entName = '';
                                this.update();
                            }
                        } else {
                            alert(data.msg)
                        }
                    })
            }

        }
        //检查统一代码是否已注册
        checkCreditCodeReg() {
            let that = this;
            var myreg = /^[a-zA-Z0-9]{18}$/
            if (myreg.test(this.registObj.creditCode)) {
                if (this.registObj.creditCode != undefined && this.registObj.creditCode != null && this.registObj.creditCode != '') {
                    fetch(`/prod-api/manage/enterpriseNew/checkIsCreditCodeReg?creditCode=${this.registObj.creditCode}`, {
                        method: 'get',
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.code == 200) {
                                if (data.data != 0) {
                                    alert("统一代码 “" + this.registObj.creditCode + "”已注册！")
                                    this.$$("#creditCode")[0].value = '';
                                    this.registObj.creditCode = '';
                                    this.update();
                                }
                            } else {
                                alert(data.msg)
                            }
                        })
                }
            }else{
                alert('请输入正确格式的统一代码')
            }


        }
        //注册提交
        submit() {
            this.isImageShow = false;
            this.update();
            var flag = this.validator("form-zcxx");
            if (flag) {
                if (this.agree.length == 0) {
                    alert("请同意“服务条款”和“服务细则”");
                    return false;
                }
                if (this.registObjCommon.password != this.registObjCommon.confirmPassword) {
                    alert("密码和确认密码不一致");
                    return false;
                }
                //个人用户注册
                if (this.userType == 1) {
                    this.changeLoading(true);
                    var obj = JSON.parse(JSON.stringify(this.registObjCommon));
                    var objGr = JSON.parse(JSON.stringify(this.registObjGr));
                    obj = {
                        ...obj,
                        ...objGr
                    }
                    // obj.uuid = this.registObj.uuid;
                    obj.userType = '02';
                    obj.userSource = '11';
                    var countryName = '';
                    if (obj.countryCode == "156") {
                        countryName = "中国";
                    } else if (obj.countryCode == "344") {//香港
                        obj.countryCode = "156";
                        countryName = "中国";
                        obj.province = this.provinceHongKong.cityCode;
                    } else if (obj.countryCode == "446") {//澳门
                        obj.countryCode = "156";
                        countryName = "中国";
                        obj.province = this.provinceMacao.cityCode;
                    } else if (obj.countryCode == "158") {//台湾
                        obj.countryCode = "156";
                        countryName = "中国";
                        obj.province = this.provinceTaiwan.cityCode;
                    }
                    if (this.registObjGr.countryCode == 5) {
                        countryName = this.countries.find(a => a.countryCode == this.registObjGr.otherCountry).countryName;
                        obj.countryCode = this.registObjGr.otherCountry;
                    }
                    obj.country = countryName;
                    // obj.labRelations = this.registObjGr.interestProduct.concat(this.registObjGr.focusArea);
                    //关心的产品拼接处理
                    if (this.registObjGr.interestProduct != undefined && this.registObjGr.interestProduct.length > 0) {
                        let careProduct = '';
                        for (let i = 0; i < this.registObjGr.interestProduct.length; i++) {
                            if (careProduct != '') {
                                careProduct += '@';
                            }
                            careProduct += this.registObjGr.interestProduct[i].labelId;
                        }
                        obj.careProduct = careProduct;
                    }
                    //关心的领域拼接处理
                    if (this.registObjGr.focusArea != undefined && this.registObjGr.focusArea.length > 0) {
                        let careApplicationFields = '';
                        for (let i = 0; i < this.registObjGr.focusArea.length; i++) {
                            if (careApplicationFields != '') {
                                careApplicationFields += '@';
                            }
                            careApplicationFields += this.registObjGr.focusArea[i].labelId;
                        }
                        obj.careApplicationFields = careApplicationFields;
                    }

                    //无用字段清一下
                    delete obj.interestProduct
                    delete obj.focusArea
                    delete obj.otherCountry

                    fetch("/prod-api/auth/register", {
                        method: "post",
                        body: JSON.stringify(obj),
                        headers: {
                            "Content-type": "application/json"
                        }
                    }).then(res => res.json()).then(r => {
                        this.changeLoading(false);
                        if (r.code == 200) {
                            // alert("注册成功");
                            this.stepin++;
                            setToken(r.data.access_token,720);
                            // location.href = '/';
                        } else {
                            // this.refreshCode();
                            this.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
                            alert(r.msg);
                        }
                    })

                } else if (this.userType == 2) {//企业用户注册
                    this.changeLoading(true);
                    var obj = JSON.parse(JSON.stringify(this.registObjCommon));
                    // obj.uuid = this.registObj.uuid;
                    obj.userType = '01';
                    obj.userSource = '11';
                    // obj.labRelations = this.registObj.interestProduct.concat(this.registObj.focusArea);//感兴趣的产品/领域
                    //关心的产品拼接处理
                    if (this.registObj.interestProduct != undefined && this.registObj.interestProduct.length > 0) {
                        let careProduct = '';
                        for (let i = 0; i < this.registObj.interestProduct.length; i++) {
                            if (careProduct != '') {
                                careProduct += '@';
                            }
                            careProduct += this.registObj.interestProduct[i].labelId;
                        }
                        obj.careProduct = careProduct;
                    }
                    //关心的领域拼接处理
                    if (this.registObj.focusArea != undefined && this.registObj.focusArea.length > 0) {
                        let careApplicationFields = '';
                        for (let i = 0; i < this.registObj.focusArea.length; i++) {
                            if (careApplicationFields != '') {
                                careApplicationFields += '@';
                            }
                            careApplicationFields += this.registObj.focusArea[i].labelId;
                        }
                        obj.careApplicationFields = careApplicationFields;
                    }
                    //无用字段清一下
                    delete obj.interestProduct
                    delete obj.focusArea
                    delete obj.otherCountry

                    var objQy = JSON.parse(JSON.stringify(this.registObj));
                    //处理国家、省市、通讯地址
                    var countryName = '';
                    if (this.registObj.countryCode == "156") {
                        countryName = "中国";
                        obj.province = objQy.province;
                        obj.city = objQy.city;
                    } else if (this.registObj.countryCode == "344") {//香港
                        obj.countryCode = "156";
                        objQy.countryCode = "156";
                        countryName = "中国";
                        obj.province = this.provinceHongKong.cityCode;
                        objQy.province = this.provinceHongKong.cityCode;
                    } else if (this.registObj.countryCode == "446") {//澳门
                        obj.countryCode = "156";
                        objQy.countryCode = "156";
                        countryName = "中国";
                        obj.province = this.provinceMacao.cityCode;
                        objQy.province = this.provinceMacao.cityCode;
                    } else if (this.registObj.countryCode == "158") {//台湾
                        obj.countryCode = "156";
                        objQy.countryCode = "156";
                        countryName = "中国";
                        obj.province = this.provinceTaiwan.cityCode;
                        objQy.province = this.provinceTaiwan.cityCode;
                    }
                    if (this.registObj.countryCode == 5) {
                        countryName = this.countries.find(a => a.countryCode == this.registObj.otherCountry).countryName;

                        objQy.countryCode = this.registObj.otherCountry;
                    }
                    objQy.country = countryName;
                    obj.country = countryName;
                    obj.countryCode = objQy.countryCode;
                    obj.address = objQy.address;

                    //处理企业类型
                    if (objQy.enterpriseTypes != undefined && objQy.enterpriseTypes.length > 0) {
                        let enterpriseType = '';
                        for (var i = 0; i < objQy.enterpriseTypes.length; i++) {
                            if (enterpriseType != '') {
                                enterpriseType += '@';
                            }
                            enterpriseType += objQy.enterpriseTypes[i].labelId;
                            //处理细分类别
                            let subTypesObj = '';//对象中字段名
                            let subTypesName = '';//存储字段名
                            var subTypes = '';//存储字段内容
                            if (objQy.enterpriseTypes[i].labelId == '1') {//生产企业
                                subTypesName = 'mainProduct';
                                subTypesObj = 'mainProductArr';
                            } else if (objQy.enterpriseTypes[i].labelId == '2') {//系统集成
                                subTypesName = 'applicationFields';
                                subTypesObj = 'applicationAreaArr';
                            } else if (objQy.enterpriseTypes[i].labelId == '3') {//运营服务
                                subTypesName = 'servicesRange';
                                subTypesObj = 'serviceAreaArr';
                            } else if (objQy.enterpriseTypes[i].labelId == '4') {//4是“其他”
                                subTypesName = 'otherEnterpriseType';
                                subTypesObj = 'otherEnterpriseTypeArr';
                            }
                            if (subTypesObj != '' && objQy[subTypesObj] != undefined && objQy[subTypesObj].length > 0) {
                                for (var m = 0; m < objQy[subTypesObj].length; m++) {
                                    if (subTypes != '') {
                                        subTypes += '@';
                                    }
                                    subTypes += objQy[subTypesObj][m].labelId;
                                }
                            }
                            objQy[subTypesName] = subTypes;
                        }
                        objQy.enterpriseType = enterpriseType;
                    }
                    objQy.type = '1';
                    //处理统一代码中空字符串
                    objQy.creditCode = objQy.creditCode.replace(/\s/g, '');
                    //处理一下注册资金中的逗号
                    objQy.regCapital = objQy.regCapital.replace(/,/g, '');
                    //无用字段清一下
                    delete objQy.otherCountry;
                    if (objQy.enterpriseType.indexOf('1')) { delete objQy.mainProductArr; }
                    if (objQy.enterpriseType.indexOf('2')) { delete objQy.applicationAreaArr; }
                    if (objQy.enterpriseType.indexOf('3')) { delete objQy.serviceAreaArr; }
                    if (objQy.enterpriseType.indexOf('4')) { delete objQy.otherEnterpriseTypeArr; }
                    delete objQy.enterpriseTypes;


                    var formData = {
                        enterprise: objQy,
                        user: obj,
                        code: obj.code,
                        uuid: obj.uuid
                    }
                    const json = JSON.stringify(formData);
                    // 将 json 字符串转化为 Blob 对象
                    const blob = new Blob([json], {
                        type: 'application/json',
                    });
                    //添加图片
                    let fileObj = new FormData();
                    // logoFile
                    fileObj.append("files", this.zgzFile);
                    fileObj.append("param", blob);

                    fetch("/prod-api/auth/entRegisterNewUpload", {
                        method: "post",
                        body:fileObj,
                        // headers: {
                        //     "Content-type": "application/json"
                        // }
                    }).then(res => res.json()).then(r => {
                        this.changeLoading(false);
                        if (r.code == 200) {
                            // alert("注册成功");
                            this.stepin++;
                            setToken(r.data.access_token,720);
                            // location.href = '/';
                        } else {
                            // this.refreshCode();
                            this.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
                            alert(r.msg);
                        }
                    })
                }
            }
        }
        //进入用户中心
        enterCenter(){
            if (this.userType == 1) {//个人
                location.href = '/';
            } else if (this.userType == 2) {//企业
                location.href = entCenterUrl;
            }

        }
        //打开图片滑块验证码弹窗
        openImageVarify(){
            var flag = this.validator("form-zcxx",["checkCode"]);
            if(!flag){
                return;
            }
            this.isImageShow = true;
            this.update();
        }
        constructor(props) {
            super(props);
            this.checkImageVarify = this.checkImageVarify.bind(this); // 绑定 this
        }

        //图片滑块验证码校验通过
        checkImageVarify(imgData){
            setTimeout(() => {
                this.isImageShow = false;
                this.update();
            }, 300)
            this.imgData = imgData;
            this.update();
            this.getVerify();//发送验证码
        }

        render() {
            return (
                <div class="mainbox">
                    <div className={this.loading ? 'page-loading' : 'disnone'}><label>{this.loadingText}</label></div>
                    <div class="header">
                        <div class="regist-box regist-head">
                            <div class="regist-top regist-top-left">
                                <a href="http://120.46.204.66:8090/Home"><span class="regist-logo"></span></a>
                                {/* <span class="white22">账号注册</span> */}
                            </div>
                            <p class="noaccount">账号注册
                            </p>
                        </div>
                    </div>
                    <div class="regist-box regist-box-main">
                        <div class="regist-step-box">
                            <div class="bgline"></div>
                            <div class="bgline-active" style={'width:' + (this.stepin * 22.7) + 'rem'}></div>
                            <ul>
                                <li className={this.stepin >= 1 ? 'active-step' : ''}><span>选择用户类型</span></li>
                                <li className={this.stepin >= 2 ? 'active-step' : ''}><span>填写{this.userType == 1 ? '个人' : (this.userType == 2 ? '企业' : '')}资料</span></li>
                                <li className={this.stepin >= 3 ? 'active-step' : ''}><span>填写注册信息</span></li>
                                <li className={this.stepin >= 4 ? 'active-step' : ''}><span>注册成功</span></li>
                            </ul>
                        </div>

                        <div className={this.stepin == 1 ? 'regist-cont' : 'disnone'}>
                            <ul class="regist-user-type">
                                <li className={this.userType == 2 ? 'type2 typein' : 'type2'} onClick={evt => {
                                    this.changeUser(evt, 2)
                                }} >
                                    <a className={'a-checkbox'}>我是企业用户</a>
                                </li>
                                <li className={this.userType == 1 ? 'type1 typein' : 'type1'} onClick={evt => {
                                    this.changeUser(evt, 1)
                                }} >
                                    <a className={'a-checkbox'}>我是个人用户</a>
                                </li>
                            </ul>
                            <div class="step-btn" style="margin-bottom:9rem;">
                                <button type="button" class="btn-next" onClick={evt => { this.nextStep(evt, 2) }} >下一步</button>
                                <button type="button" className="btn-reset" onClick={evt => { this.resetForm(evt) }}>重置</button>
                            </div>

                        </div>


                        <div className={this.stepin == 2 && this.userType == 2 ? 'form-qyzl' : 'disnone'}>
                            <form>
                                <div className="info-box">
                                    <i className="info-icon"></i>
                                    <div className="info-title info-title-gsxx"></div>
                                    <div className="fullrow">
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>公司名称：</span>
                                            <input type="text" className="form-input required" id="entName"
                                                   o-model="registObj.entName"
                                                   placeholder={'请填写公司全称'}
                                                   name="entName" data-msg="请输入公司名称" data-reg={entNameValidator}
                                                   data-tip="请输入正确格式的公司名称（不含空格）" onBlur={evt => {
                                                this.checkCompanyReg()
                                            }} maxlength="100"/>
                                            <span className="tip">【格式要求：需与营业执照一致, 且至少6个字（不含空格）】</span>
                                        </div>
                                        {/*需求要求去掉所有非必填项（除营业执照） --update by sxm 20240312*/}
                                        {/*<div className="col12">*/}
                                        {/*    <span className="form-label">公司英文名称：</span>*/}
                                        {/*    <input type="text" className="form-input" o-model="registObj.entEnName"*/}
                                        {/*           name="entEnName" data-msg="只能输入英文" data-reg={english} data-tip="请输入英文" />*/}
                                        {/*</div>*/}
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>统一代码：</span>
                                            <input type="text" className="form-input required" id="creditCode" o-model="registObj.creditCode" data-reg={creditCodeValidator}
                                                   name="creditCode" data-msg="请输入统一代码" data-tip="请输入正确格式的统一代码" onBlur={evt => { this.checkCreditCodeReg() }} />
                                            <span className="tip">【格式要求：统一代码由18位数字或英文字母组成】</span>
                                        </div>
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>企业性质：</span>
                                            <select name="enterpriseProperty" placeholder="选择企业性质" className="form-select required" o-model="registObj.enterpriseProperty" data-msg="请选择企业性质">
                                                <option value="">请选择</option>
                                                <option value="01">国有</option>
                                                <option value="02">民营</option>
                                                <option value="03">中外合资</option>
                                                <option value="04">外商独资</option>
                                                <option value="05">其他</option>
                                            </select>
                                        </div>
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>注册资金（万元）：</span>
                                            <input type="text" className="form-input required" o-model="registObj.regCapital"
                                                   name="regCapital" data-msg="请输入注册资金" data-reg={numberValidator} data-tip="请输入正确数值格式的注册资金" maxlength="24" />
                                        </div>
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>成立日期：</span>
                                            <input type="date" datetimeformat="yyyy-MM-dd" name="foundDate" o-model="registObj.foundDate" onKeydown={(evt) => {evt.preventDefault();}} className="form-input required" placeholder="成立日期" data-msg="请选择成立日期" />
                                        </div>
                                    </div>
                                    <div className="fullRow">
                                        <div className="col24">
                                            <span className="form-label"><i className="red">*</i>企业类型：</span>
                                            <div className="required" name="enterpriseTypes" data-msg="请选择您的企业性质">
                                                {this.enterpriseTypeList.map(item => {
                                                    return (
                                                        <label><input type="checkbox" name="enterpriseTypes" value={item.dictValue} onClick={(evt) => { this.checkboxClickDict(evt, 'registObj', 'enterpriseTypes', item, '') }} />{item.dictLabel}</label>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className={this.subQylx.type1.checked ? 'col24' : 'disnone'}>
                                            <table className="optionsTable">
                                                <tr>
                                                    <td className="form-table-title" width="8%">生产制造企业</td>
                                                    <td>
                                                        <p><span className="form-label" style="float:none"><i
                                                            className="red">*</i>主营产品：</span>
                                                        </p>
                                                        <div className="required" name="mainProductArr" data-msg="请选择您的主营产品">
                                                            {this.subQylx.type1.list.map(item => {
                                                                return (
                                                                    <label><input type="checkbox" name="enterpriseTypes" value={item.dictValue} onClick={(evt) => { this.checkboxClickDict(evt, 'registObj', 'mainProductArr', item, 'mainProduct') }} />{item.dictLabel}</label>
                                                                )
                                                            })}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className={this.subQylx.type2.checked ? 'col24' : 'disnone'}>
                                            <table className="optionsTable">
                                                <tr>
                                                    <td className="form-table-title" width="8%">系统集成企业</td>
                                                    <td>
                                                        <p>
                                                            <span className="form-label" style="float:none"><i className="red">*</i>应用领域：</span>
                                                        </p>
                                                        <div className="required" name="applicationAreaArr" data-msg="请选择您的应用领域">
                                                            {this.subQylx.type2.list.map(item => {
                                                                return (
                                                                    <label><input type="checkbox" name="applicationAreaArr" value={item.dictValue}
                                                                                  onClick={(evt) => {
                                                                                      this.checkboxClickDict(evt, 'registObj', 'applicationAreaArr',
                                                                                          item, 'applicationArea')
                                                                                  }} />{item.dictLabel}</label>
                                                                )
                                                            })}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>

                                        </div>
                                        <div className={this.subQylx.type3.checked ? 'col24' : 'disnone'}>
                                            <table className="optionsTable">
                                                <tr>
                                                    <td className="form-table-title" width="8%">运营服务企业</td>
                                                    <td>
                                                        <p>
                                                            <span className="form-label" style="float:none"><i className="red">*</i>服务领域：</span>
                                                        </p>
                                                        <div className="required" name="serviceAreaArr" data-msg="请选择您的服务领域">
                                                            {this.subQylx.type2.list.map(item => {
                                                                return (
                                                                    <label><input type="checkbox" name="enterpriseTypes" value={item.dictValue} onClick={(evt) => { this.checkboxClickDict(evt, 'registObj', 'serviceAreaArr', item, 'serviceArea') }} />{item.dictLabel}</label>
                                                                )
                                                            })}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className={this.subQylx.type4.checked ? 'col24' : 'disnone'}>
                                            <table className="optionsTable">
                                                <tr>
                                                    <td className="form-table-title" width="8%">其他</td>
                                                    <td>
                                                        <span className="form-label" style="line-height: 35px;"><i className="red">*</i>其他：</span>
                                                        <div className="required" name="otherEnterpriseTypeArr" data-msg="请选择您的业务方向">
                                                            {this.subQylx.type4.list.map(item => {
                                                                return (
                                                                    <label><input type="checkbox" name="enterpriseTypes" value={item.dictValue} onClick={(evt) => { this.checkboxClickDict(evt, 'registObj', 'otherEnterpriseTypeArr', item, 'otherEnterpriseType') }} />{item.dictLabel}</label>
                                                                )
                                                            })}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>


                                    </div>
                                    <div className="fullrow">
                                        {/*<div className="col24">*/}
                                        {/*    <span className="form-label"><i className="red">*</i>公司注册地址：</span>*/}
                                        {/*    <input type="text" className="form-input required" o-model="registObj.regAddress"*/}
                                        {/*           name="regAddress" data-msg="请输入公司注册地址" />*/}
                                        {/*</div>*/}
                                        <div className="col24">
                                            <span className="form-label"><i className="red">*</i>企业简介：</span>
                                            <textarea className="form-input required" name="entProfile" data-msg="请输入企业简介" style="height:auto;line-height: 3.5rem;min-height: 15rem;" data-reg={entProfileValidator}
                                                     data-tip="请输入800字（包含标点符号、换行及空格）以内的企业简介"
                                                      onChange={evt => {
                                                this.changeTextarea(evt,"registObj","entProfile")
                                            }}></textarea>
                                            <span className="tip">【格式要求：请输入800字（包含标点符号、换行及空格）以内的内容】</span>
                                        </div>
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>法人代表：</span>
                                            <input type="text" className="form-input required" o-model="registObj.legalPerson"
                                                   name="legalPerson" data-msg="请输入法人代表" maxlength="50" />
                                        </div>
                                        {/*<div className="col12">*/}
                                        {/*  <span className="form-label"><i className="red">*</i>法人电子邮件：</span>*/}
                                        {/*  <input type="text" className="form-input required" o-model="registObj.email"*/}
                                        {/*         name="email" data-msg="请输入法人电子邮件" data-reg={emailValidator}*/}
                                        {/*         data-tip="请输入正确格式的邮箱" />*/}
                                        {/*</div>*/}
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>法人联系电话：</span>
                                            <input type="text" className="form-input required" o-model="registObj.legalPersonPhone"
                                                   name="legalPersonPhone" data-msg="请输入法人联系电话" maxlength="30" />
                                            {/*<span className="tip">【格式要求：86-10-63440401,注意横线要在英文状态输入】</span>*/}
                                        </div>
                                        {/*<div className="col12">*/}
                                        {/*  <span className="form-label">法人手机号：</span>*/}
                                        {/*  <input type="text" className="form-input" o-model="registObj.legalPersonMobile"*/}
                                        {/*         name="legalPersonMobile" />*/}
                                        {/*  /!* <span className="tip">【格式要求：010-26737123-888,注意横线要在英文状态输入】</span> *!/*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="fullrow">
                                        {/*需求要求去掉所有非必填项（除营业执照） --update by sxm 20240312*/}
                                        {/*<div className="col12">*/}
                                        {/*    <span className="form-label">企业微信公众号：</span>*/}
                                        {/*    <input type="text" className="form-input " o-model="registObj.wechat" name="wechat" data-msg="请输入企业微信公众号" />*/}
                                        {/*</div>*/}
                                        {/*<div className="col12">*/}
                                        {/*    <span className="form-label">网址：</span>*/}
                                        {/*    <input type="text" className="form-input" o-model="registObj.url" name="url" data-msg="请输入网址" />*/}
                                        {/*</div>*/}
                                        <div className="col24">
                                            <span className="form-label"><i className="red">*</i>国家或地区：</span>
                                            <div className="required" data-msg="请选择国家或地区" name="countryCode">
                                                <label htmlFor="">
                                                    <input type="radio" onClick={evt => {
                                                        this.changeCountry(evt)
                                                    }} value="156" name="country" o-model="registObj.countryCode" />中国大陆
                                                </label>
                                                <label htmlFor="">
                                                    <input type="radio" onClick={evt => {
                                                        this.changeCountry(evt)
                                                    }} value="344" name="country" o-model="registObj.countryCode" />中国香港
                                                </label>
                                                <label htmlFor="">
                                                    <input type="radio" onClick={evt => {
                                                        this.changeCountry(evt)
                                                    }} value="446" name="country" o-model="registObj.countryCode" />中国澳门
                                                </label>
                                                <label htmlFor="">
                                                    <input type="radio" onClick={evt => {
                                                        this.changeCountry(evt)
                                                    }} value="158" name="country" o-model="registObj.countryCode" />中国台湾
                                                </label>
                                                <label htmlFor="">
                                                    <input type="radio" onClick={evt => {
                                                        this.changeCountry(evt)
                                                    }} value="5" name="country" o-model="registObj.countryCode" />其他
                                                </label>
                                                <span className={this.registObj.countryCode != 5 ? 'hide' : 'countries col24'}>
                        <select name="" placeholder="选择其他国家" className="form-select" o-model="registObj.otherCountry">
                          {this.countries.map(item => {
                              return (
                                  <option value={item.countryCode}>{item.countryName}</option>
                              )
                          })}
                        </select>
                      </span>
                                            </div>
                                        </div>

                                        <div className={this.registObj.countryCode != 156 ? 'hide' : 'countries col24'}>
                                            <span className="form-label"><i className="red">*</i>省份或地区：</span>
                                            <div>
                                                <div className="col12">
                                                    <select name="" className="form-select" onChange={evt => {
                                                        this.getCitys(evt)
                                                    }} placeholder="选择省份或地区" o-model="registObj.province">
                                                        {this.provinces.map(item => {
                                                            return (
                                                                <option value={item.cityCode}>{item.cityName}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="col12">
                                                    <select name="" className="form-select" placeholder="选择城市" id="" o-model="registObj.city">
                                                        {this.cities.map(item => {
                                                            return (
                                                                <option value={item.cityCode}>{item.cityName}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col24">
                                            <span className="form-label"><i className="red">*</i>公司注册地址：</span>
                                            <input type="text" className="form-input required" o-model="registObj.address" name="address"
                                                   data-msg="请输入公司注册地址" maxlength="255" />
                                        </div>
                                        {/*需求要求去掉所有非必填项（除营业执照） --update by sxm 20240312*/}
                                        {/*<div className="col12">*/}
                                        {/*    <span className="form-label">邮政编码：</span>*/}
                                        {/*    <input type="text" className="form-input" o-model="registObj.postcode"*/}
                                        {/*           name="postcode" data-msg="请输入邮政编码" />*/}
                                        {/*</div>*/}
                                        <div className="col24">
                                            <span className="form-label">营业执照：</span>
                                            <div id="uploadArea" name="licenseCopy" data-msg="请上传营业执照">
                                                <input style="display:none" type="file" name="file" id="btn-file" onChange={evt => {
                                                    this.addFile()
                                                }}>上传</input>
                                            </div>
                                            <img id="licenseBook" />
                                            <span className='upload-btn' onClick={evt => {
                                                this.uploadFile()
                                            }}>上传</span>
                                            <span className="tip">文件大小 ：500K 以内　　　　格式 ：jpg,png</span>
                                        </div>
                                        {/*<div className="col24">*/}
                                        {/*    <span className="form-label">Logo：</span>*/}
                                        {/*    <div id="uploadLogoArea" name="logo" data-msg="请上传Logo">*/}
                                        {/*        <input style="display:none" type="file" name="logofile" id="btn-logo-file" onChange={evt => {*/}
                                        {/*            this.addLogoFile()*/}
                                        {/*        }}>上传</input>*/}
                                        {/*    </div>*/}
                                        {/*    <img id="logoFile" />*/}
                                        {/*    <span className='upload-btn' onClick={evt => {*/}
                                        {/*        this.uploadLogoFile()*/}
                                        {/*    }}>上传</span>*/}
                                        {/*    <span className="tip">文件大小 ：300K 以内　　　　格式 ：jpg,png　</span>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="info-box">
                                    <i className="info-icon"></i>
                                    <div className="info-title info-title-lxrxx"></div>
                                    <div className="fullrow">
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>联系人姓名：</span>
                                            <input type="text" className="form-input required" o-model="registObj.contact" name="contact" data-msg="请输入联系人姓名" maxlength="50" />
                                        </div>
                                        {/*<div className="col12"> 20240111屏蔽此字段 -update by sxm*/}
                                        {/*    <span className="form-label"><i className="red">*</i>联系人性别：</span>*/}
                                        {/*    <div className="required" name="contactSex" data-msg="请选择联系人性别">*/}
                                        {/*        <label htmlFor=""><input type="radio" value="1" name="contactSex" o-model="registObj.contactSex"></input>先生</label>*/}
                                        {/*        <label htmlFor=""><input type="radio" value="0" name="contactSex" o-model="registObj.contactSex"></input>女士</label>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>联系人地址：</span>
                                            <input type="text" className="form-input required" o-model="registObj.contactAddress" name="contactAddress" data-msg="请输入联系人地址" maxlength="255" />
                                        </div>
                                        {/*需求要求去掉所有非必填项（除营业执照） --update by sxm 20240312*/}
                                        {/*<div className="col12">*/}
                                        {/*    <span className="form-label">联系人邮编：</span>*/}
                                        {/*    <input type="text" className="form-input" o-model="registObj.contactPostcode" name="contactPostcode" data-msg="请输入联系人邮编" />*/}
                                        {/*</div>*/}
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>联系人联系电话：</span>
                                            <input type="text" className="form-input required" o-model="registObj.contactPhone" name="contactPhone" data-msg="请输入联系人联系电话" maxlength="30" />
                                            {/*<span className="tip">【格式要求：86-10-63440401,注意横线要在英文状态输入】</span>*/}
                                        </div>
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>联系人手机：</span>
                                            <input type="text" className="form-input required" o-model="registObj.contactMobile" name="contactMobile" data-msg="请输入联系人手机" data-reg={phoneValidator} data-tip="请输入正确格式的联系人手机" />
                                        </div>
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>联系人电子邮件：</span>
                                            <input type="text" className="form-input required" o-model="registObj.contactEmail" name="contactEmail" data-reg={emailValidator} data-msg="请输入联系人电子邮件" data-tip="请输入正确格式的电子邮件" maxlength="255" />
                                        </div>
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>联系人职务：</span>
                                            <input type="text" className="form-input required" o-model="registObj.contactPosition" name="contactPosition" data-msg="请输入联系人职务" maxlength="255" />
                                        </div>
                                        <div className="col12">
                                            <span className="form-label"><i className="red">*</i>所在部门：</span>
                                            <input type="text" className="form-input required" o-model="registObj.contactDept" name="contactDept" data-msg="请输入所在部门" maxlength="255" />
                                        </div>
                                    </div>
                                </div>
                                {/*需求要求去掉关心领域 --update by sxm 20240312*/}
                                {/*<div className="info-box">*/}
                                {/*    <i className="info-icon"></i>*/}
                                {/*    <div className="info-title info-title-gxly"></div>*/}
                                {/*    <div className="fullrow">*/}
                                {/*        <div className="col24">*/}
                                {/*            <span className="form-label"><i className="red">*</i>您所关心的产品：</span>*/}
                                {/*            <div className="required" name="interestProduct" data-msg="请选择您关心的产品">*/}
                                {/*                {this.interestProductList.map(item => {*/}
                                {/*                    return (*/}
                                {/*                        <label><input type="checkbox" name="interestProduct" value={item.dictValue}*/}
                                {/*                                      onClick={(evt) => { this.checkboxClickDict(evt, 'registObj', 'interestProduct', item, 'focusProduct') }} />{item.dictLabel}</label>*/}
                                {/*                    )*/}
                                {/*                })}*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="col24">*/}
                                {/*            <span className="form-label"><i className="red">*</i>您所关心的领域：</span>*/}
                                {/*            <div className="required" name="focusArea" data-msg="请选择您关心的领域">*/}
                                {/*                {this.focusAreaList.map(item => {*/}
                                {/*                    return (*/}
                                {/*                        <label><input type="checkbox" name="focusArea" value={item.dictValue} onClick={(evt) => { this.checkboxClickDict(evt, 'registObj', 'focusArea', item, 'focusArea') }} />{item.dictLabel}</label>*/}
                                {/*                    )*/}
                                {/*                })}*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="step-btn">
                                    <button type="button" className="btn-last mr30" onClick={evt => { this.lastStep(evt, 1) }}>上一步
                                    </button>
                                    <button type="button" className="btn-next" onClick={evt => { this.nextStep(evt, 3) }}>下一步
                                    </button>
                                    <button type="reset" className="btn-reset" onClick={evt => { this.resetForm(evt) }}>重置</button>
                                </div>
                            </form>
                        </div>
                        <div className={this.stepin == 2 && this.userType == 1 ? 'form-grzl' : 'disnone'}>
                            <form>
                                <div className="fullrow">
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>国家或地区：</span>
                                        <div className="required" data-msg="请选择国家或地区" name="countryCode">
                                            <label htmlFor="">
                                                <input type="radio" onClick={evt => {
                                                    this.changeCountry(evt)
                                                }} value="156" name="country" o-model="registObjGr.countryCode" />中国大陆
                                            </label>
                                            <label htmlFor="">
                                                <input type="radio" onClick={evt => {
                                                    this.changeCountry(evt)
                                                }} value="344" name="country" o-model="registObjGr.countryCode" />中国香港
                                            </label>
                                            <label htmlFor="">
                                                <input type="radio" onClick={evt => {
                                                    this.changeCountry(evt)
                                                }} value="446" name="country" o-model="registObjGr.countryCode" />中国澳门
                                            </label>
                                            <label htmlFor="">
                                                <input type="radio" onClick={evt => {
                                                    this.changeCountry(evt)
                                                }} value="158" name="country" o-model="registObjGr.countryCode" />中国台湾
                                            </label>
                                            <label htmlFor="">
                                                <input type="radio" onClick={evt => {
                                                    this.changeCountry(evt)
                                                }} value="5" name="country" o-model="registObjGr.countryCode" />其他
                                            </label>
                                            <span className={this.registObjGr.countryCode != 5 ? 'hide' : 'countries col24'}>
                      <select name="" placeholder="选择其他国家" className="form-select" o-model="registObjGr.otherCountry">
                        {this.countries.map(item => {
                            return (
                                <option value={item.countryCode}>{item.countryName}</option>
                            )
                        })}
                      </select>
                    </span>
                                        </div>
                                    </div>

                                    <div className={this.registObjGr.countryCode != 156 ? 'hide' : 'countries col24'}>
                                        <span className="form-label"><i className="red">*</i>省份或地区：</span>
                                        <div>
                                            <div className="col12">
                                                <select name="" className="form-select" onChange={evt => {
                                                    this.getCitys(evt)
                                                }} placeholder="选择省份或地区" o-model="registObjGr.province">
                                                    {this.provinces.map(item => {
                                                        return (
                                                            <option value={item.cityCode}>{item.cityName}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className="col12">
                                                <select name="" className="form-select" placeholder="选择城市" id="" o-model="registObjGr.city">
                                                    {this.cities.map(item => {
                                                        return (
                                                            <option value={item.cityCode}>{item.cityName}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>通讯地址：</span>
                                        <input type="text" className="form-input required" o-model="registObjGr.address" name="address"
                                               data-msg="请输入通讯地址" maxlength="100" />
                                    </div>
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>您所关心的产品：</span>
                                        <div className="required" name="interestProduct" data-msg="请选择您关心的产品">
                                            {this.interestProductList.map(item => {
                                                return (
                                                    <label><input type="checkbox" name="interestProduct" value={item.dictValue} onClick={(evt) => { this.checkboxClickDict(evt, 'registObjGr', 'interestProduct', item, 'focusProduct') }} />{item.dictLabel}</label>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="col24">
                                        <span className="form-label"><i className="red">*</i>您所关心的领域：</span>
                                        <div className="required" name="focusArea" data-msg="请选择您关心的领域">
                                            {this.focusAreaList.map(item => {
                                                return (
                                                    <label><input type="checkbox" name="focusArea" value={item.dictValue} onClick={(evt) => { this.checkboxClickDict(evt, 'registObjGr', 'focusArea', item, 'focusArea') }} />{item.dictLabel}</label>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="step-btn">
                                    <button type="button" className="btn-last mr30" onClick={evt => {
                                        this.lastStep(evt, 1)
                                    }}>上一步
                                    </button>
                                    <button type="button" className="btn-next" onClick={evt => {
                                        this.nextStep(evt, 3)
                                    }}>下一步
                                    </button>
                                    <button type="reset" className="btn-reset" onClick={evt => { this.resetForm(evt) }}>重置</button>

                                </div>
                            </form>
                        </div>
                        <div className={this.stepin == 3 ? 'form-zcxx' : 'disnone'}>
                            <div className="fullRow">
                                <div className="col12">
                                    <span className="form-label"><i className="red">*</i>用户名：</span>
                                    <input type="text" className="form-input required" o-model="registObjCommon.username" name="username"
                                           data-msg="请输入用户名" maxlength="128" />
                                </div>
                                <div className="col12">
                                    <span className="form-label"><i className="red">*</i>安全邮箱：</span>
                                    <input type="text" className="form-input required" o-model="registObjCommon.email" name="email"
                                           data-msg="请输入安全邮箱" data-reg={emailValidator} data-tip="请输入正确格式的邮箱" maxlength="50" />
                                </div>
                                <div className="col12">
                                    <span className="form-label"><i className="red">*</i>密码：</span>
                                    <input type="password" className="form-input required" o-model="registObjCommon.password" name="password"
                                           data-msg="请输入密码" data-reg={passValidator} data-tip="密码至少包含数字、英文字母、特殊字符 两种及以上（不包含中文及空格），长度为8-20位" maxlength="20" />
                                    <span className="tip">【格式要求：密码至少包含数字、英文字母、特殊字符 两种及以上（不包含中文及空格），长度为8-20位】</span>
                                </div>
                                <div className="col12">
                                    <span className="form-label"><i className="red">*</i>确认密码：</span>
                                    <input type="password" className="form-input required" o-model="registObjCommon.confirmPassword"
                                           name="confirmPassword" data-msg="请输入确认密码" data-reg={passValidator}
                                           data-tip="密码至少包含数字、英文字母、特殊字符 两种及以上（不包含中文及空格），长度为8-20位" maxlength="20" />
                                    <span className="tip">【格式要求：密码至少包含数字、英文字母、特殊字符 两种及以上（不包含中文及空格），长度为8-20位】</span>
                                </div>
                                <div className="col12">
                                    <span className="form-label"><i className="red">*</i>手机号：</span>
                                    <input type="text" className="form-input required" o-model="registObjCommon.phoneNumber" name="phoneNumber"
                                           data-msg="请输入手机号" data-reg={phoneValidator} data-tip="手机号格式不正确" />
                                </div>
                                {/*<div className="col12">*/}
                                {/*    <span className="form-label"><i className="red">*</i>图片验证码：</span>*/}
                                {/*    <div style="position:relative">*/}
                                        {/*<input type="text" className="form-input required" o-model="registObjCommon.code" name="code"*/}
                                        {/*       data-msg="请输入图片验证码" />*/}
                                        {/*<img height="36" ref={this.codeRef} name="codeUrl" src="" onClick={evt => {*/}
                                        {/*    this.refreshCode();*/}
                                        {/*}} className="login-code-img codeUrl" />*/}
                                {/*        <button className='pbr'>获取滑动验证码</button>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col12">
                                    <span className="form-label"><i className="red">*</i>短信验证码：</span>
                                    <div style="position:relative">
                                        <input type="text" className="form-input required" o-model="registObjCommon.checkCode" name="checkCode"
                                               data-msg="请输入短信验证码" />
                                        <button disabled={this.disBtn} onClick={evt => {
                                            this.openImageVarify()
                                        }} className='pbr'>{this.tip}</button>
                                        <oi-dialog-box settings={{show: this.isImageShow, width:310, height: 265}}>
                                            <image-verify ref={this.imgVerifyRef} mess='hello ' checkImageVarify={this.checkImageVarify} />
                                        </oi-dialog-box>
                                    </div>
                                </div>
                                <div className="col24">
                                    <label><input type="checkbox" o-model="agree" value="1" />同意“<a href="javascript:void(0)" onClick={evt => {
                                        this.showServiceDialog()
                                    }}>服务条款</a>”和“<a href="javascript:void(0)" onClick={evt => {
                                        this.showRuleDialog()
                                    }}>服务细则</a>”</label>
                                </div>
                            </div>
                            <div className="step-btn">
                                <button className="btn-last mr30" onClick={evt => {
                                    this.lastStep(evt, 2)
                                }}>上一步
                                </button>
                                <button className="btn-next" onClick={evt => {
                                    this.submit(evt)
                                }}>注册
                                </button>
                                <button type="reset" className="btn-reset" onClick={evt => { this.resetForm(evt) }}>重置</button>
                            </div>
                        </div>
                        <div className={this.stepin == 4 ? '' : 'disnone'}>
                            <div class="regist-success">
                                <p>恭喜您，注册成功！</p>
                            </div>
                            <div className="step-btn">
                                <button className="btn-next" onClick={evt => {
                                    this.enterCenter(evt)
                                }}>进入用户中心
                                </button>
                            </div>
                        </div>
                    </div >
                    <div className={this.stepin == 1 ? 'regist-footer positionFixed' : 'regist-footer'}>
                        <div class="regist-box" style="text-align: center;height:auto;min-height:8rem;">
                            版权所有：中国安全防范产品行业协会 京ICP备07503664号-4   京公网安备 11010802034822号
                        </div>
                    </div>
                    <oi-dialog-box settings={this.serviceSetting}>
                        <div className="oi-row">
                            <h3 className="info-title">{this.serviceArticle.title}</h3>
                            <p className="info-content" unsafeHTML={this.serviceArticle.content}></p>
                        </div>
                        <div slot="footer" className="text-c">
                            <button className="btn-dialog-submit m3t" onClick={evt => {
                                this.updateService()
                            }}>确 定</button>
                        </div>
                    </oi-dialog-box>
                    <oi-dialog-box settings={this.ruleSetting} >
                        <div className="oi-row">
                            <h3 className="info-title">{this.ruleArticle.title}</h3>
                            <p className="info-content" unsafeHTML={this.ruleArticle.content}></p>
                        </div>
                        <div slot="footer" className="text-c">
                            <button className="btn-dialog-submit m3t" onClick={evt => {
                                this.updateRule()
                            }}>确 定</button>
                        </div>
                    </oi-dialog-box>
                </div >
            );
        }
    }
)
