import {setToken} from "../../token";

const { h, define, Component, classNames } = omii;
import "../../../es-lib/omii/latest/ui/omii-ui.js"
import css from "./index.scss";

export default class extends Component {
    static css = css;

    installed() {
       this.initVerify(this.container);
    }

    //传值给父组件
    setResult(res){
        this.props.checkImageVarify(res)
    }
    //刷新
    exportRefresh(){
        this.container.querySelector('.refreshIcon').onclick();
    }
    initVerify(container) {
        var that = this;
        var canvas = container.querySelector('#canvas');
        var block = container.querySelector('#block');
        var refresh = container.querySelector('.refreshIcon')

        //添加移动事件
        var block_slider = container.querySelector("#block");
        var slider = container.querySelector(".verSliderBlock");
        var slider_mark = container.querySelector("#bar-mask");

        var wbili = 1;//缩放比例
        var defaultWidth = 310;//默认图片宽度
        var x = Math.round(Math.random() * 200) + 10

        var data = {};//图片对象

        initImage()

        function initImage() {
            fetch("/prod-api/manage/check/getImgSwipe", {
            }).then(response => response.json()).then(r => {
                if (r.code == 200) {
                    data = r.data;
                    canvas.src = 'data:image/jpg;base64,' + data.SrcImage;//后台获取图片
                    block.src = 'data:image/jpg;base64,' + data.CutImage;//后台获取图片
                    block.style.top = data.YPosition + 'px';
                    wbili = Math.round(data.SrcImageWidth / defaultWidth * 100) / 100
                }else{
                    console.info("获取图片验证码失败")
                }
            });
        }

        //用于判断当前是否是在按住滑块的情况下
        var yd = false
        var moveX = 0
        var downX = 0

        //鼠标按下
        slider.onmousedown = function (e) {
            downX = e.clientX;
            yd = true

        }

        //鼠标移动事件
        function hadleMousemove(e) {
            if (yd) {
                moveX = e.clientX - downX;
                container.querySelector('#slide').innerHTML = ''
                if (moveX >= 310) {
                    moveX = 310 - 40
                }
                if (moveX > -2) {
                    slider.style.backgroundColor = "#1991FA";
                    slider_mark.style.borderWidth = "1px"
                    slider_mark.style.borderColor = "#1991fa"
                    slider_mark.style.width = moveX + 40 + "px";

                    block_slider.style.left = (310 - 40 - 20) / (310 - 40) * moveX + "px";
                    slider.style.left = moveX + "px";

                }
            }
        }

        //鼠标抬起事件
        function hadleMouseup(e) {
            if (yd) {
                slider.onmousemove = null;
                var blockLeft = (310 - 40 - 20) / (310 - 40) * moveX;
                block_slider.style.left = blockLeft+ "px";
                var imgObj = {
                    // code: data.code,
                    uuid: data.uuid,
                    moveEnd_X: blockLeft,
                    wbili: wbili,
                    XPositionUuid: data.XPositionUuid
                }
                fetch("/prod-api/manage/check/rstImgSwipe", {
                    method: "post",
                    body:JSON.stringify(imgObj),
                    headers: {
                        "Content-type": "application/json"
                    }
                }).then(res => res.json()).then(r => {
                    if (r.code == 200) {
                        if(r.data.success){
                            // alert('验证成功')
                            checkImgResult(true, r.data.code)
                        }else{
                            checkImgResult(false, null)
                        }
                    } else {
                        checkImgResult(false, null)
                    }
                })

                yd = false
            }
        }
        function checkImgResult(flag, code, uuid){
            if(flag){
                slider.style.background = "url(assets/images/verify/success.png)";
                slider.style.backgroundSize = '100%'
                var imgData = {
                    imgCode: code,
                    imgUuid: data.uuid
                }
                that.setResult(imgData)
            }else{
                slider_mark.style.backgroundColor = "#fce1e1"
                slider_mark.style.borderWidth = "1px"
                slider_mark.style.borderColor = "#f57a7a"

                slider.style.backgroundColor = "#f57a7a";
                slider.style.background = "url(assets/images/verify/fail.png)";
                slider.style.backgroundSize = '100%'
                setTimeout(() => {
                    rest();

                }, 1000)
            }
        }

        //鼠标在按住滑块下移动
        slider.onmousemove = function (e) {
            hadleMousemove(e)
        }
        //鼠标在滑块下抬起
        slider.onmouseup = function (e) {
            hadleMouseup(e)
        }

        //设置全局的移动事件,当鼠标按下滑块后,移动过程中鼠标可能会移出滑块,这是滑块也会监听鼠标的移动进行相应的移动
        document.addEventListener('mousemove', function (e) {
            hadleMousemove(e)
        })
        document.addEventListener('mouseup', function (e) {
            hadleMouseup(e)
        })

        function rest() {
            initImage()
            container.querySelector('#slide').innerHTML = '向右滑动验证'
            slider.style.backgroundColor = "#fff";
            slider.style.left = "0px"
            slider.style.background = "url(assets/images/verify/right_arrow.png)";
            slider.style.backgroundSize = '100%'
            block_slider.style.left = "0px"

            slider_mark.style.width = "0px"
            slider_mark.style.backgroundColor = "#d1e9fe"
            slider_mark.style.borderWidth = "0px"
            slider_mark.style.borderColor = "#d1e9fe"
        }
        //刷新
        refresh.onclick = function(){
            rest()
        }
    }

    render() {

        return (
            <>
                <div ref={e => (this.container = e)} className="container">
                    <img width="310" height="155" id="canvas"/>
                    <img width="50" height="50" id="block"/>
                    <div className="refreshIcon"></div>
                    <div className="bar">
                        <div id="bar-mask">
                            <div className="verSliderBlock"></div>
                        </div>
                        <span id="slide">向右滑动验证</span>
                    </div>
                </div>
            </>
        )
    }

}
