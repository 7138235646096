import { h, tag, render, define, route, Component, uniqueTag } from "/es-lib/omii/latest/omii.js";
import login from "./login";
import homePage from "./home";
import security from "./security";
import "./register";
import "./forget";
import "./bindPhone";
import "./auth";
import "./mainAccount";
import { getToken } from "./token";
import Cookies from 'js-cookie'
import { Query } from 'dynamic-query'

// let q = new Query()
// q.froms.push('cms_tab')
// q.fields.push('name', 'tab_id')
// q.rows = 100
// q.where('name').notIn('最新公告', '首页')

// fetch('/prod-api/manage/other/query', {
//   headers: {
//     'content-type': 'application/json'
//   },
//   method: "POST",
//   body: JSON.stringify(q)
// })

define("af-login", login);

render(<link href="./css/reboot.css" rel="stylesheet"></link>, "head")

let page = null;

define("af-index", class extends Component {
  isSingle = false;
  whiteUrl = ['https://award.afdata.org.cn/','http://localhost:8082/'];//可直接跳入的白名单地址（外部链接）
  get currPage() {

  }
  install() {
    var paramArr = location.href.substr(location.href.indexOf("?") + 1).split("&");
    let obj = {};
    for (let i = 0; i < paramArr.length; i++) {
      let name = paramArr[i].substr(0, paramArr[i].indexOf("="));
      let value = paramArr[i].substr(paramArr[i].indexOf("=") + 1);
      if (name) {
        obj[name] = value;
      }
    }
    if (obj.redirectUrl) {
      sessionStorage.setItem("centerRedirect", obj.redirectUrl);
    }
    if(location.href.indexOf("MainAccount")>-1 || location.href.indexOf("mainAccount")>-1){
      this.isSingle =  true;
    }
    if(location.href.indexOf("BindPhone")>-1){//移动端页面在ios首次打开无法显示的问题，在此处单独绑定
      this.isSingle =  true;
      page = <af-bind-phone></af-bind-phone>
    }
    if (security.isLogined) {
      if (obj.redirectUrl) {
        sessionStorage.removeItem("centerRedirect");
        if (Cookies.get("Ent-Token")) {
          if (obj.redirectUrl.indexOf("?") >= 0) {
            location.href = obj.redirectUrl + "&token=" + getToken();
          } else {
            location.href = obj.redirectUrl + "?token=" + getToken();
          }
        } else {
          location.href = obj.redirectUrl;
        }
      }
    }
    route("/Auth", () => {
      this.isSingle = true;
      page = <af-auth></af-auth>
      this.updateSelf();
    })
    route("/Register", () => {
      this.isSingle = true;
      page = <af-register></af-register>
      this.updateSelf();
    })
    route("/Forget", () => {
      this.isSingle = true;
      page = <af-forget></af-forget>
      this.updateSelf();
    })
    route("/BindPhone", () => {
      this.isSingle = true;
      page = <af-bind-phone></af-bind-phone>
      this.updateSelf();
    })
    route("/MainAccount", () => {
      this.isSingle = true;
      page = <af-main-account></af-main-account>
      this.updateSelf();
    })
    route("/", () => {
      this.isSingle = false;
      page = null;
      this.updateSelf();
    })

  }

  async jumpTo(evt){
    let userInfo = JSON.parse(sessionStorage.getItem("expertUser"));
    if(userInfo==undefined || userInfo==null){
      userInfo = await security.getUserInfo();
    }
    if (userInfo.code == 200) {
      sessionStorage.setItem("expertUser", JSON.stringify(userInfo))
    }
    let isWhite = false;//url白名单中的地址直接跳入 --add by sxm 20230424
    var rUrl = "";
    if (sessionStorage.getItem("centerRedirect") && sessionStorage.getItem("centerRedirect") != null) {
      rUrl = sessionStorage.getItem("centerRedirect");
      sessionStorage.removeItem("centerRedirect");
      this.whiteUrl.forEach(wUrl=>{
        if(rUrl==wUrl){
          isWhite = true;
        }
      })
    }
    if(isWhite && rUrl){//重定向地址为白名单中地址，直接跳入
      if (Cookies.get("Ent-Token")) {
        if (rUrl.indexOf("?") >= 0) {
          location.href = rUrl + "&token=" + getToken();
        } else {
          location.href = rUrl + "?token=" + getToken();
        }
      } else {
        location.href = rUrl;
      }
    }else {
      //应用中心跳转来的路径，需判断是否有权限直接进入；无权限时返回列表or详情，有权限时直接进入 --add by sxm 20241031
      if (rUrl != undefined && rUrl != null && rUrl != '' && rUrl.indexOf("appJumpin") > -1) {
        var pcappId = "";//需要校验的应用id
        var urlParamStrs = rUrl.split("?");
        if (urlParamStrs.length > 1) {
          var params = urlParamStrs[1].split('&');
          params.forEach(item => {
            var urlParam = item.split('=');
            if (urlParam[0] == "appJumpin") {
              pcappId = urlParam[1];
            }
          })
          fetch("/prod-api/manage/applicationService/checkIsEnter?serviceId=" + pcappId, {
            method: 'get',
            headers: {
              Authorization: `Bearer ${getToken()}`
            }
          }).then(response => response.json())
              .then(r => {
                if (r.code == 200) {
                  if (r.data.isExternalLinks == "1") {//直接跳走
                    console.info("直接打开：",r.data.applicationUrl)
                    window.location.href = r.data.applicationUrl;
                  } else {//门户平台打开
                    console.info("门户平台打开：",portalUrl + "/ApplicationJump?id=" + pcappId)
                    window.location.href = portalUrl + "/ApplicationJump?id=" + pcappId;
                  }
                } else {
                  location.href = rUrl;
                }
              })
        }

      }else {
        if (userInfo.user.userType == "01") {//企业跳到企业服务中心
          if (rUrl != "") {
            // if(rUrl=="https://enterprisecenter.afdata.org.cn/surveyCate/survey"){
            if (rUrl.indexOf("surveyCate/survey") > -1 || rUrl.indexOf("members/yearCheck") > -1 || rUrl.indexOf("exh/innovate") > -1 || rUrl.indexOf("memberApply/form") > -1) {//该链接需直接进入(统计调查、会员年审、创新产品申报、申请会员)--add by sxm 20240304
              location.href = rUrl;
            } else {
              location.href = entCenterUrl;
            }
          } else {
            location.href = entCenterUrl;
          }

        } else {
          if (rUrl != "") {
            if (Cookies.get("Ent-Token")) {
              if (rUrl.indexOf("?") >= 0) {
                location.href = rUrl + "&token=" + getToken();
              } else {
                location.href = rUrl + "?token=" + getToken();
              }
            } else {
              location.href = rUrl;
            }
          } else {
            if (evt.detail == "01") {
              location.href = serviceCenterUrl;
            } else {
              this.update()
            }
          }
        }
      }
    }


  }
  render() {
    let $page = <this.pageName />
    return (
      this.isSingle ? page : (
        security.isLogined ? (
          homePage
        ) : <af-login onLogined={(evt) => {
          this.jumpTo(evt);
        }} />
      )
    )
  }
})

render(<af-index />, "body")
