import Cookies from "js-cookie"
const key = "Ent-Token"
const expireKey = "af-auth-token-expire"
// let Token = sessionStorage.getItem(key)
let Token = Cookies.get(key)
let lastUpdateTime = new Date().getTime()
let expireTime = Number(sessionStorage.getItem(expireKey))
if (expireTime) lastUpdateTime = expireTime
let lastExpiresIn = 720
export function setToken(token, expiresIn) {
    lastExpiresIn = expiresIn ?? 720
    lastUpdateTime = new Date().getTime()
    Token = token
    if (token == null) {
        sessionStorage.removeItem(key)
        sessionStorage.removeItem(expireKey)
    } else {
        sessionStorage.setItem(key, token)
        sessionStorage.setItem(expireKey, lastUpdateTime)
        if (location.host.indexOf("localhost") >= 0||location.host.indexOf("124.70.19.168")>=0) {
            Cookies.set("Ent-Token", token)
            Cookies.set("Expert-Token", token)
            // Cookies.set("Admin-Token", token)
        } else {
            Cookies.set("Ent-Token", token, {
                domain: "afdata.org.cn",
                path: "/"
            })
            // Cookies.set("Admin-Token", token, {
            //     domain: "afdata.org.cn",
            //     path: "/"
            // })
            Cookies.set("Expert-Token", token, {
                domain: "afdata.org.cn",
                path: "/"
            })
        }
    }
    setTimeout(() => {

    }, 720 * 60 * 1000 - 60 * 1000);
}
export function getToken() {
    return Token
}

export function isTokenExpired() {
    return !getToken() || lastUpdateTime + 720 * 60 * 1000 < new Date().getTime()
}

export default {
    setToken, getToken, isTokenExpired, removeToken
}
export function removeToken() {
    sessionStorage.removeItem(key)
    sessionStorage.removeItem(expireKey)
    Cookies.remove("Ent-Token", {
        domain: "afdata.org.cn",
        path: "/"
    })
    // Cookies.remove("Admin-Token", {
    //     domain: "afdata.org.cn",
    //     path: "/"
    // })
    Cookies.remove("Expert-Token", {
        domain: "afdata.org.cn",
        path: "/"
    })
}
