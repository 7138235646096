const { h, define, Component, createRef } = omii;
import "../../es-lib/omii/latest/ui/omii-ui.js"
import css from "../css/home.scss";
import { getToken } from "../token";

export default class extends Component {
    static css = css;
    dataList = [];
    data = {
        total: 0,
        pageSize: 8,
        pageNum: 1
    }
    detailOpinion="";
    pageRef = createRef();
    myCss = `
    .pager .page  {
        font-size:1.4rem!important;
        padding:6px 9px;
        border:solid 1px #f5f5f9;
        margin:0 5px
    }
    .pager .page:hover{
        background:#002040 !important;
    }
    .pager .active{
        background:#002040 !important;
    }
    .pager .jumper,.pager .info{
        font-size:1.4rem!important;
    }
    .pager .jumper .jump-to{
        margin:0 1rem;
    }
    `
    dialogSetting = {
        width: "500px",
        height: "300px",
        show: false,
        title: "详细信息"
    }
    getPagedList(e) {
        let pageNum = e.detail;
        this.data.pageNum = pageNum;
        let expertUser = JSON.parse(sessionStorage.getItem("expertUser"));
        let userId = "";
        if (expertUser) {
            userId = expertUser.user.userId;
        }
        let obj = {
            constraints: [{
                field: "receiveUser",
                operator: "IsEqualTo",
                constraintType: "Where",
                value: userId,
            }],
            fields: ["all"],
            froms: ["userNotice"],
            ordersBy: [{ field: "createTime", aspect: "desc" }]
        };
        fetch(`/prod-api/manage/other/query/paged?pageNum=${pageNum}&pageSize=8`, {
            method: "post",
            body: JSON.stringify(obj),
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                res.rows.map(item => {
                    item.createTime = this.formatDate(item.createTime);
                })
                this.dataList = res.rows;
                this.data.total = res.total;
                this.update();
            })
    }
    getList(e) {
        // let pageNum = e.detail;
        // this.data.pageNum = pageNum;
        let expertUser = JSON.parse(sessionStorage.getItem("expertUser"));
        let userId = "";
        if (expertUser) {
            userId = expertUser.user.userId;
        }
        let obj = {
            constraints: [{
                field: "receiveUser",
                operator: "IsEqualTo",
                constraintType: "Where",
                value: userId
            }],
            fields: ["all"],
            froms: ["userNotice"],
            ordersBy: [{ field: "createTime", aspect: "desc" }]
        };
        fetch("/prod-api/manage/other/query/paged?pageNum=1&pageSize=8", {
            method: "post",
            body: JSON.stringify(obj),
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                if (res.rows) {
                    res.rows.map(item => {
                        item.createTime = this.formatDate(item.createTime);
                    })
                    this.dataList = res.rows;
                    this.data.total = res.total;

                }
                this.update();
            })
        // this.data.pageNum = pageNum;
    }
    formatDate(v) {
        let curDate = new Date(v);
        return curDate.format("yyyy-MM-dd hh:mm:ss");
    }
    install() {
        // for (var i = 0; i < 3; i++) {
        //     this.dataList.push({
        //         title: "标题" + i
        //     })
        // }
        this.getList();
    }
    installed() {
        // let root = this.pageRef.current.attachShadow({ mode: "open" });
        // setTimeout(() => {
        //     root.appendChild(style);
        // }, 5)
    }
    showDialog(content){
        this.dialogSetting.show = true;
        this.detailOpinion=content;
        this.update();
    }
    closeDialog(){
        this.dialogSetting.show=false;
        this.update();
    }
    render() {
        return (
            <>
                <div className="oi-row-full font26">消息中心</div>
                <div className="list-area">
                    {this.dataList.length > 0 ? this.dataList.map((item) => (
                        <div className="notice-item">
                            <a >
                                【{item.businessName}】 {item.title} {item.content!=null?<span className="detail-link" onClick={evt=>{
                                    this.showDialog(item.content)
                                }}>查看详情</span>:''} {item.businessName=="留言咨询"?<span className="detail-link"><a href={expertCenterUrl+"/remark"}>前往回复</a></span>:""}
                            </a>
                            <span>{item.createTime}</span>
                        </div>
                    )) : (
                        <div class="notice-item text-c">暂无数据</div>
                    )}
                    {
                        this.data.total > 0 ? <div className="pager-area">
                            <oi-pager css={this.myCss} total={this.data.total} page-size={this.data.pageSize} page-num={this.data.pageNum} onPaged={evt => this.getPagedList(evt)} />
                        </div> : <div />
                    }


                </div>
                <oi-dialog-box settings={this.dialogSetting}>
                        <div className="oi-row phonerules">
                        {this.detailOpinion}
                        </div>
                    </oi-dialog-box>
            </>
        )
    }
}
