export default `
body {
  background-color: red;
}

.oi-label {
  font-size: 14px;
  display: flex;
  align-items: center;
  float: left;
  color: rgb(96, 98, 102);
  margin: 0.8rem 2rem 0.8rem 0;
  justify-content: flex-start;
}
.oi-label input {
  border: solid 1px #ddd;
  outline: none;
  height: 16px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiRTpcXFByb2plY3RzXFxhbmZhbmdcXGxpbnV4XFx1c2VyLWNlbnRlclxcc3JjXFxjb21wb25lbnRzIiwic291cmNlcyI6WyJsYWJlbHMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJOzs7QUFFSjtFQUNJO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0k7RUFDQTtFQUNBIn0= */`;
