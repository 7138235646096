const { h, uniqueTag, Component, define, apis, createRef, render } = omii;
import security from "./security";
import css from "./css/login.scss";
import { codeController, labelsController } from "./apis";

import { setToken, getToken, isTokenExpired } from "./token"
import imageVerify from './components/imageVerify/index'

define("image-verify", imageVerify)

let images = [];
let telValidator = "^\\d{1,4}-\\d{1,4}-\\d{5,9}(-\\d{1,4})?$";
// let phoneValidator = "^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\\d{8}$";
let phoneValidator = "^\\d{11}$";//调整手机号验证为 只校验11位数字 --update by sxm 20240715
let emailValidator = "^([a-zA-Z]|[0-9])(\\w|\\-)+@([a-zA-Z0-9_-])+\\.([a-zA-Z]{2,4})$";
// let emailValidator = "^([a-zA-Z]|[0-9])(\\w|\\-)+@[a-zA-Z0-9]+\\.([a-zA-Z]{2,4})$";
let passValidator = "(?!^[0-9]+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^A-z0-9]+$)^[^\\s\\u4e00-\\u9fa5]{6,}";
define("af-forget", class extends Component {
  static css = css;
  registObj = {};
  stepin = 1;//当前步骤
  userType = '0';//用户类型 01-企业，02-个人
  tip = "获取验证码";
  disBtn = false;
  loading = false;
  nullTipsList = [];//为空的项
  isImageShow= false;//是否显示图片滑块验证码弹窗
  imgData= {
    imgCode: '',
    imgUuid: ''
  };//图片滑块验证码部分参数
  // phoneReg = /^[1][3,5,4,7,8,9][0-9]{9}$/;
  phoneReg = /^\d{11}$/;//调整手机号验证为 只校验11位数字 --update by sxm 20240715

  codeRef = createRef();
  imgVerifyRef = createRef();

  //初始化
  install() {
    // this.refreshCode();
  }
  //切换用户类型
  changeUser(e, v) {
    this.userType = v;
    this.update();
  }

  //上一步
  lastStep(e, v) {
    this.stepin = v;
    this.update();
  }
  //下一步
  nextStep(e, v) {
    if (v == 2) {//进入步骤2，验证用户类型
      if (this.userType == '0') {
        alert("请选择用户类型！");
        return false;
      }
    }
    this.stepin = v;
    this.update();
  }

  //刷新验证码
  refreshCode() {
    codeController.get().then((r) => {
      this.codeRef.current.src = `data:image/jpg;base64,${r.img}`;
      this.registObj.uuid = r.uuid;
    });
  }
  //表单校验 true-校验通过
  validator(formName) {
    let flag = true;
    this.nullTipsList = [];
    let registObjName = "registObj";

    for (var i = 0; i < this.$$("." + formName + " .required").length; i++) {
      let item = this.$$("." + formName + " .required")[i];
      let name = item.getAttribute("name");
      let nameArr = name.split(".");
      let currentValue = this[registObjName][nameArr[0]];
      if (nameArr.length > 1) {
        currentValue = this[registObjName][nameArr[0]][nameArr[1]];
      }

      if (!currentValue) {
        flag = false;
        let msgNode = item.parentNode.querySelector("span.errorTip");
        if (!msgNode) {
          let span = document.createElement("span");
          span.className = "errorTip";
          span.innerText = item.dataset.msg;
          item.parentNode.appendChild(span);
        }
        this.nullTipsList.push(item.dataset.msg);
      } else {
        let msgNode = item.parentNode.querySelector("span.errorTip");
        let reg = item.dataset.reg;
        if (reg) {
          reg = new RegExp(reg);
          if (!reg.test(currentValue)) {
            flag = false;
            if (msgNode) {
              msgNode.innerText = item.dataset.tip;
            } else {
              let span = document.createElement("span");
              span.className = "errorTip";
              span.innerText = item.dataset.tip;
              item.parentNode.appendChild(span);
            }
            this.nullTipsList.push(item.dataset.tip);
          } else {
            if (msgNode) {
              item.parentNode.removeChild(msgNode)
            }
          }
        } else {
          if (msgNode) {
            item.parentNode.removeChild(msgNode)
          }
        }
      }
    }
    // console.info("验证结果：",flag)
    return flag;
  }
  //检查用户名是否已存在
  checkUsername() {
    let that = this;
    if (this.registObj.username != undefined && this.registObj.username != null && this.registObj.username != '') {
      this.changeLoading(true);
      fetch(`/prod-api/system/user/checkUserNameIsRegist?username=${this.registObj.username}&usertype=` + this.userType, {
        method: 'get',
      })
          .then(response => response.json())
          .then(data => {
            if (data.code == 200) {
              if (!data.data) {
                alert("用户名“" + this.registObj.username + "”不存在！")
                this.$$("#username")[0].value = '';
                this.registObj.username = '';
                this.update();
              }
            } else {
              alert(data.msg)
            }
            this.changeLoading(false);
          })
    }
  }
  //检查手机号是否已存在
  checkPhoneNumber() {
    let that = this;
    if(this.registObj.phonenumber == undefined || this.registObj.phonenumber == null || this.registObj.phonenumber == ''){
      return;
    }
    if (this.phoneReg.test(this.registObj.phonenumber)) {
      let userParam = {
        phonenumber: this.registObj.phonenumber,
        userType: this.userType
      }
      this.changeLoading(true);
      fetch(`/prod-api/system/user/checkPhoneIsRegist`, {
        method: 'post',
        body: JSON.stringify(userParam),
        headers: {
          "Content-type": "application/json"
        }
      })
          .then(response => response.json())
          .then(data => {
            if (data.code == 200) {
              if (!data.data) {
                alert("手机号码“" + this.registObj.phonenumber + "”不存在！")
                this.$$("#phonenumber")[0].value = '';
                this.registObj.phonenumber = '';
                this.update();
              }
            } else {
              alert(data.msg)
            }
            this.changeLoading(false);
          })
    } else {
      alert('请输入正确手机号')
    }
  }
  //获取手机验证码
  getVerify() {
    if(this.registObj.username==undefined || this.registObj.username==''){
      alert('请输入用户名')
      return;
    }
    let num = 60
    //设置循环定时器
    if (this.phoneReg.test(this.registObj.phonenumber)) {
      if(this.registObj.code==''){
        alert('请先通过图片滑块验证')
        return;
      }
      this.disBtn = true
      let that = this
      this.changeLoading(true);
      // fetch(`/prod-api/manage/open/smscode/SendSMSFindPwd?userName=${this.registObj.username}&tel=${this.registObj.phonenumber}&code=${this.registObj.code}&uuid=${this.registObj.uuid}&userType=` + this.userType, {
      fetch(`/prod-api/manage/open/smscode/SendSMSFindPwdHd?userName=${this.registObj.username}&tel=${this.registObj.phonenumber}&code=${this.registObj.code}&uuid=${this.registObj.uuid}&userType=` + this.userType, {
        method: 'get',
      })
          .then(response => response.json())
          .then(data => {
            if (data.code == 200) {
              let timer = setInterval(function () {
                num--
                if (num <= 0) {
                  //清除循环执行定时器
                  clearInterval(timer);
                  //还原
                  num = 60;
                  that.disBtn = false
                  that.tip = "获取验证码";
                } else {
                  that.tip = num + '秒后可重新获取';
                }
                that.update();
              }, 1000)
              that.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
            } else {
              // that.refreshCode();
              that.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
              that.disBtn = false
              that.update();
              alert(data.msg)
            }
            this.changeLoading(false);
      })

    } else {
      alert('请输入正确手机号')
      this.imgVerifyRef.current.exportRefresh();//刷新图片滑块验证码
    }

  }
  //校验手机验证码是否正确
  checkPhoneCode(e, v){
    this.isImageShow = false;
    this.update();
    var flag = this.validator("form-yhxx");
    if (!flag) {
      if (this.nullTipsList != null && this.nullTipsList.length > 0) {
        alert(this.nullTipsList[0]);//返回第一个错误提示
      }
      return false;
    }else{
      //校验短信验证码
      let param = {
        phone: this.registObj.phonenumber,
        phoneCode: this.registObj.phoneCode
      }
      fetch(`/prod-api/system/user/checkPhoneCode?phone=${this.registObj.phonenumber}&phoneCode=${this.registObj.phoneCode}`, {
        method: 'post'
      })
          .then(response => response.json())
          .then(data => {
            if (data.code == 200) {
              this.stepin = v;
              this.update();
            } else {
              alert(data.msg)
            }
          })
    }
  }
  //重置表单
  resetForm(e) {
    if (this.stepin == 1) {//第一步
      this.userType = 0;
    } else if (this.stepin == 2) {//第二步
      this.registObj = {};
    } else if (this.stepin == 3) {//第三步
      this.$$(".form-czmm input.form-input").map(item => item.value = '')
    }
    this.isImageShow = false;
    this.update();
  }

  changeLoading(flag) {
    this.loading = flag;
    this.update();
  }
  //重置密码
  submit() {
    var flag = this.validator("form-czmm");
    console.info("flag:",flag)
    if (flag) {
      if (this.registObj.password != this.registObj.confirmPassword) {
        alert("密码和确认密码不一致");
        return false;
      }
      this.changeLoading(true);
      var obj = JSON.parse(JSON.stringify(this.registObj));
      let userParam = {
        userType: this.userType,
        userName: this.registObj.username,
        phonenumber: this.registObj.phonenumber,
        password: this.registObj.password
      }
      fetch(`/prod-api/system/user/updateUserPwd`, {
        method: 'post',
        body: JSON.stringify(userParam),
        headers: {
          "Content-type": "application/json"
        }
      })
          .then(response => response.json())
          .then(data => {
            this.changeLoading(false);
            if (data.code == 200) {
              if (data.data>0) {
                this.stepin = 4;
                this.update();
              }else if (data.data==-1){
                alert("用户信息获取失败！")
              }else {
                alert("密码重置失败！")
              }
            } else {
              alert(data.msg)
            }
          })
    }else{
      this.changeLoading(false);
    }
  }
  //打开图片滑块验证码弹窗
  openImageVarify(){
    if(this.registObj.username==undefined || this.registObj.username==''){
      alert('请输入用户名')
      return;
    }
    if (!this.phoneReg.test(this.registObj.phonenumber)) {
      alert('输入正确手机号')
      return false;
    }
    this.isImageShow = true;
    this.update();
  }
  constructor(props) {
    super(props);
    this.checkImageVarify = this.checkImageVarify.bind(this); // 绑定 this
  }

  //图片滑块验证码校验通过
  checkImageVarify(imgData){
    setTimeout(() => {
      this.isImageShow = false;
      this.update();
    }, 300)
    this.registObj.uuid = imgData.imgUuid;
    this.registObj.code = imgData.imgCode;
    this.update();
    this.getVerify();//发送验证码
  }

  render() {
    return (
        <div className="mainbox">
          <div className={this.loading ? 'page-loading' : 'disnone'}><label>{this.loadingText}</label></div>
          <div className="header">
            <div className="regist-box regist-head">
              <div className="regist-top regist-top-left">
                <a href="http://120.46.204.66:8090/Home"><span className="regist-logo"></span></a>
              </div>
              <p className="noaccount">忘记密码
              </p>
            </div>
          </div>
          <div className="regist-box regist-box-main">
            <div className="regist-step-box">
              <div className="bgline"></div>
              <div className="bgline-active" style={'width:' + (this.stepin * 22.7) + 'rem'}></div>
              <ul>
                <li className={this.stepin >= 1 ? 'active-step' : ''}><span>选择用户类型</span></li>
                <li className={this.stepin >= 2 ? 'active-step' : ''}><span>验证用户信息</span></li>
                <li className={this.stepin >= 3 ? 'active-step' : ''}><span>密码重置</span></li>
                <li className={this.stepin >= 4 ? 'active-step' : ''}><span>密码重置成功</span></li>
              </ul>
            </div>

            <div className={this.stepin == 1 ? 'regist-cont' : 'disnone'}>
              <ul className="regist-user-type">
                <li className={this.userType == '01' ? 'type2 typein' : 'type2'} onClick={evt => {
                  this.changeUser(evt, '01')
                }}>
                  <a className={'a-checkbox'}>我是企业用户</a>
                </li>
                <li className={this.userType == '02' ? 'type1 typein' : 'type1'} onClick={evt => {
                  this.changeUser(evt, '02')
                }}>
                  <a className={'a-checkbox'}>我是个人用户</a>
                </li>
              </ul>
              <div className="step-btn" style="margin-bottom:9rem;">
                <button type="button" className="btn-next" onClick={evt => {
                  this.nextStep(evt, 2)
                }}>下一步
                </button>
                <button type="button" className="btn-reset" onClick={evt => {
                  this.resetForm(evt)
                }}>重置
                </button>
              </div>

            </div>

            <div className={this.stepin == 2 ? 'form-yhxx' : 'disnone'}>
              <oi-dialog-box settings={{show: this.isImageShow, width:310, height: 265}}>
                <image-verify ref={this.imgVerifyRef} mess='hello ' checkImageVarify={this.checkImageVarify} />
              </oi-dialog-box>
              <form>
                <div className="fullRow">
                  <div className="col24">
                    <span className="form-label"><i className="red">*</i>用户名：</span>
                    <input type="text" className="form-input required" o-model="registObj.username" name="username" id="username"
                           data-msg="请输入用户名" onBlur={evt => { this.checkUsername() }}/>
                  </div>
                  <div className="col24">
                    <span className="form-label"><i className="red">*</i>手机号：</span>
                    <input type="text" className="form-input required" o-model="registObj.phonenumber"
                           name="phonenumber" id="phonenumber"
                           data-msg="请输入手机号" data-reg={phoneValidator} data-tip="手机号格式不正确" onBlur={evt => { this.checkPhoneNumber() }}/>
                  </div>
                  {/*<div className="col24">*/}
                  {/*  <span className="form-label"><i className="red">*</i>图片验证码：</span>*/}
                  {/*  <div style="position:relative">*/}
                  {/*    <input type="text" className="form-input required" o-model="registObj.code" name="code"*/}
                  {/*           data-msg="请输入图片验证码"/>*/}
                  {/*    <img height="36" ref={this.codeRef} name="codeUrl" src="" onClick={evt => {*/}
                  {/*      this.refreshCode();*/}
                  {/*    }} className="login-code-img codeUrl"/>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <div className="col24">
                    <span className="form-label"><i className="red">*</i>短信验证码：</span>
                    <div style="position:relative">
                      <input type="text" className="form-input required" o-model="registObj.phoneCode"
                             name="phoneCode"
                             data-msg="请输入短信验证码"/>
                      <button type="button" disabled={this.disBtn} onClick={evt => {
                        this.openImageVarify()
                      }} className='pbr'>{this.tip}</button>
                    </div>
                  </div>
                </div>
                <div className="step-btn">
                  <button type="button" className="btn-last mr30" onClick={evt => {
                    this.lastStep(evt, 1)
                  }}>上一步
                  </button>
                  <button type="button" className="btn-next" onClick={evt => {
                    this.checkPhoneCode(evt, 3)
                  }}>下一步
                  </button>
                  <button type="reset" className="btn-reset" onClick={evt => {
                    this.resetForm(evt)
                  }}>重置
                  </button>

                </div>
              </form>
            </div>
            <div className={this.stepin == 3 ? 'form-czmm' : 'disnone'}>
              <div className="fullRow">
                <div className="col24">
                  <span className="form-label"><i className="red">*</i>密码：</span>
                  <input type="password" className="form-input required" o-model="registObj.password"
                         name="password"
                         data-msg="请输入密码" data-reg={passValidator}
                         data-tip="密码至少包含数字、大小写字母、特殊字符 两种以上（不包含中文及空格），长度不小于8位"/>
                </div>
                <div className="col24">
                  <span className="form-label"><i className="red">*</i>确认密码：</span>
                  <input type="password" className="form-input required" o-model="registObj.confirmPassword"
                         name="confirmPassword" data-msg="请输入确认密码" data-reg={passValidator}
                         data-tip="密码至少包含数字、大小写字母、特殊字符 两种以上（不包含中文及空格），长度不小于8位"/>
                </div>
              </div>
              <div className="step-btn">
                <button className="btn-last mr30" onClick={evt => {
                  this.lastStep(evt, 2)
                }}>上一步
                </button>
                <button className="btn-next" onClick={evt => {
                  this.submit(evt)
                }}>确定
                </button>
                <button type="reset" className="btn-reset" onClick={evt => {
                  this.resetForm(evt)
                }}>重置
                </button>
              </div>
            </div>
            <div className={this.stepin == 4 ? '' : 'disnone'}>
              <div className="regist-success resetpwd-success">
                <p>恭喜您，密码重置成功！</p>
              </div>
              <div className="step-btn">
                <a href="/" className="btn-next">登录</a>
              </div>
            </div>
          </div>
          <div className={this.stepin == 1 ? 'regist-footer positionFixed' : 'regist-footer'}>
            <div className="regist-box" style="text-align: center;height:auto;min-height:8rem;">
              版权所有：中国安全防范产品行业协会 京ICP备07503664号-4 京公网安备 11010802034822号
            </div>
          </div>
        </div>
    );
  }
}
)
