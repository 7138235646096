const { h, define, Component, createRef } = omii;
import "../../es-lib/omii/latest/ui/omii-ui.js"
import { getToken } from "../token";
import css from "../css/home.scss";
// import "jquery"

export default class extends Component {
    static css = css;
    dataList = [];
    tagName = "";
    subscribeTime = "";
    subscribeEndTime = "";
    data = {
        total: 0,
        pageSize: 6,
        pageNum: 1
    }
    pageRef = createRef();
    startRef = createRef();
    myCss = `
    .pager .page  {
        font-size:1.4rem!important;
        padding:6px 9px;
        border:solid 1px #f5f5f9;
        margin:0 5px
    }
    .pager .page:hover{
        background:#002040 !important;
    }
    .pager .active{
        background:#002040 !important;
    }
    .pager .jumper,.pager .info{
        font-size:1.4rem!important;
    }
    .pager .jumper .jump-to{
        margin:0 1rem;
    }
    `
    getPagedList(e) {
        let pageNum = e.detail;
        this.data.pageNum = pageNum;
        let obj = {
            url: sjbgUrl + "dabg/sjbg/sjbgSjbgtjYhbgdytj/getUserReportSubscribeListPage",
            current: pageNum,
            size: 10,
            subscribeTime: this.subscribeTime,
            subscribeEndTime: this.subscribeEndTime,
            tagName: this.tagName,
            subscribeStatus: "01"
        }
        fetch("/prod-api/manage/request/Commonget", {
            method: "post",
            body: JSON.stringify(obj),
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                console.log(res)
                if (res.code == 200) {
                    console.log(res.data.data.records)
                    this.dataList = res.data.data.records;
                    this.data.total = res.data.data.total;
                    this.data.pageNum = pageNum;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
    }
    getList(num) {
        // let pageNum = e.detail;
        let obj = {
            url: sjbgUrl + "dabg/sjbg/sjbgSjbgtjYhbgdytj/getUserReportSubscribeListPage",
            // url: sjbgUrl + "sjbg/sjbgSjbgtjYhbgdytj/getUserReportSubscribeListPage",
            current: num,
            size: 10,
            subscribeTime: this.subscribeTime,
            subscribeEndTime: this.subscribeEndTime,
            tagName: this.tagName,
            subscribeStatus: "01"
        }
        fetch("/prod-api/manage/request/Commonget", {
            method: "post",
            body: JSON.stringify(obj),
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                console.log(res)
                if (res.code) {
                    this.dataList = res.data.data.records;
                    this.data.total = res.data.data.total;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
        // this.data.pageNum = pageNum;
    }
    getOperate(id) {
        // alert(121212)
        // let pageNum = e.detail;
        // this.data.pageNum = pageNum;
        if (confirm("确定要取消订阅吗？")) {
            let url = "/dabg-api/sjbg/web/jtbg/ReportSubscriptionCancel/" + id;
            let obj = {
                url: sjbgUrl+"dabg/sjbg/web/jtbg/ReportSubscriptionCancel/" + id,
                // url: sjbgUrl + "sjbg/web/jtbg/ReportSubscriptionCancel/" + id,
            }
            fetch("/prod-api/manage/request/Commonget", {
                method: "post",
                body: JSON.stringify(obj),
                headers: {
                    "Content-type": "application/json",
                    "SiteKey": "Portal",
                    Authorization: `Bearer ${getToken()}`
                }
            }).then(response => response.json())
                .then(res => {
                    this.getList(1);
                    this.update();
                })
            // fetch(url, {
            //     method: "get",
            //     headers: {
            //         "Content-type": "application/json",
            //         "SiteKey": "Portal",
            //         Authorization: `Bearer ${getToken()}`
            //     }
            // }).then(response => response.json())
            //     .then(res => {
            //         this.getList(1);
            //         this.update();
            //     })
        }
    }

    install() {
        this.getList(1);
    }
    query(e) {
        this.getList(1)
    }
    operate(id) {
        this.getOperate(id)
    }
    installed() {
        console.log(this.$("#startDate"))
        // let root = this.pageRef.current.attachShadow({ mode: "open" });
        // setTimeout(() => {
        //     root.appendChild(style);
        // }, 5)
        // const picker = datepicker(this.$$(".startDate")[0], {})
    }
    render() {
        return (
            <>
                <div className="oi-row-full font26">我的订阅</div>
                <div className="list-area">
                    <div className="row search-area">
                        <div className="search-col">
                            开始时间: <input type="date" datetimeformat="yyyy-MM-dd" o-model="subscribeTime" className="startDate normalInput" placeholder="开始日期" />
                        </div>
                        <div className="search-col">
                            结束时间： <input type="date" o-model="subscribeEndTime" className="endDate normalInput" placeholder="截止日期" />
                        </div>
                        <div className="search-col">
                            标签名称: <input o-model="tagName" className="normalInput" placeholder="标签名称" />
                        </div><button className="search-btn" onClick={e => {
                            this.query()
                        }}>搜索</button>
                    </div>
                    <table class="table-list" cellPadding="0" border="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="index-col">序号</th>
                                <th>标签名称</th>
                                <th class="time-col">收藏时间</th>
                                <th class="action-col">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.dataList.length > 0 ? this.dataList.map((item, i) => (
                                <tr>
                                    <td class="type-col">{i + 1}</td>
                                    <td> {item.tagName}</td>
                                    <td class="time-col">
                                        {item.subscribeTime}
                                    </td>
                                    <td class="action-col">
                                        <span class="action" onClick={evt => {
                                            this.operate(item.tagNo)
                                        }}>
                                            取消订阅
                                        </span>
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="4" className="text-c">
                                        暂无数据
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {
                        this.data.total > 0 ? <div className="pager-area">
                            <oi-pager css={this.myCss} total={this.data.total} page-size={this.data.pageSize} page-num={this.data.pageNum} onPaged={evt => this.getPagedList(evt)} />
                        </div> : <div />
                    }

                </div>
            </>
        )
    }
}
