const { h, define, Component, createRef } = omii;
import "../../es-lib/omii/latest/ui/omii-ui.js"
import { getToken } from "../token";
import css from "../css/home.scss";

export default class extends Component {
    static css = css;
    dataList = [];
    companyName = "";
    attentionTime = "";
    attentionEndTime = "";
    sourceType = "";
    businessName = "";
    userInfo = {};
    data = {
        total: 0,
        pageSize: 10,
        pageNum: 1
    }
    pageRef = createRef();
    serviceArticle = {};
    serviceSetting = {
        width: "660px",
        height: "487px",
        show: false,
        title: "留言详情"
    }
   
    myCss = `
    .pager .page  {
        font-size:1.4rem!important;
        padding:6px 9px;
        border:solid 1px #f5f5f9;
        margin:0 5px
    }
    .pager .page:hover{
        background:#002040 !important;
    }
    .pager .active{
        background:#002040 !important;
    }
    .pager .jumper,.pager .info{
        font-size:1.4rem!important;
    }
    .pager .jumper .jump-to{
        margin:0 1rem;
    }
    `
    getPagedList(e) {
        let pageNum = e.detail;
        let url = "/prod-api/manage/board/listByUser?pageNum=" + pageNum + "&pageSize=10";
        if (this.sourceType) {
            url += "&sourceType=" + this.sourceType;
        }
        if (this.businessName) {
            url += "&businessName=" + this.businessName;
        }
        fetch(url, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                if (res.code == 200) {
                    res.rows.map(item => {
                        if (item.source == 1) {
                            if (item.businesstype == "2") {
                                item.url = productUrl + item.businessid;
                                item.businessName = "产品";
                            } else if (item.businesstype == "3") {
                                item.url = solutionUrl + item.businessid;
                                item.businessName = "方案";
                            } else if (item.businesstype == "4") {
                                item.businessName = "文章";
                                item.url = portalUrl + "/" + item.tabKey + "/" + item.businessid
                            }else if (item.businesstype == "5") {
                                item.businessName = "专家";
                                item.url = portalUrl + "/expertCenter?id=" + item.businessid
                            }
                        } else if (item.source == 2) {
                            if (item.businesstype == "2") {
                                item.businessName = "产品";
                                item.url = exhProductUrl + item.businessid;
                            } else if (item.businesstype == "4") {
                                item.businessName = "文章";
                                item.url = exhSiteUrl + "/" + item.tabKey + "/" + item.businessid
                            }
                        }
                    })
                    this.dataList = res.rows;
                    this.data.total = res.total;
                    this.data.pageNum = pageNum;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
    }
    getList(num) {
        // let pageNum = e.detail;
      
        let url = "/prod-api/manage/board/listByUser?pageNum=" + num + "&pageSize=10";
        if(sessionStorage.getItem("expertUser")!=null){
            url+= "&createBy="+JSON.parse(sessionStorage.getItem("expertUser")).user.userId;
        }
        if (this.sourceType) {
            url += "&sourceType=" + this.sourceType;
        }
        if (this.businessName) {
            url += "&businessName=" + this.businessName;
        }
        fetch(url, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                if (res.code == 200) {
                    res.rows.map(item => {
                        if (item.source == 1) {
                            if (item.businesstype == "2") {
                                item.url = productUrl + item.businessid;
                                item.businessName = "产品";
                            } else if (item.businesstype == "3") {
                                item.url = solutionUrl + item.businessid;
                                item.businessName = "方案";
                            } else if (item.businesstype == "4") {
                                item.businessName = "文章";
                                item.url = portalUrl + "/" + item.tabKey + "/" + item.businessid
                            }else if (item.businesstype == "5") {
                                item.businessName = "专家";
                                item.url = portalUrl + "/expertCenter?id=" + item.businessid
                            }
                        } else if (item.source == 2) {
                            if (item.businesstype == "2") {
                                item.businessName = "产品";
                                item.url = exhProductUrl + item.businessid;
                            } else if (item.businesstype == "4") {
                                item.businessName = "文章";
                                item.url = exhSiteUrl + "/" + item.tabKey + "/" + item.businessid
                            }
                        }
                    })
                    this.dataList = res.rows;
                    this.data.total = res.total;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
    }
    removeFollow(id) {
        if (confirm("确定要取消收藏吗？")) {
            let url = "/prod-api/manage/personal/removeFavorite?favoriteId=" + id;
            fetch(url, {
                method: "delete",
                headers: {
                    "Content-type": "application/json",
                    "SiteKey": "Portal",
                    Authorization: `Bearer ${getToken()}`
                }
            }).then(response => response.json())
                .then(res => {
                    this.getList(1);
                    this.update();
                })
        }

    }
    goDetail(item) {
        if (item.businesstype == 4) {
            let url = "/prod-api/manage/tab/getTabByArticle?articleId=" + item.businessid;
            fetch(url, {
                method: "get",
                headers: {
                    "Content-type": "application/json",
                    "SiteKey": "Portal",
                    Authorization: `Bearer ${getToken()}`
                }
            }).then(response => response.json())
                .then(res => {
                    if (res.data && res.data.length > 0) {
                        let tabKey = res.data[0].key;
                        // let url=portalUrl+"/"+tabKey+"/"+item.businessid;
                        window.open(portalUrl + "/" + tabKey + "/" + item.businessid);
                    }
                })
        } else {
            window.open(item.url);
        }
    }
    showDetail(obj) {
        this.serviceArticle = obj;
        this.serviceSetting.show = true;
        this.update()
    }
    updateService() {
        this.serviceSetting.show = false;
        this.update()
    }
    install() {
        this.getList(1);
    }
    query(e) {
        this.getList(1)
    }
    showReply(obj){
    }
    installed() {
    }
    render() {
        return (
            <>
                <div className="oi-row-full font26">我的留言</div>
                <div className="list-area">
                    <table class="table-list" cellPadding="0" border="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="index-col">序号</th>
                                <th class="subject-col">内容</th>
                                <th class="time-col">留言时间</th>
                                <th class="action-col">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.dataList.length > 0 ? this.dataList.map((item, i) => (
                                <tr>
                                    <td class="index-col">{i + 1}</td>
                                    <td class="subject-col">【{item.businesstype==5?<a target="_blank" href={item.url}>{item.businessName}</a>:item.businessName}】
                                        {
                                            (item.content && item.content.length > 40) ? (<span class='content-tip'><div className="content-detail">{item.content}</div>{item.content.substring(0, 40) + "..."}</span>) : (<span>{item.content}</span>)
                                        }
                                    </td>
                                    <td class="time-col">{item.createTime.replace("T", " ")}</td>
                                    <td class="action-col">
                                        {
                                            item.businesstype==5?<span>{item.auditStatus==3?<a href="javascript:Void(0)" onClick={e=>{
                                                this.showDetail(item)
                                            }}>已回复</a>:<em style='font-style:normal;color:grey;cursor:default'>未回复</em>}</span>: <a onClick={e=>{
                                                this.goDetail(item)
                                            }} href="javascript:void(0)" style="cursor:pointer">查看原文</a>
                                        }
                                       
                                    </td>
                                </tr>
                            )) : (
                                <tr class="text-c">
                                    <td colSpan="6">
                                        暂无数据
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {
                        this.data.total > 0 ? <div className="pager-area">
                            <oi-pager css={this.myCss} total={this.data.total} page-size={this.data.pageSize} page-num={this.data.pageNum} onPaged={evt => this.getPagedList(evt)} />
                        </div> : <div />
                    }


                </div>
                <oi-dialog-box settings={this.serviceSetting}>
                    <div className="oi-row">
                        <table className="form-table">
                            <tr>
                                <td className="form-label">留言主题：</td>
                                <td className="form-content">{this.serviceArticle.title}</td>
                            </tr>
                            {/* <tr>
                                <td className="form-label">联系电话：</td>
                                <td className="form-content">{this.serviceArticle.tel}</td>
                            </tr> */}
                            {/* <tr>
                                <td className="form-label">预约时间：</td>
                                <td className="form-content">{this.serviceArticle.bookTime}</td>
                            </tr> */}
                            <tr>
                                <td className="form-label">留言内容：</td>
                                <td className="form-content"> <p className="info-content" unsafeHTML={this.serviceArticle.content}></p></td>
                            </tr>
                        </table>

                    </div>
                    <div className="reply-box">
                        {
                            this.serviceArticle.auditStatus == 3 ? <div>
                                <p>回复时间：{this.serviceArticle.updateTime}</p>
                                <p>回复内容：{this.serviceArticle.remark}</p>
                            </div> : <span></span>
                        }
                    </div>
                    {/* <div slot="footer" className="text-c">
                        <button className="btn-submit m3t" onClick={evt => {
                            this.updateService()
                        }}>确 定</button>
                    </div> */}
                </oi-dialog-box>
              
            </>
        )
    }
}
