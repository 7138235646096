import { setToken, getToken, isTokenExpired } from "./token"
import { authController, systemController, entUserController } from "./apis";

let currUser = null;
export default {
    async login(data) {
        // let r = await authController.login.post(data);
        let r = await authController.loginNew20230530.post(data);
        if (r.code == 200) {
            setToken(r.data.access_token, r.data.expires_in)
            return true
        } else {
            alert(r.msg);
        }
        return false;
        //以下为新增登录接口
        // let r = await authController.unionLogin.post(data);
        // if (r.code == 200) {
        //     setToken(r.data.token.access_token, r.data.token.expires_in);
        //     if(r.data.user.userType=='01'||r.data.user.userType=='03'){
        //         location.href=serviceCenterUrl;
        //     }
        //     return true
        // }else{
        //     alert(r.msg);
        // }
        // return false
    },
    async logout() {
        fetch("/prod-api/auth/logout", {
            method: "delete",
            headers: {
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                console.log("执行退出：：前往门户")
                setToken(null);
                location.href=portalUrl;
            })

    },
    async getUserInfo() {
        // return systemController.user.getGetInfoNew()
        return systemController.user.getGetInfo()
    },
    get isLogined() {
        return !isTokenExpired()
    },
    async getEntUserInfo() {
        // return entUserController.enterprise.getGetInfoNew()
        return entUserController.enterprise.getGetInfo()
    },
}
