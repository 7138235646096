const { h, define, Component, createRef } = omii;
import "../../es-lib/omii/latest/ui/omii-ui.js"
import { getToken } from "../token";
import css from "../css/home.scss";


let phoneValidator = "^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\\d{8}$";
export default class extends Component {
    static css = css;
    dataList = [];
    demandTitle = "";
    demandState = "";
    subscribeEndTime = "";
    data = {
        total: 0,
        pageSize: 6,
        pageNum: 1
    }
    demandStateList=[{
      label:'暂存',
      value:'00'
    },{
      label:'提交',
      value:'01'
    },
    {
      label:'审核通过',
      value:'02'
    },{
      label:'审核不通过',
      value:'03'
    }]
    formData={
        demandTitle:'',
        contactMan:'',
        contactPhone:'',
        contactMail:'',
        demandContent:''
    }
    formSetting = {
      width: "660px",
      height: "92rem",
      show: false,
      title: "",
      type:''
    }
    column=[{
        label:'标题',
        prop:'demandTitle',
        type:'input',
        span:24,
        tip:false,
        msg:''
      },
      {
        label:'联系人',
        prop:'contactMan',
        type:'input',
        span:24,
        tip:false,
        msg:''
      },
      {
        label:'手机',
        prop:'contactPhone',
        type:'input',
        span:12,
        tip:false,
        msg:''
      },
      {
        label:'邮箱',
        prop:'contactMail',
        type:'input',
        span:12,
        tip:false,
        msg:''
      },
      {
        label:'内容描述',
        prop:'demandContent',
        type:'textarea',
        span:24,
        tip:false,
        msg:''
      },
      {
        label:'审核状态',
        prop:'auditResult',
        type:'select',
        span:24,
        dict:[{
          label:'同意',
          value:'1',
          disabled:true
        },{
          label:'不同意',
          value:'2',
          disabled:true
        }],
        tip:false,
        msg:'',
        hide:true
      },
      {
        label:'审核意见',
        prop:'auditDesc',
        type:'textarea',
        span:24,
        tip:false,
        msg:'',
        hide:true
      }]
    pageRef = createRef();
    startRef = createRef();
    myCss = `
    .pager .page  {
        font-size:1.4rem!important;
        padding:6px 9px;
        border:solid 1px #f5f5f9;
        margin:0 5px
    }
    .pager .page:hover{
        background:#002040 !important;
    }
    .pager .active{
        background:#002040 !important;
    }
    .pager .jumper,.pager .info{
        font-size:1.4rem!important;
    }
    .pager .jumper .jump-to{
        margin:0 1rem;
    }
    `
    getPagedList(e) {
        let pageNum = e.detail;
        this.data.pageNum = pageNum;
        let obj = {
          url:'/prod-api/manage/userDemand/userDemand/list',
          current: num,
          size: 10,
          demandState: this.demandState,
          demandTitle: this.demandTitle,
          userId:JSON.parse(sessionStorage.getItem('expertUser')).user.userId
        }
        let url=Object.entries(obj).reduce((pre,cur)=>{
          console.log(cur)
          return pre?pre+'&'+cur[0]+'='+cur[1]:cur[1]+'?'
        },'')
        fetch(url, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                console.log(res)
                if (res.code == 200) {
                    this.dataList = res.rows;
                    this.data.total = res.total;
                    this.data.pageNum = pageNum;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
    }
    getList(num) {
        // let pageNum = e.detail;
        //console.log(JSON.parse(sessionStorage.getItem('expertUser')).user.userId)
        let obj = {
            url:'/prod-api/manage/userDemand/userDemand/list',
            current: num,
            size: 10,
            demandState: this.demandState,
            demandTitle: this.demandTitle,
            userId:JSON.parse(sessionStorage.getItem('expertUser')).user.userId
        }
        let url=Object.entries(obj).reduce((pre,cur)=>{
          //console.log(cur)
          return pre?pre+'&'+cur[0]+'='+cur[1]:cur[1]+'?'
        },'')
        //console.log(url)
        fetch(url, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                console.log(res)
                if (res.code) {
                    this.dataList = res.rows;
                    this.data.total = res.total;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
        // this.data.pageNum = pageNum;
    }
    handleUpdate(id) {
      fetch("/prod-api/manage/userDemand/userDemand/updateState/"+id, {
          method: "post",
          body: JSON.stringify(obj),
          headers: {
              "Content-type": "application/json",
              "SiteKey": "Portal",
              Authorization: `Bearer ${getToken()}`
          }
      }).then(response => response.json())
          .then(res => {
              this.getList(1);
              this.update();
          })
    }
    handleAdd(){
        this.formData={
            demandTitle:'',
            contactMan:'',
            contactPhone:'',
            demandContent:'',
            contactMail:'',
            auditResult:'',
            demandId:'',
            demandState:'',
            auditDesc:''
        }
      this.formSetting.show=true
      this.formSetting.type='add'
      this.formSetting.title='新增需求'
      this.column[5].hide=true
      this.column[6].hide=true
      this.column.forEach(e=>e.disabled=false)
      this.update();
    }
    handleType(obj,type){
      this.formSetting.show=true
      this.formSetting.type=type
      console.log(obj)
      this.formData={
        demandId:obj.demandId,
        demandTitle:obj.demandTitle,
        contactMan:obj.contactMan,
        contactPhone:obj.contactPhone,
        contactMail:obj.contactMail,
        demandContent:obj.demandContent,
        demandState: obj.demandState,
        auditResult:obj.auditResult,
        auditDesc:obj.auditDesc
      }
      this.column.forEach(e=>e.disabled=(type=='view'))
      this.column.forEach(e=>e.tip=false)
      if(type=='view'){
          this.column[5].hide=!obj.auditResult
          this.column[6].hide=!obj.auditResult
          this.formSetting.title='查看需求'
      }else{
        this.column[5].hide=true
        this.column[6].hide=true
        this.formSetting.title='修改需求'
      }
      this.update();
    }
    handleData(id,type){
        console.log(id)
        if (confirm("确定要"+(type=='update'?'提交':'删除')+"吗？")) {
            fetch(type=='update'?"/prod-api/manage/userDemand/userDemand/updateState/"+id:'/prod-api/manage/userDemand/userDemand/remove/'+id, {
                method: type=='update'?"put":'delete',
                headers: {
                    "Content-type": "application/json",
                    "SiteKey": "Portal",
                    Authorization: `Bearer ${getToken()}`
                },
            }).then(response => response.json())
            .then(res => {
                console.log(res)
                if(res.code==200){
                    alert((type=='update'?'提交':'删除')+'成功！')
                    this.formSetting.show=false
                    this.getList(1);
                    this.update();
                }else{
                    alert(res.msg)
                }
            })
        }
    }
    format(value,prop){
      if(value){
        return this[prop+'List'].filter(e=>e.value==value)[0].label
      }else{
        return value
      }
    }
    handleSubmit(){
        if(this.formSetting.type=='view'){
            this.formSetting.show=false
            this.formData={
                demandTitle:'',
                contactMan:'',
                contactPhone:'',
                contactMail:'',
                auditResult:'',
                demandId:'',
                demandState:'',
            }
            this.update()
            return 
        }
        Object.entries(this.formData).forEach(e=>{
            let item=this.column.filter(col=>col.prop==e[0])[0]
            if(['demandTitle','contactMan','contactPhone','contactMail','demandContent'].includes(e[0])&&!e[1]){
                item.tip=true
                item.msg='请输入'+item.label
            }
            if(e[0]=='contactPhone'&&e[1]){
                item.tip=!(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/g.test(e[1]))
                item.msg='请输入正确'+item.label
            }
            if(e[0]=='contactMail'&&e[1]){
                item.tip=!(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g.test(e[1]))
                item.msg='请输入正确'+item.label
            }
            
        })
        for(let item of this.column){
            if(item.tip)return this.update()
        }
        this.submit()
    }
    submit(){
        let type=this.formSetting.type
        fetch(type=='add'?"/prod-api/manage/userDemand/userDemand/add":'/prod-api/manage/userDemand/userDemand/update', {
            method: type=='add'?"post":'put',
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            },
            body:JSON.stringify(this.formData)
        }).then(response => response.json())
        .then(res => {
            console.log(res)
            if(res.code==200){
                alert((type=='add'?'新增':'修改')+'成功！')
                this.formSetting.show=false
                this.formData={
                    demandTitle:'',
                    contactMan:'',
                    contactPhone:'',
                    contactMail:'',
                    auditResult:'',
                    demandId:'',
                    demandState:'',
                }
                this.getList(1);
                this.update();
            }else{
                alert(res.msg)
            }
        })
    }
    checkValue(e,prop,index){
        console.log(this.formData)
        let value=e.target.value
        let item=this.column[index]
        if(!value){
            item.tip=true
            item.msg='请输入'+item.label
        }else if(prop=='contactPhone'){
            item.tip=!(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/g.test(value))
            item.msg='请输入正确'+item.label
        }else if(prop=='contactMail'){
            item.tip=!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g.test(value)
            item.msg='请输入正确'+item.label
        }
        else{
            item.tip=false
        }
        this.update();
    }
    changeTextarea(e,prop){
        this.formData[prop]=e.target.value
        this.update()
    }
    install() {
        this.getList(1);
    }
    query(e) {
        this.getList(1)
    }
    reset(){
        this.demandTitle=''
        this.demandState=' '
        this.update()
        let time=setTimeout(()=>{
            this.demandState=''
            this.getList(1)
            this.update()
            clearTimeout(time)
        })
        
    }
    operate(id) {
        this.getOperate(id)
    }
    installed() {
        console.log(this.$("#startDate"))
    }
    render() {
        return (
            <>
                <div className="oi-row-full font26">发布需求</div>
                <div className="list-area">
                    <div className="row search-area">
                        <div className="search-col">
                            标题: <input o-model="demandTitle" value={this.demandTitle} className="normalInput" placeholder="标题" />
                        </div>
                        <div className="search-col">
                            状态:<select className="form-select required" o-model='demandState' value={this.demandState} name='demandState' data-msg="请选择状态">
                                                <option value=' '>请选择</option>
                                                {this.demandStateList.map(item=>{
                                                    return <option  value={item.value}>{item.label}</option>
                                                })}
                                            </select>
                        </div>
                        <button className="search-btn" onClick={e => {
                            this.query()
                        }}>搜索</button>
                        <button className="search-btn search-btn1" onClick={e => {
                            this.reset()
                        }}>重置</button>
                    </div>
                    <div>
                    <button className="search-btn" onClick={e => {
                            this.handleAdd()
                        }}>新增</button>
                    </div>
                    <table class="table-list" cellPadding="0" border="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th class="index-col">序号</th>
                                <th class="time-col">标题</th>
                                <th class="time-col">联系人</th>
                                <th class="time-col">手机</th>
                                <th class="time-col">状态</th>
                                <th class="action-col">操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.dataList.length > 0 ? this.dataList.map((item, i) => (
                                <tr>
                                    <td class="index-col">{i + 1}</td>
                                    <td class="status-col"> {item.demandTitle}</td>
                                    <td class="status-col"> {item.contactMan}</td>
                                    <td class="status-col"> {item.contactPhone}</td>
                                    <td class="status-col">
                                        {this.format(item.demandState,'demandState')}
                                    </td>
                                    <td class="action1-col">
                                        <span class="action" onClick={evt => {
                                            this.handleType(item,'view')
                                        }}>查看</span>
                                        {item.demandState=='00'?<span class="action" onClick={evt => {
                                            this.handleType(item,'edit')
                                        }}>修改</span>:''}
                                        {item.demandState=='00'?<span class="action" onClick={evt => {
                                            this.handleData(item.demandId,'update')
                                        }}>提交</span>:''}
                                        {item.demandState=='00'?<span class="action" onClick={evt => {
                                            this.handleData(item.demandId,'delete')
                                        }}>删除</span>:''}
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="24" className="text-c">
                                        暂无数据
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {
                        this.data.total > 0 ? <div className="pager-area">
                            <oi-pager css={this.myCss} total={this.data.total} page-size={this.data.pageSize} page-num={this.data.pageNum} onPaged={evt => this.getPagedList(evt)} />
                        </div> : <div />
                    }

                </div>
                <oi-dialog-box settings={this.formSetting}>
                    <form>
                        <div className="oi-row">
                            {this.column.map((col,index)=>{
                                return col.hide?'':(<div className={'oi-col-'+col.span}>
                                        <span className="oi-label"><i className="red">*</i>{col.label}：</span>
                                        <div className="oi-col-content">
                                            {col.type=='input'?<input type="text" className="form-input required" o-model={'formData.'+col.prop} value={this.formData[col.prop]} name={col.prop} id={col.prop}
                                             disabled={col.disabled}   onBlur={evt => { this.checkValue(evt,col.prop,index) }}/>:
                                            col.type=='textarea'?<textarea className="form-input required" disabled={col.disabled} name={col.prop} style="height:auto;" value={this.formData[col.prop]} onChange={evt => {this.changeTextarea(evt,col.prop)}} onBlur={evt => { this.checkValue(evt,col.prop,index) }}></textarea>:
                                            <select name={col.prop} placeholder={'选择'+col.label} disabled={col.disabled} className="form-select required" o-model={'formData.'+col.prop} data-msg="请选择审核状态">
                                                {col.dict.map(item=>{
                                                    return <option  value={item.value}>{item.label}</option>
                                                })}
                                            </select>
                                            }
                                        </div>
                                        {
                                          col.tip?<div className="msg">{col.msg}</div>:''
                                        }
                                    </div>)
                            })}
                        </div>
                    </form>
                    <div slot="footer" className="text-c">
                        <button className="btn-submit m3t" onClick={evt => {
                            this.handleSubmit()
                        }}>确 定</button>
                    </div>
                </oi-dialog-box>
            </>
        )
    }
}
