const { h, define, Component, createRef } = omii;
import "../../es-lib/omii/latest/ui/omii-ui.js"
import { getToken } from "../token.js";
import css from "../css/home.scss";
import apply from "./apply/form"

define("oi-apply", apply)

export default class extends Component {
    static css = css;
    dataList = [];
    companyName = "";
    attentionTime = "";
    attentionEndTime = "";
    sourceType = "";
    businessName = "";
    data = {
        total: 0,
        pageSize: 10,
        pageNum: 1
    }
    pageRef = createRef();
    serviceArticle = {};
    serviceSetting = {
        width: "660px",
        height: "487px",
        show: false,
        title: "留言详情"
    }
    myCss = `
    .pager .page  {
        font-size:1.4rem!important;
        padding:6px 9px;
        border:solid 1px #f5f5f9;
        margin:0 5px
    }
    .pager .page:hover{
        background:#002040 !important;
    }
    .pager .active{
        background:#002040 !important;
    }
    .pager .jumper,.pager .info{
        font-size:1.4rem!important;
    }
    .pager .jumper .jump-to{
        margin:0 1rem;
    }
    `
    getPagedList(e) {
        let pageNum = e.detail;
        let url = "/prod-api/manage/board/listByUser?pageNum=" + pageNum + "&pageSize=10";
        if (this.sourceType) {
            url += "&sourceType=" + this.sourceType;
        }
        if (this.businessName) {
            url += "&businessName=" + this.businessName;
        }
        fetch(url, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                if (res.code == 200) {
                    res.rows.map(item => {
                        if (item.source == 1) {
                            if (item.businesstype == "2") {
                                item.url = productUrl + item.businessid;
                                item.businessName = "产品";
                            } else if (item.businesstype == "3") {
                                item.url = solutionUrl + item.businessid;
                                item.businessName = "方案";
                            } else if (item.businesstype == "4") {
                                item.businessName = "文章";
                                item.url = portalUrl + "/" + item.tabKey + "/" + item.businessid
                            }
                        } else if (item.source == 2) {
                            if (item.businesstype == "2") {
                                item.businessName = "产品";
                                item.url = exhProductUrl + item.businessid;
                            } else if (item.businesstype == "4") {
                                item.businessName = "文章";
                                item.url = exhSiteUrl + "/" + item.tabKey + "/" + item.businessid
                            }
                        }
                    })
                    this.dataList = res.rows;
                    this.data.total = res.total;
                    this.data.pageNum = pageNum;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
    }
    getList(num) {
        // let pageNum = e.detail;
        let url = "/prod-api/manage/board/listByUser?pageNum=" + num + "&pageSize=10";
        if (this.sourceType) {
            url += "&sourceType=" + this.sourceType;
        }
        if (this.businessName) {
            url += "&businessName=" + this.businessName;
        }
        fetch(url, {
            method: "get",
            headers: {
                "Content-type": "application/json",
                "SiteKey": "Portal",
                Authorization: `Bearer ${getToken()}`
            }
        }).then(response => response.json())
            .then(res => {
                if (res.code == 200) {
                    res.rows.map(item => {
                        if (item.source == 1) {
                            if (item.businesstype == "2") {
                                item.url = productUrl + item.businessid;
                                item.businessName = "产品";
                            } else if (item.businesstype == "3") {
                                item.url = solutionUrl + item.businessid;
                                item.businessName = "方案";
                            } else if (item.businesstype == "4") {
                                item.businessName = "文章";
                                item.url = portalUrl + "/" + item.tabKey + "/" + item.businessid
                            }
                        } else if (item.source == 2) {
                            if (item.businesstype == "2") {
                                item.businessName = "产品";
                                item.url = exhProductUrl + item.businessid;
                            } else if (item.businesstype == "4") {
                                item.businessName = "文章";
                                item.url = exhSiteUrl + "/" + item.tabKey + "/" + item.businessid
                            }
                        }
                    })
                    this.dataList = res.rows;
                    this.data.total = res.total;
                } else {
                    this.data.total = 0;
                }
                this.update();
            })
    }
    showDetail(obj) {
        this.serviceArticle = obj;
        this.serviceSetting.show = true;
        this.update()
    }
    install() {
        this.getList(1);
    }
    query(e) {
        this.getList(1)
    }
    installed() {
    }
    render() {
        return (
            <>
                <div className="oi-row-full font26">专家申请</div>
                <div>
                    <oi-tab
                        items={[
                            {
                                nav: "我要申请",
                                pane: <oi-apply />
                            },
                            {
                                nav: "申请记录",
                                pane: <>
                                    <table class="table-list" cellPadding="0" border="0" cellspacing="0">
                                        <thead>
                                            <tr>
                                                <th class="index-col">序号</th>
                                                <th class="subject-col">内容</th>
                                                <th class="time-col">留言时间</th>
                                                <th class="action-col">操作</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.dataList.length > 0 ? this.dataList.map((item, i) => (
                                                <tr>
                                                    <td class="index-col">{i + 1}</td>
                                                    <td class="subject-col">【{item.businessName}】
                                                        {
                                                            (item.content && item.content.length > 40) ? (<span class='content-tip'><div className="content-detail">{item.content}</div>{item.content.substring(0, 40) + "..."}</span>) : (<span>{item.content}</span>)
                                                        }
                                                    </td>
                                                    <td class="time-col">{item.createTime.replace("T", " ")}</td>
                                                    <td class="action-col">
                                                        <a href={item.url} target="_blank" style="cursor:pointer">查看原文</a>
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr class="text-c">
                                                    <td colSpan="6">
                                                        暂无数据
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {
                                        this.data.total > 0 ? <div className="pager-area">
                                            <oi-pager css={this.myCss} total={this.data.total} page-size={this.data.pageSize} page-num={this.data.pageNum} onPaged={evt => this.getPagedList(evt)} />
                                        </div> : <div />
                                    }
                                </>
                            }

                        ]}
                    />
                </div>
            </>
        )
    }
}
